import { Button, Col, Input, Label, Row } from "reactstrap"
import SelectOptions from "src/views/components/Select/SelectOptions"

const index = () => {
    return (
        <div className="px-1 py-1" style={{backgroundColor:"#FFFFFF"}}>
            <Row className="d-flex justify-content-center">
                <Col md="6">
                    <Label>Şablon Adı</Label>
                    <Input></Input>
                </Col>
                <Col md="6">
                    <Label>Şablon Türü</Label>
                    <SelectOptions />
                </Col>
                <Col md="11" className="d-flex justify-content-center my-1">
                    <Col
                        style={{ backgroundColor: '#7367F0', cursor: 'pointer',paddingTop:"4px",paddingBottom:"4px" }}
                        className="text-white  rounded-2  text-center "
                        md="3"
                    >
                        Okul No
                    </Col>
                    <Col
                        style={{ backgroundColor: '#7367F0', cursor: 'pointer',paddingTop:"4px",paddingBottom:"4px" }}
                        className="text-white mx-1  rounded-2    text-center "
                        md="3"
                    >
                        Öğrenci Ad Soyad
                    </Col>
                    <Col
                        style={{ backgroundColor: '#7367F0', cursor: 'pointer',paddingTop:"4px",paddingBottom:"4px" }}
                        className="text-white rounded-2   text-center "
                        md="3"
                    >
                        Veli Ad Soyad
                    </Col>
                    <Col
                        style={{ backgroundColor: '#7367F0', cursor: 'pointer',paddingTop:"4px",paddingBottom:"4px" }}
                        className="text-white mx-1  rounded-2  text-center "
                        md="3"
                    >
                        Tarih
                    </Col>
                </Col>
                <Col md="12">
                    <Input type="textarea"></Input>
                </Col>
                <Col className="my-1 d-flex justify-content-center">
                    <Button
                        className="btn btn-success"
                    >
                        Şablonu Kaydet
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default index
