import TabBar from './components/TabBar'
import { ReactNode, useEffect, useState } from 'react'
import {
  reportTabs,
  updateTabs,
  accountTabs,
  featureTabs,
  messageTabs,
  contactTabs,
} from './tabs'
import { Table } from 'reactstrap'

const HomePage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [thickWidth, setThickWidth] = useState((windowWidth * 70) / 100 - 40)
  const [thinWidth, setThinWidth] = useState((windowWidth * 30) / 100 - 40)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const handleWindowResize = () => {
      setVisible(true)
      if (windowWidth < 1200) {
        setVisible(false)
      }
      setWindowWidth(window.innerWidth)
    }

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return (
    <>
      <Row>
        <div
          style={{
            display: 'flex',
            width: thickWidth,
            height: 300,
            marginRight: 20,
          }}
        >
          <TabBar
            tooltip="Yeni Gelen Özellikleri Görüntüleyebilirsiniz"
            tabs={featureTabs}
            height={300}
            topRightBorder={true}
            half
          />
          <TabBar
            tooltip="Hesap Bilgileri"
            tabs={accountTabs}
            height={300}
            topRightBorder={true}
            half
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: thinWidth,
            height: 300,
            marginBottom: 20,
          }}
        >
          <TabBar
            tooltip="Yeni Gelen Özellikleri Görüntüleyebilirsiniz"
            tabs={messageTabs}
            topRightBorder={true}
            half
          />
        </div>
      </Row>
      <Row>
        <div
          className="d-flex flex-column"
          style={{ width: thickWidth, marginRight: 20 }}
        >
          <div
            style={{
              display: 'flex',
              height: 400,
            }}
          >
            <TabBar
              tooltip="Yeni Gelen Özellikleri Görüntüleyebilirsiniz"
              tabs={reportTabs}
              height={300}
              topRightBorder={true}
              half
              scroll
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TabBar
              tooltip="Yeni Gelen Özellikleri Görüntüleyebilirsiniz"
              tabs={contactTabs}
              height={300}
              topRightBorder={true}
              half
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: thinWidth,
            height: '100%',
            maxHeight: 690,
          }}
        >
          <TabBar
            tooltip="Yeni Gelen Özellikleri Görüntüleyebilirsiniz"
            tabs={updateTabs}
            topRightBorder={true}
            half
            scroll
          />
        </div>
      </Row>
    </>
  )
}

const Row = ({ children }: { children: ReactNode }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  const direction = windowWidth < 1200 ? 'column' : 'row'

  return (
    <div
      style={{ height: '100%', marginBottom: 20 }}
      className={`d-flex ms-1 flex-${direction}`}
    >
      {children}
    </div>
  )
}

export default HomePage
