import { useFormik } from 'formik'
import { useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { SiMicrosoftexcel } from 'react-icons/si'
import * as Yup from 'yup'
import { NavLink } from 'react-router-dom'
import { Button, Col, Input, Label, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import MultiMessage from 'src/views/components/multimessage'
import FieldTwo from  './fieldTwo/index'
import FieldOne from  './fieldOne/index'
import WpPhone from '../wpPhone'


type Props = {
  active: any
  formik: any
  activeType: any
}

const Index = (props: Props) => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)

  const toggleTab = (tab: number) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const formik = useFormik({
    initialValues: {
      message: '',
      phone: '',
      files: [],
    },
    validationSchema: Yup.object({
      message: Yup.string().required(t('Bu alan zorunludur.')),
      phone: Yup.string().required(t('Bu alan zorunludur.')),
  
    }),
    onSubmit: (values) => {

    },
  })
  


  return (
    <Row className="justify-content-center">
    <Col  xs="12" sm="12" md="12" lg="12" xl="6" xxl="5" style={{ borderRight: "2px solid #ccc" }}>
    <FieldOne/>
    </Col>
    <Col  xs="12" sm="12" md="12" lg="12" xl="6" xxl="5" style={{ borderRight: "2px solid #ccc" }}>
    <FieldTwo />
    </Col>
    <Col   xs="12" sm="12" md="12" lg="12" xl="6" xxl="2" >
    <WpPhone formik={formik} />
    </Col>
  </Row>
  )
}

export default Index