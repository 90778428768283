import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import PackageCard from './card/index'
import { useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
const index = () => {
  const [modal, setModal] = useState(false)
  return (
    <div>
      <Row>
        <Col md="12">
          <PackageCard />
        </Col>
      </Row>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        ></ModalHeader>
        <ModalBody className="text-start px-1  ">
          <div className=" mt-1 px-1 p ">
            <h2>Sms Paket Fiyatları, Kullanım ve Ücretlendirme Detayları</h2>
          </div>
          <div className="px-1 py-1 ">
            <p>1. Okul SMS paket fiyatlarımıza KDV dahil değildir.</p>
            <p>
              {
                '2. Belirtilen fiyatlar dışında herhangi (rehber aktarımı, sistem kurulumu, teknik destek, vb.. için) bir ücret talep edilmez.'
              }
            </p>
            <p>
              {
                '3. Okul SMS paketlerimizin son kullanım tarihi bulunmamaktadır. Sms paketinizi istediğiniz zaman kullanabilirsiniz.'
              }
            </p>
            <p>
              {
                "4. BTK'nın yeni fiyat tarifesi ile mevcut paketleriniz 31.12.2023 tarihine kadar geçerlidir. SMS kredileriniz 01.01.2023 tarihinden itibaren yeni fiyatlara göre revize edilecektir."
              }
            </p>
            <p>
              {
                '5. 100% Geri Ödeme Garantisi üyelikten sonra 30 gün içinde ve 50 SMS altında kullanım yapmış olma durumlarında geçerlidir. Memnun kalmadığınız takdirde ödediğiniz ücretin tamamı tarafınıza iade edilir. Kullandığınız kredinin ücreti kesilmez.'
              }
            </p>
            <p>
              {
                '6. Gönderim yaptığınız Operatör hangisi olursa olsun (Turkcell, Vodafone, Turk Telekom) her SMS 1 kredi olarak ücretlendirilir.'
              }
            </p>
            <p>
              {
                '7. Bizde her kullanıcı özeldir ve aldığı paket farketmeksizin en iyi hizmeti alır.'
              }
            </p>
            <p>
              {
                '8. Üyelik aşamasından göndereceği ilk mesaja kadar kendisine özel temsilcisi destek olur.'
              }
            </p>
            <p>
              {
                '9.Süre sınırlaması olmadan kullanım ve diğer merak edilen konular hakkında müşteri temsilcilerimiz sizlerin bilgisayarınıza uzaktan bağlanarak eğitim verir.'
              }
            </p>
            <p>
              {
                '10. İlkSMS, operatörler arası ara bağlantı ücretlendirmelerinde değişiklik olması halinde; yürürlükte bulunan mevzuata uygun şekilde; tarifelerinde, paketten ücretlendirme şartlarında değişiklik yapabilir.'
              }
            </p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
