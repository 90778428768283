import { lazy } from 'react'
import Dashboard from '../../views/dashboard/dahboard'
import ForgotPassword from 'src/views/pages/authentication/ForgotPassword/ForgotPassword'
import SendMessage from 'src/views/pages/main/sendMessage/index'
import ExamResulSms from 'src/views/pages/main/examResultSms/index'
import Absenteeims from 'src/views/pages/main/absenteeism/index'
import Guide from 'src/views/pages/main/contacts/index'
import HomePage from 'src/views/pages/main/homePage'
import Reports from 'src/views/pages/main/reports/index'
import Remmitance from 'src/views/pages/main/buyCredit/remmitance/index'
import BuyCredit from 'src/views/pages/main/buyCredit/index'
import Help from 'src/views/pages/main/help'
import ClassGroupDetails from 'src/views/pages/main/contacts/classGroupOperations/classGroupDetails/index'
import Support from 'src/views/pages/main/support'
import Account from 'src/views/pages/main/account'
import Settings from 'src/views/pages/main/settings'
import ESchoolIntegration from 'src/views/pages/main/eSchoolIntegration'

const Login = lazy(() => import('../../views/pages/authentication/Login/Login'))
const AppRoutes = [
  {
    element: <HomePage />,
    path: '/',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: true,
    },
  },
  {
    element: <ForgotPassword />,
    path: '/forgot-password',
    meta: {
      appLayout: true,
      publicRoute: true,
    },
  },
  {
    element: <Login />,
    path: '/giris',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <SendMessage />,
    path: '/sendMessage',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <Absenteeims />,
    path: '/absences',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <Reports />,
    path: '/reports',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <ExamResulSms />,
    path: '/grades',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <Guide />,
    path: '/contact',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <BuyCredit />,
    path: '/buyCredit',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <Remmitance />,
    path: '/remmitance',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <Help />,
    path: '/help',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },

  {
    element: <Support />,
    path: '/support',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },

  {
    element: <ClassGroupDetails />,
    path: 'contact/classGroupDetails',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <Account />,
    path: '/settings',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
  {
    element: <ESchoolIntegration />,
    path: '/eSchoolIntegration',
    meta: {
      appLayout: true,
      className: 'email-application',
      publicRoute: false,
    },
  },
]

export default AppRoutes
