import { useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { SiMicrosoftexcel } from 'react-icons/si'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import * as yup from 'yup'
import FieldOne from './fieldOne'
import FieldTwo from './filedTwo'
import WpPhone from '../wpPhone'
import { useFormik } from 'formik'


type Props = {
  active: any
  formik: any
  activeType: any
}

const Index = (props: Props) => {
  const [isClicked1, setİsClicked1] = useState(false)
  const [isClicked2, setİsClicked2] = useState(false)
  const [isClicked3, setİsClicked3] = useState(false)
  const [guardian, setGuardian] = useState(false)

  
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  const handleFieldTwoDataChange = (data1:any, data2:any,data3:any) => {
    setİsClicked1(data1);
    setİsClicked2(data2);
    setİsClicked3(data3);

  };
  const changeGuardian = (data:any)=>{
    setGuardian(data)
  }
  

  return (
    <Row className="justify-content-center ">
      <Col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="6"
        xxl="5"
        style={{ borderRight: '2px solid #ccc' }}
      >
        <FieldOne  dataChange={changeGuardian} isClicked1={isClicked1} isClicked2={isClicked2} isClicked3={isClicked3} />
      </Col>
      <Col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="6"
        xxl="5"
        style={{ borderRight: '2px solid #ccc' }}
      >
        <FieldTwo
        guardian={guardian}
         onDataChange={handleFieldTwoDataChange}
          formik={formik}
        />
      </Col>
      <Col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        xxl="2"
        className=" d-none d-xl-block"
      >
        <WpPhone formik={formik} />
      </Col>
    </Row>
  )
}

export default Index
