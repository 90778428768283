import { Col, Row } from 'reactstrap'
import Button from 'react-bootstrap/Button'
import CustomTable from 'src/views/components/customTable/customTable'
import { useState } from 'react'
import SearchBar from '../../components/searchBar'
import { columns, modalColumns } from '../data/columns'
import ReportCustomModal from '../../components/modal'

const Reports = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const data = createData(handleShow)

  return (
    <Row className=" d-flex">
      <Col xs="12">
        <SearchBar />
        <CustomTable notPage={true} data={innerData} columns={innerColumns} />
        <CustomTable data={data} columns={columns} onRowClicked={handleShow} />
        {ReportCustomModal(handleClose, show, createModalData(), modalColumns)}
      </Col>
    </Row>
  )
}

export default Reports

const innerData = [
  {
    sent: '1',
    successMessages: '1',
    unsuccessMessages: '0',
    waitingMessages: '0 ',
    refundedMessages: '0 Kredi',
    lostCredits: '1 Kredi',
  },
]

const innerColumns = [
  {
    name: 'Toplam Gönderilen',
    sortable: true,
    selector: (row: { sent: string }) => row.sent,
  },
  {
    name: 'Başarılı Mesaj',
    sortable: true,
    selector: (row: { successMessages: string }) => row.successMessages,
  },
  {
    name: 'Başarısız Mesaj',
    sortable: true,
    selector: (row: { unsuccessMessages: string }) => row.unsuccessMessages,
  },
  {
    name: 'Bekleyen Mesaj',
    sortable: true,
    selector: (row: { waitingMessages: string }) => row.waitingMessages,
  },
  {
    name: 'İade Mesaj Toplamı',
    sortable: true,
    selector: (row: { refundedMessages: string }) => row.refundedMessages,
  },
  {
    name: 'Düşen Kredi Sayısı',
    sortable: true,
    selector: (row: { lostCredits: string }) => row.lostCredits,
  },
]

function createData(handleShow: any) {
  const data = Array(5).fill({
    date: '10/21/2024',
    sender: 'test',
    sendAccount: 'Ana Hesap',
    message: 'WP REPORTS',
    result: 'Toplam 1 Adet SMS Gönderildi',
  })

  return data.map((item: any) => {
    return {
      date: item.date,
      sender: item.sender,
      sendAccount: item.sendAccount,
      message: item.message,
      result: item.result,
      details: (
        <div
          style={{
            padding: 10,
            borderRadius: 18,
            backgroundColor: '#24d366',
            color: 'white',
          }}
        >
          Başarılı
        </div>
      ),
      process: (
        <div className="d-flex flex-column">
          <Button size="sm" variant="primary mb-1" onClick={() => handleShow()}>
            Detay
          </Button>
          <Button variant="success" size="sm">
            Excel
          </Button>
        </div>
      ),
    }
  })
}

function createModalData() {
  const data = Array(12).fill({
    date: '21/10/2024',
    class: '12-A',
    schoolNo: '139',
    name: 'İbrahim Sert',
    phone: '05555555555',
    message: 'test',
    readStatus: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 75,
          border: 'none',
          padding: 5,
          backgroundColor: 'green',
          color: 'white',
          borderRadius: 5,
        }}
      >
        <p className="m-0 p-0 " style={{ fontSize: 10, marginRight: 5 }}>
          BAŞARILI
        </p>
      </div>
    ),
  })
  data.unshift({
    date: '10/21/2024',
    class: '12-A',
    schoolNo: '139',
    name: 'İbrahim Sert',
    phone: '05555555555',
    message: 'test',
    readStatus: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 75,
          border: 'none',
          padding: 5,
          backgroundColor: 'red',
          color: 'white',
          borderRadius: 5,
        }}
      >
        <p className="m-0 p-0 " style={{ fontSize: 10, marginRight: 5 }}>
          BAŞARISIZ
        </p>
      </div>
    ),
    result: 'Toplam 1 Adet SMS',
  })

  return data.map((item: any) => {
    return {
      date: item.date,
      name: item.name,
      class: item.class,
      schoolNo: item.schoolNo,
      phone: item.phone,
      message: item.message,
      readStatus: item.readStatus,
    }
  })
}
