import Page from '../Page'
import image from '../../../../../../assets/images/wp-billboard.jpeg'
import image1 from '../../../../../../assets/images/borusanjpg.jpg'
import Carousel from 'react-bootstrap/Carousel'
import Image from 'react-bootstrap/Image'
import { useState } from 'react'

const Features = () => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex)
  }

  return (
    <Page maxHeight={300} overflow="hidden">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Carousel
          activeIndex={index}
          className="w-100 "
          data-bs-theme="dark"
          onSelect={handleSelect}
          interval={3000}
          fade
        >
          <Carousel.Item className="d-flex flex-column align-items-center ">
            <Image src={image} width={'75%'} height={275} />
          </Carousel.Item>
          <Carousel.Item className="d-flex flex-column align-items-center ">
            <Image src={image1} width={'75%'} height={250} />
          </Carousel.Item>
        </Carousel>
      </div>
    </Page>
  )
}

export default Features
