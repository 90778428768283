import Page from '../Page'

const RollCall = () => {
  return (
    <Page width="100%" display="grid" minHeight={400} overflow="scroll">
      E Yoklama
    </Page>
  )
}

export default RollCall
