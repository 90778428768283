import { Card, CardBody, Input } from 'reactstrap'
import { columns } from '../../views/data/columns'
import CustomTable from 'src/views/components/customTable/customTable'

const index = (props: { name: string }) => {
  const data = createData()

  return (
    <div>
      <div
        className="d-flex w-100 "
        style={{ justifyContent: 'space-between' }}
      >
        <div style={{ width: 600 }}>
          <Card className="bg-white w-100">
            <CardBody>
              <div className="mb-1 text-center my-1">
                <h4>Rapor Arama</h4>
              </div>
              <Input
                className="my-1 py-1"
                placeholder="Telefon Numarası İle Arama "
              />
              <Input
                className="my-1 py-1"
                placeholder="Okul Numarası İle Arama"
              />
              <div className=" d-flex justify-content-center align-items-center">
                <div className="btn w-75 my-1 btn-success d-flex btn-block justify-content-center align-items-center ">
                  {props.name} Ara
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="w-100 ms-5">
          <CustomTable data={data} columns={columns} />
        </div>
      </div>
    </div>
  )
}

export default index

function createData() {
  const data = Array(0).fill({
    date: '10/21/2024',
    sender: 'test',
    sendAccount: 'Ana Hesap',
    message: 'YOKLAMA SMS',
    result: 'Toplam 1 Adet SMS Gönderildi',
  })

  return data.map((item: any) => {
    return {
      date: item.date,
      sender: item.sender,
      sendAccount: item.sendAccount,
      message: item.message,
      result: item.result,
    }
  })
}
