import axios from 'axios'
import toast from 'react-hot-toast'
import { logoutClear } from 'src/utility/Utils'

if (localStorage.getItem('user')) {
  const user: any = localStorage.getItem('user')
  const token = JSON.parse(user).token
  axios.defaults.headers.common['authorization'] = `Bearer ${token}`
  axios.defaults.headers.common['Accept-Language'] = `${localStorage.getItem(
    'i18nextLng'
  )}`
  axios.defaults.headers.common['Platform'] = 'web'
}
if (window.origin.includes('localhost')) {
  axios.defaults.baseURL = process.env.REACT_APP_LOCAL
} else {
  axios.defaults.baseURL = process.env.REACT_APP_API
}
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    if (
      response.config.method !== 'get' &&
      !response.data?.description?.includes('getirildi') &&
      response.data?.description &&
      !response.data?.description?.includes('Listelendi')
    ) {
      toast.success(response.data.description)
    }
    return response
  },
  function (error) {
    if (error.response.status === 500) {
      toast.error('Server Error!')
    }
    /*if (error.response.status === 400) {
      toast.error('Veriler getirilemedi lütfen daha sonra Tekrar deneyiniz.')
    }*/
    // if (![400, 500].includes(error.response.status)) {
    //   toast.error('Bilinmeyen Hata!')
    // }
    error.response.status === 401 //&& window.location.assign("/giris")
    if (
      !error.response.request.responseURL.includes('/last/notes') &&
      !error.response.data?.message?.includes('record not found')
    ) {
     // console.log(error.response.data)
       // ; (error.response.data.message ?? error.response.data.description) &&
        //  toast.error(error.response.data.message ?? error.response.data.description)
    }
    if (
      error.response.data.message === 'invalid or expired jwt' &&
      window.location.href.includes('/login/admin') === false
    ) {
      window.location.href = '/giris'
      logoutClear()
    }
    return Promise.reject(error)
  }
)
