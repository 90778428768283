import Table from 'react-bootstrap/Table'
import Page from '../Page'
import TableComponent from './table.js'

const WhatsappReport = () => {
  return (
    <Page
      width="100%"
      overflow="scroll"
      display="grid"
      maxHeight={375}
      minHeight={300}
    >
      <TableComponent
        rows={data}
        columns={[
          'Tarih-Saat',
          'İlgili Müşteri',
          'Telefon',
          'Çalışan',
          'Hizmet',
          'Not',
          'İstatistik',
        ]}
      />
    </Page>
  )
}

export default WhatsappReport

const data = [
  createData(
    '12.09.2023',
    'Melih',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih1',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'WP',
    'Not',
    'İstatistik'
  ),
]

function createData(
  date: string,
  customer: string,
  phone: string,
  employee: string,
  service: string,
  note: string,
  statistic: string
) {
  return {
    date,
    customer,
    phone,
    employee,
    service,
    note,
    statistic,
  }
}
