import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import Accordion from 'react-bootstrap/Accordion'
import { useEffect, useState } from 'react'
import ImageUploader from './downloadImg'
const index = () => {
  const [modal, setModal] = useState(false)
  const [uploadedImages, setUploadedImages] = useState<{ name: string, image: string | null }[]>([]);

  const handleImageChange = (image: string | null, name: string) => {
    // Yeni bir resim ekleyerek diziyi güncelle
    setUploadedImages((prevImages) => [...prevImages, { name, image }]);
  };

  return (
    <div>

      <Row className="d-flex justify-content-center">
        <Col xs="12" sm="6" lg="4" md="6">
          <Card style={{ backgroundColor: "#FFFFFF" }}>
            <CardHeader>
              <h2>Yüklenmesi Gereken Evraklar</h2>
            </CardHeader>
            <CardBody>
              <Col md="12" className="text-center my-1">
                Evraklarınızı Sürükleyip Bırakarak Yada Kutulara Tıklayıp
                Seçerek Yükleyebilirsiniz.
              </Col>
              <Col>
                <Accordion className="pb-1" flush>
                  <Accordion.Item eventKey={'0'}>
                    <Accordion.Header>
                      Kimlik / Nüfus Cüzdanı (Tüm kurumlar)
                    </Accordion.Header>
                    <Accordion.Body>
                      <ImageUploader onImageChange={(image) => handleImageChange(image, 'Kimlik')} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={'1'}>
                    <Accordion.Header>Sözleşme (Tüm kurumlar)</Accordion.Header>
                    <Accordion.Body>
                      <ImageUploader onImageChange={(image) => handleImageChange(image, 'Sözleşme')} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={'2'}>
                    <Accordion.Header>
                      Görev Yeri (MEB resmi okullar)
                    </Accordion.Header>
                    <Accordion.Body>
                      <ImageUploader onImageChange={(image) => handleImageChange(image, 'Görev Yeri')} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={'3'}>
                    <Accordion.Header>
                      Vergi Levhası / Faaliyet Belgesi (Özel kurumlar)
                    </Accordion.Header>
                    <Accordion.Body>
                      <ImageUploader onImageChange={(image) => handleImageChange(image, 'Vergi Levhası ')} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={'4'}>
                    <Accordion.Header>
                      İkametgah (Tüm kurumlar)
                    </Accordion.Header>
                    <Accordion.Body>
                      <ImageUploader onImageChange={(image) => handleImageChange(image, 'İkametgah')} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col className="d-flex justify-content-center my-1 ">
                <Button className="btn btn-warning">Sözleşmeyi indir</Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" sm="6" lg="4" md="6" >
          <Card style={{ height: '505px', backgroundColor: "#FFFFFF" }}>
            <CardHeader>
              <h2>Yüklenmiş Evraklar</h2>
            </CardHeader>
            <CardBody>
              <Row>
              {uploadedImages.map(({ name, image }, index) => (
                <Col md="3" className='my-1' >
                <div key={index}>
                  {image && (
                    <div className='d-flex flex-column align-items-center ' >
                      <img src={image} alt="Selected" style={{ maxWidth: '100px',maxHeight:"100px" }} />
                      <span className='mt-1'>{name}</span>
                    </div>
                  )}
                </div>
                </Col>
              ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" sm="6" lg="4" md="6" >
          <Card style={{ height: '505px', backgroundColor: "#FFFFFF" }}>
            <CardHeader className="p-0">
              <img style={{ width: '100%' }} src="/image/evrak.jpg" alt="" />
            </CardHeader>
            <CardBody>
              <Card
                style={{
                  backgroundColor: '#FFFFFF',
                  position: 'relative',
                  bottom: '32px',
                }}
              >
                <CardHeader className="d-flex justify-content-center">
                  <h4 className="text-center ">
                    Evrakları Nasıl Hazırlayacağım?
                  </h4>
                </CardHeader>
                <CardBody className="text-center">
                  <Col className="my-1">
                    Evraklar konusunda kafanızda soru işaretleri mi var? Size
                    yardımcı olmak için buradayız.
                  </Col>
                  <Col>
                    <Button

                      color='#28B5DC'
                      style={{ backgroundColor: "#28B5DC" }}
                      onClick={() => {
                        setModal(true)
                      }}
                      className=" btn text-white  rounded-5 "

                    >
                      Devamını Oku...
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        className="modal-dialog-centered modal-xl py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        >
          <h3>Evrakları Nasıl Hazırlayacağım?</h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6">
              Abonelik sözleşmesi: Sözleşmede ilgili alanların doldurulup her
              sayfasının imzalanması, son sayfada Abone alanına okul mührü
              vurulması gerekmektedir.Abonelik sözleşmesini indirmek için
              Tıklayın. Abone sözleşmesi nasıl doldurulmalı? Video’yu izlemek
              için Tıklayın! Görev Belgesi: MEBBİS Sistemi üzerinden
              almalısınız. Not: Okul müdürü haricinde yapılan aboneliklerde
              müdür tarafından imzalanmış “Yetki Belgesi” gereklidir. Kimlik
              Fotokopisi: Okul müdürüne ait Kimlik kartı ve ya T.C. kimlik
              numarası bulunan Nüfus Cüzdanı, Sürücü Ehliyetinin önlü arkalı
              fotokopisi ve ya fotoğrafı gereklidir. Not: Yeni tip Sürücü
              ehliyetleri üzerinde T.C. numarası yazmadığından dolayı kabul
              edilmemektedir.
            </Col>
            <Col md="6">
              <div className="embed-responsive embed-responsive-16by9 ">
                <iframe
                  width={550}
                  height={250}
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/VJ0EwNn19Nc?si=XuR09bK4_GF8tc8r"
                  allowFullScreen
                />
              </div>
            </Col>
            <Col md="12">
              <b>Özel okullar için ayrıca istenen belgeler;</b>
              <ul>
                <li> Vergi levhası</li>
                <li>Eğitim Kurumu Açma ve Çalıştırma Ruhsatı</li>
              </ul>
              Evraklarınızın taratılmış nüshasını ve ya fotoğrafını,
              destek@ilksms.com mail adresine veya 0212 909 25 54 numaralı
              whatsapp numaramıza göndermeniz gerekmektedir.
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
