import SmsReports from './components/Reports/SmsReports'
import WhatsappReports from './components/Reports/WhatsappReports'
import RollCall from './components/Reports/RollCall'
import Birthdays from './components/Reports/Birthdays'
import Appointments from './components/Reports/Appointments'
import AccountInfo from './components/Account/Account'
import Features from './components/Features/Features'
import Messages from './components/Messages/Messages'
import Contact from './components/Contact/Contact'
import Updates from './components/Updates/Updates'

export const reportTabs = [
  {
    id: 1,
    title: 'Sms Raporları',
    content: <SmsReports />,
    tooltip:
      'SMS gönderim durumu, teslim edilen ve teslim edilmeyen mesajlar hakkında istatistikler ve diğer detaylı bilgileri içerir.',
  },
  {
    id: 2,
    title: 'Whatsapp Raporları',
    content: <WhatsappReports />,
    tooltip:
      'WhatsApp üzerinden gönderilen mesajların gönderilme tarihleri, okundu durumları,  ve diğer detayları içerir.',
  },
  {
    id: 3,
    title: 'E Yoklama',
    content: <RollCall />,
    tooltip:
      'Öğrencilerin devamsızlık durumlarına anında erişimi sağlar, günlük ve toplu devamsızlık durumu hakkında detaylı bilgileri içerir.',
  },
  {
    id: 4,
    title: 'Doğum Günleri',
    content: <Birthdays />,
    tooltip:
      'Doğum günü dileklerini iletmek ve kişisel ilişkileri güçlendirmek için kullanılır.',
  },
  {
    id: 5,
    title: 'Veli Randevuları',
    content: <Appointments />,
    tooltip:
      'Öğretmen-veli görüşmelerinde, online randevu alabilmeleri için kullanılır',
  },
]

export const updateTabs = [
  {
    id: 1,
    title: 'Güncellemeler',
    content: <Updates />,
    tooltip:
      'Okul profili, kurum ve temsilci bilgileriniz hakkında detaylı bilgileri içerir',
  },
]

export const accountTabs = [
  {
    id: 1,
    title: 'Hesap Bilgileri',
    content: <AccountInfo />,
    tooltip: 'Hesap Bilgilerinizi Görüntüleyebilirsiniz',
  },
]

export const featureTabs = [
  {
    id: 1,
    title: 'Yeni Özellikler',
    content: <Features />,
    tooltip: 'Yeni Özellikleri Görüntüleyebilirsiniz',
  },
]

export const messageTabs = [
  {
    id: 1,
    title: 'Mesaj İstatistikleri',
    content: <Messages />,
    tooltip: 'Mesaj İstatistiklerini görüntüleyebilirsiniz',
  },
]

export const contactTabs = [
  {
    id: 1,
    title: 'Bize Yazın',
    content: <Contact />,
    tooltip: 'Bizimle Bu Yollarla İletişime Geçebilirsiniz',
  },
]
