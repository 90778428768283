import Page from '../Page'

const Appointments = () => {
  return (
    <Page width="100%" display="grid" minHeight={400} overflow="scroll">
      Veli Randevu
    </Page>
  )
}

export default Appointments
