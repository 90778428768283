import Table from 'react-bootstrap/Table'
import Page from '../Page'
import TableComponent from './table.js'

const SmsReports = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        overflow: 'scroll',
      }}
    >
      <TableComponent
        rows={data}
        columns={[
          'Tarih-Saat',
          'İlgili Müşteri',
          'Telefon',
          'Çalışan',
          'Hizmet',
          'Not',
          'İstatistik',
        ]}
      />
    </div>
  )
}

export default SmsReports

const data = [
  createData(
    '12.09.2023',
    'Melih',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih1',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
  createData(
    '12.09.2023',
    'Melih2',
    '0555 555 55 55',
    'Employee',
    'SMS',
    'Not',
    'İstatistik'
  ),
]

function createData(
  date: string,
  customer: string,
  phone: string,
  employee: string,
  service: string,
  note: string,
  statistic: string
) {
  return {
    date,
    customer,
    phone,
    employee,
    service,
    note,
    statistic,
  }
}
