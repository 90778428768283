import { useState } from 'react'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomTable from 'src/views/components/customTable/customTable'
import Templates from './templates/index'

const index = () => {

  const columns = [
    {
      name:"Şablon Adı",
      sortable:true,
      selector:(row: {name:any})=>row.name,
    },
    {
      name:"İşlemler",
      sortable:true,
      selector:(row: {operations:any})=>row.operations,
    },
  ]
  const templates = [
    {
      name:"Test Şablonu1",
    },
    {
      name:"Test Şablonu2",
    },
    {
      name:"Test Şablonu3",
    },
    {
      name:"Test Şablonu4",
    },
    {
      name:"Test Şablonu5",
    },
  ]
  const data = templates.map((item:any)=>{
    return {
      name:item.name,
      operations:(
        <div>
        <div className='btn btn-danger'>
          Sil
        </div>
        <div className='btn btn-warning mx-1'>
          Güncelle
        </div>
        </div>
      )
    }
  })
  return (
    <div>
      <Row>
        <Col style={{borderRight:"2px solid #cccc"}}  sm="12" md="12" lg="6" >
          <Templates/>
        </Col>
        <Col sm="12" md="12" lg="6"  >
          <CustomTable data={data} columns={columns} >
          </CustomTable>
        </Col>
      </Row>
    </div>
  )
}

export default index
