import { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import NewTemplates from './newTemplates'
import BirthdayTemplates from './birthdayTemplates'
import EschoolTemplates from './eSchoolTemplates'
const index = () => {
    const { t }: any = useTranslation()
    const [active, setActive] = useState(1)
    const [activeType, setActiveType] = useState(1)
    const toggleTab = (tab: any) => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    const formik: any = useFormik({
        initialValues: {
            message: '',
            files: [],
        },
        validationSchema: yup.object({
            message: yup.string(),
        }),
        onSubmit: async (values) => { },
    })
    useEffect(() => {
        formik.resetForm()
    }, [active, activeType])
    return (
        <div className="fontFamily">
            <Nav
                style={{ width: '100%', backgroundColor: '#FFFFFF' }}
                pills
                className="flex-wrap  "
            >
                <NavItem className="" style={{ width: '31%', marginRight: 'px' }}>
                    <NavLink
                        style={{
                            marginTop: '3px',
                            marginBottom: '3px',
                            borderColor: '#1F59AA',
                            color: active === 1 ? 'white' : '#1F59AA',
                            backgroundColor: active === 1 ? '#1F59AA' : 'inherit',
                        }}
                        className="  border-2  rounded"
                        active={active === 1}
                        onClick={() => {
                            toggleTab(1)
                        }}
                    >
                        <span className="fw-bold">{t('Yeni Şablon')}</span>
                    </NavLink>
                </NavItem>

                <NavItem className="mx-1" style={{ width: '31%' }}>
                    <NavLink
                        style={{
                            marginTop: '3px',
                            marginBottom: '3px',
                            borderColor: '#1F59AA',
                            color: active === 2 ? 'white' : '#1F59AA',
                            backgroundColor: active === 2 ? '#1F59AA' : 'inherit',
                        }}
                        className="border-  border-2  rounded"
                        active={active === 2}
                        onClick={() => {
                            toggleTab(2)
                        }}
                    >
                        <span className="fw-bold">
                            {t('Doğum Günü Şablonu')}
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem className="mx-1" style={{ width: '31%' }}>
                    <NavLink
                        style={{
                            marginTop: '3px',
                            marginBottom: '3px',
                            borderColor: '#1F59AA',
                            color: active === 3 ? 'white' : '#1F59AA',
                            backgroundColor: active === 3 ? '#1F59AA' : 'inherit',
                        }}
                        className="border-  border-2  rounded"
                        active={active === 3}
                        onClick={() => {
                            toggleTab(3)
                        }}
                    >
                        <span className="fw-bold">{t('E-Yoklama Şablonu')}</span>
                    </NavLink>
                </NavItem>

            </Nav>

            <TabContent
                style={{ display: activeType === 2 ? 'none' : 'grid' }}
                activeTab={active}
            >
                <TabPane tabId={1}>
                    <NewTemplates/>
                </TabPane>

                <TabPane tabId={2}>
                    <BirthdayTemplates/>
                </TabPane>
                <TabPane tabId={3}>
                    <EschoolTemplates/>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default index
