import { useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { SiMicrosoftexcel } from 'react-icons/si'
import { NavLink } from 'react-router-dom'
import { Button, Col, Input, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import * as yup from 'yup'
import FieldOne from './fieldTwo'
import FieldTwo from './fieldOne'
import SmsPhone from '../../../sendMessage/components/phone'
import { useFormik } from 'formik'



const Index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)

  const toggleTab = (tab: number) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {
    },
  })
  const col_2_5 = {
    flex: '0 0 20.8333333333%',
    maxWidth: '20.8333333333%',
}
  const col_5_5 = {
    flex: '37.5%;',
    maxWidth: '37.5%;',
    borderRight:"2px solid #ccc"
}

  return (
    <Row className="justify-content-center mt-2 ">
    <Col  xs="12" sm="12" md="12" lg="12" xl="5" xxl="5" style={{ borderRight: "2px solid #ccc" }}>
      <FieldOne />
    </Col>
    <Col xs="12" sm="12" md="12" lg="12" xl="7" xxl="5" style={{ borderRight: "2px solid #ccc" }}>
      <FieldTwo  />
    </Col>
    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="2"   className=" d-none d-xxl-block" >
      <SmsPhone formik={formik} />
    </Col>
  </Row>
  )
}

export default Index
