import { useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { SiMicrosoftexcel } from 'react-icons/si'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import * as yup from 'yup'
import FieldOne from './fieldOne/index'
import FieldTwo from './fieldTwo/index'
import SmsPhone from '../phone/index'
import { useFormik } from 'formik'

type Props = {
  active: any
  formik: any
  activeType: any
}

const Index = (props: Props) => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [columnsName, setColumnsName] = useState('')

  const toggleTab = (tab: number) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  const handleFieldTwoDataChange = (data: any) => {
    setColumnsName(data)
  }


  return (
    <Row className="justify-content-center">
      <Col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="6"
        xxl="5"
        style={{ borderRight: '2px solid #ccc' }}
      >
        <FieldOne onDataChange={handleFieldTwoDataChange} />
      </Col>
      <Col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="6"
        xxl="5"
        style={{ borderRight: '2px solid #ccc' }}
      >
        <FieldTwo  columnsName={columnsName} />
      </Col>
      <Col xs="12" sm="12" md="12" lg="12" xl="6" xxl="2">
        <SmsPhone formik={formik} />
      </Col>
    </Row>
  )
}

export default Index
