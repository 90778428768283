import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AiOutlineCloudUpload, AiOutlineQuestionCircle } from 'react-icons/ai'
import CustomTable from 'src/views/components/customTable/customTable'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import { IoReloadCircleSharp } from 'react-icons/io5'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { Scrollbar } from 'react-scrollbars-custom'
import { FaSearchPlus } from 'react-icons/fa'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import { useFormik } from 'formik'
import CustomExcel from '../../components/CustomExcel'

const index = (props: any) => {
  const [modal, setModal] = useState(false)
  const [active, setActive] = useState(true)
  const [excelModal, setExcelModal] = useState(false)
  const [show, setShow] = useState(false)
  const { t }: any = useTranslation()
  const [excelData, setExcelData] = useState<any[][] | null>(null)

  const formik = useFormik({
    initialValues: {
      saveType: { name: 'Öğrenci', id: 1 },
    },
    onSubmit(values) {},
  })

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()

    reader.onload = (e: any) => {
      const binaryData = e.target.result
      const workbook = XLSX.read(binaryData, { type: 'binary' })

      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]

      const data: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      setExcelData(data)
    }

    reader.readAsBinaryString(file)
  }

  const col = excelData ? excelData[0]?.join(',') : ''

  const columns2 = excelData
    ? excelData[0].map((item: any) => ({
        name: item,
        Sortable: true,
        selector: (row: any) => row[item],
      }))
    : []

  const selectionColumn: any = {
    name: 'Seç',
    selector: (row: any) => row.selected,
    sortable: false,
    cell: (row: any) => (
      <Input
        type="checkbox"
        checked={row.selected}
        onChange={() => handleCheckboxChange(row)}
      />
    ),
  }

  columns2.unshift(selectionColumn)

  const data1 = excelData
    ? excelData.slice(1).map((item: any, index: number) => {
        const row: any = {
          selected: true,
          index: index,
        }
        excelData[0].forEach((columnName: any, i: number) => {
          row[columnName] = item[i]
        })
        return row
      })
    : []
  const handleCheckboxChange = (row: any) => {
    const update = [excelData ? [...excelData] : '']
  }

  if (typeof props.onDataChange === 'function') {
    props.onDataChange(col)
  }

  useEffect(() => {
    if (excelData ? excelData.length > 0 : '') {
      setActive(false)
    }
  }, [excelData])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const options = [
    {
      name: 'Öğrenci',
      id: 1,
    },
    {
      name: 'Öğretmen',
      id: 2,
    },
    {
      name: 'Personel',
      id: 3,
    },
  ]
  const OptionsMaps = options.map((item: any) => {
    return {
      name: item.name,
      id: item.id,
    }
  })
  return (
    <Row className="mt-5">
      <Col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="6"
        xxl="6"
        style={{ borderRight: '2px solid #ccc' }}
      >
        {active ? (
          <div>
            <div className="mb-2">
              <Label>Kayıt Türü</Label>
              <SelectOptions
                value={formik.values.saveType}
                onChange={(e: any) => {
                  formik.setFieldValue('saveType.name', e.name)
                  formik.setFieldValue('saveType.id', e.id)
                }}
                options={OptionsMaps}
              />
              <span>
                {
                  " Öğretmen kaydederken 'ÖĞRETMEN' servis şoförleri ve hizmet personeli kaydederken 'PERSONEL' seçiniz"
                }
              </span>
            </div>
            <CustomExcel />
          </div>
        ) : (
          <>
            <Row>
              <Col md="12" className=" d-flex justify-content-center">
                <div className="">
                  <div
                    onClick={() => setActive(true)}
                    style={{ cursor: 'pointer' }}
                    className=" d-flex justify-content-center align-items-center"
                  >
                    <AiOutlineCloudUpload size={40} color="#AAAAAA" />
                  </div>
                  <div className=""> Excel Dosyasını Tekrar Yükle</div>
                </div>
              </Col>
              <Col md="12">
                <Scrollbar className="" style={{ width: '100%', height: 400 }}>
                  <CustomTable notPage data={data1} columns={columns2}>
                    <Button color="primary" onClick={() => setExcelModal(true)}>
                      <FaSearchPlus size={'25px'} />
                    </Button>
                  </CustomTable>
                </Scrollbar>
              </Col>
            </Row>
          </>
        )}
      </Col>
      <Col
        style={{ backgroundColor: '#FFFFFF' }}
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="6"
        xxl="6"
        className="px-2 py-2"
      >
        <h3
          style={{ borderBottom: '2px solid #ccc' }}
          className="pb-1 text-center"
        >
          Excel Örneği
        </h3>
        <span>Excel dosyanız aşağıdaki görsel formatında olmalıdır.</span>
        <br />
        <span>Sütün Sıralaması;</span>
        {formik.values.saveType.id === 1 ? (
          <>
            <ol>
              <li>
                Okul Numarası{' '}
                <b className="text-danger">(Öğrenciyse Zorunlu)</b>
              </li>
              <li>
                Ad Soyad <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>
                {' '}
                Veli Ad Soyad{' '}
                <b className="text-danger">(Öğrenciyse Zorunlu)</b>
              </li>
              <li>
                Telefon <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>
                Sınıf/Grup <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>2. Veli Adı (Opsiyonel)</li>
              <li>2. Veli Telefonu (Opsiyonel)</li>
              <li>Rehber Türü (Opsiyonel)</li>
              <li>Doğum Tarihi (Opsiyonel)</li>
              <li>Öğrenci Telefon (Opsiyonel)</li>
            </ol>
            <div>
              Sütunlar Excel dosyasında bulunmak zorundadır fakat tüm sütunların
              dolu olması zorunlu değildir. Örneğin, personel kaydederken Okul
              Numarası veya Veli Adı alanları boş bırakılabilir ama sütun
              sıralamasını bozmamak için Excel dosyası formatı örneğimizdeki
              gibi olmalıdır.
            </div>
            <div className="my-1 d-flex justify-content-center">
              <img
                style={{ width: '700px' }}
                src="/image/excelPhoto.jpg"
                alt=""
              />
            </div>
            <div
              style={{ width: '100%' }}
              className="d-flex justify-content-center align-items-center"
            >
              <div style={{ width: '80%' }} className="btn btn-success">
                Örnek Öğrenci Excel Dosyası İndir
              </div>
            </div>
          </>
        ) : formik.values.saveType.id === 2 ? (
          <>
            <ol>
              <li>
                Ad Soyad <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>
                Telefon <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>
                Sınıf/Grup <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>Ders 1 (Opsiyonel)</li>
              <li>Ders 2 (Opsiyonel)</li>
              <li>Doğum Tarihi (Opsiyonel)</li>
            </ol>

            <div>
              AÇIKLAMA: <br /> Dosyadaki sütun sayısı değişmemelidir. Örnek
              dosyamızın formatını değiştirmeden verilerinizi giriniz. Ders-1:
              Öğretmenin okulda girdiği ders adı. Örn: Türkçe, Matematiki Tarih…
              Ders-2: Öğretmen okulda farklı bir derse daha giriyorsa bu alana
              yazınız. Ders bilgisini girmek zorunlu değildir fakat 1. ders
              devamsızlık yoklaması alırken veya sms mesaj içerisinde öğretmen
              imzası kullanmak istemeniz durumunda bu alanı doldurunuz.
            </div>
            <div className="my-1 d-flex justify-content-center">
              <img
                style={{ width: '700px' }}
                src="/image/excelPhoto.jpg"
                alt=""
              />
            </div>
            <div
              style={{ width: '100%' }}
              className="d-flex justify-content-center align-items-center"
            >
              <div style={{ width: '80%' }} className="btn btn-success">
                Örnek Öğretmen Excel Dosyası İndir
              </div>
            </div>
          </>
        ) : (
          <>
            <ol>
              <li>
                Ad Soyad <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>
                Telefon <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>
                Sınıf/Grup <b className="text-danger">(Zorunlu)</b>
              </li>
              <li>Doğum Tarihi (Opsiyonel)</li>
            </ol>
            <div className="my-1 d-flex justify-content-center">
              <img
                style={{ width: '700px' }}
                src="/image/excelPhoto.jpg"
                alt=""
              />
            </div>
            <div
              style={{ width: '100%' }}
              className="d-flex justify-content-center align-items-center"
            >
              <div style={{ width: '80%' }} className="btn btn-success">
                Örnek Personel Excel Dosyası İndir
              </div>
            </div>
          </>
        )}
      </Col>

      <Modal
        className="modal-dialog-centered modal-xl py-1"
        isOpen={excelModal}
        toggle={() => setExcelModal(!excelModal)}
      >
        <ModalHeader
          style={{ backgroundColor: '#1F59AA' }}
          className="d-flex justify-content-center align-items-center text-center "
          closeButton
          toggle={() => setExcelModal(!excelModal)}
        >
          <h4 className="text-white">Aktarılan Kayıtlar</h4>
        </ModalHeader>
        <ModalBody>
          <div>
            <CustomTable notPage data={data1} columns={columns2} />
          </div>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        ></ModalHeader>
        <ModalBody className="text-center px-1  ">
          <div className="text-black">
            <AiOutlineQuestionCircle size={100} color="black" />
          </div>
          <div className=" mt-1 px-1 p ">
            <h4>Nasıl Kullanılır?</h4>
          </div>
          <div className="px-1 py-1 ">
            <p>
              E-Okuldan indireceğiniz data-only dosyasını hiçbir değişiklik
              yapmadan sisteme yükleyiniz.
            </p>
            <p>
              {
                "OrtaÖğretim Kurum İşlemleri/ Not İşlemleri/ Ders Notu Girişi/ Raporlar(Yazıcı Sembolü)/ Puan Çizelgeleri (Yeni Yönetmeliğe Uygun)/ (en üstte 'Gösterici Seçenekleri'ni HTML5 Word Excel yapıp) açılan sayfada yazıcı sembolünün yanıdaki oka tıklayıp 'Excel Sadece Veri'yi seçip indirebilirsiniz."
              }
            </p>
            <p>
              {
                'Rehberinizde çakışan numaralar varsa Rehber Türü seçerek karışıklığı önleyebilirsiniz.'
              }
            </p>
            <p>
              {
                'Dosyanızı hiç bir değişiklik yapmadan yandan sisteme aktarınız.'
              }
            </p>
            <p>
              {
                'Dosyanız aktarıldıktan sonra devamsızlık durumuna göre gerekli seçimleri yapıp ilerleyiniz.Devamsızlık bilgisinin gideceği kişiler ve mesaj içeriği listelenecektir.Dilerseniz mesajın sonundaki koyu yazılmış metinleri değiştirebilir veya kaldırabilirsiniz.Ardından Mesajı Gönder butonuyla seçilenlere mesaj gönderim işlemini tamamlayınız.'
              }
            </p>
            <p>
              {
                'Sadece E-Okuldan alınacak olan (LİSELER İÇİN) Öğrenci Devamsızlık Toplamları (Öğretim Yılı) dosyasıyla çalışır.'
              }
            </p>
          </div>
        </ModalBody>
      </Modal>
    </Row>
  )
}

export default index
