import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { SiMicrosoftexcel } from 'react-icons/si'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import HighSchool from './highSchool'
import MidSchool from './midSchool'

import { FaWhatsapp } from 'react-icons/fa'
import { FcGraduationCap, FcOrganization } from 'react-icons/fc'

const index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const toggleTabType = (tab: any) => {
    if (activeType !== tab) {
      setActiveType(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])
  return (
    <>
      <Nav
        style={{ width: '100%' }}
        pills
        className=" d-flex align-items-center justify-content-start "
      >
        <NavItem
          className=""
          style={{
            width: '20.3%',
          }}
        >
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#E6E5EA',
              backgroundColor: active === 1 ? '#FFA24A' : 'inherit',
            }}
            className="border  border-2  rounded"
            active={active === 1}
            onClick={() => {
              toggleTab(1)
            }}
          >
            <FcGraduationCap />
            <span className="fw-bold">{t('Lise')}</span>
          </NavLink>
        </NavItem>

        <NavItem
          className="mx-1"
          style={{
            width: '20.5%',
          }}
        >
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#E6E5EA',
              backgroundColor: active === 2 ? '#24D6EB' : 'inherit',
            }}
            className="border-  border-2  rounded"
            active={active === 2}
            onClick={() => {
              toggleTab(2)
            }}
          >
            <FcOrganization />
            <span className="fw-bold">{t('Orta Okul')}</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent
        style={{ display: activeType === 2 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <HighSchool />
        </TabPane>

        <TabPane tabId={2}>
          <MidSchool />
        </TabPane>
      </TabContent>
    </>
  )
}

export default index
