import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import Guides from '../../../../../../../components/guides'
import * as yup from 'yup'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import debounce from 'lodash/debounce';
import { useFormik } from 'formik'
const Index = (props: any) => {
  const { t }: any = useTranslation()
  const [count, setCount] = useState(0)

  const formik: any = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {
    },
  })
  const fprmiks = props.formik ? props.formik : null
  useEffect(() => {
    if (fprmiks) {
      fprmiks.setFieldValue(
        'message',
        formik.values.message
      )
    }

  }, [formik.values.message])

  const messageEle = document.getElementById('textarea');

  if (messageEle) {
    const debounceHandler = debounce(function (e: any) {
      const target = e.target;
      const maxLength = target.getAttribute('maxlength');
      const currentLength = target.value.length;
      setCount(currentLength);
    }, 300);
    messageEle.addEventListener('input', debounceHandler);
  }
  return (
    <Row className="px-1">
      <Guides />
      <Col md="6" className="mb-1">
        <div>
          <Label>
            WhatsApp Numarası
          </Label>
          <Input disabled value={"5306556725"} />
        </div>
      </Col>
      <Col md="6" className="mb-1">
        <div>
          <CustomSelect label={t('Hızı')} />
        </div>
      </Col>
      <Col md="6" className="mb- bg-">
        <div className='d-flex justify-content-between align-items-center'>
          <Label>Mesaj</Label>
          <Label>
            <b style={{ fontSize: '12px' }}>1 SMS({count} Karakter)</b>
          </Label>
        </div>
        <Input
          id="textarea"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          maxlength="400"
          className='messageField'
          type="textarea"
        />
      </Col>
      <Col md="6" className='bg-' >
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Şablonlar')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Tür')} />
          </div>
        </Col>
        <Col md="12" className="my-1">
          <div>
            <Label>
              Dosya Seç
            </Label>
            <Input type="file" />
          </div>
        </Col>
      </Col>
      <Col md="6" style={{ marginBottom: '5px' }} className="">
        <Col md="12" className="d-flex justify-content-around align-items-center my-1 ">

          <div
            style={{ paddingTop: "5px", paddingBottom: "5px", backgroundColor: "#5F53DC" }}
            className="rounded-5 border btn text-white   text-center d-flex align-items-center"
          >
            <b style={{ fontSize: '11px' }}> Okul No</b>
          </div>
          <div
            style={{ paddingTop: "5px", paddingBottom: "5px", backgroundColor: "#5F53DC" }}
            className="rounded-5 border btn text-white  text-center d-flex align-items-center"
          >
            <b style={{ fontSize: '11px' }}> Öğrenci Ad Soyad</b>
          </div>
          <div
            style={{ paddingTop: "5px", paddingBottom: "5px", backgroundColor: "#5F53DC" }}
            className="rounded-5 border btn text-white   text-center d-flex align-items-center"
          >
            <b className='' style={{ fontSize: '11px' }}>Veli Ad Soyad</b>
          </div>
        </Col>
      </Col>
      <Row className="mb-">
        <Col md="6" style={{ marginBottom: '10px' }}>
          <div className="d-flex align-items-center">
            <Input type="checkbox" />
            <span
              className='messageCheckbox'
            >
              {t('Mükerrer Mesajları Sil')}
            </span>
          </div>
        </Col>
        <Col md="6" style={{ marginBottom: '10px' }}>
          <div className="d-flex align-items-center">
            <Input type="checkbox" />
            <span
              className='messageCheckbox'
            >
              {t('Mesajı Şablon Olarak Kaydet')}
            </span>
          </div>
        </Col>
      </Row>
      <Col md="12" className="mt-1">
        <div
          className="btn text-white d-flex align-items-center justify-content-center btn-block"
          style={{ backgroundColor: '#1F59AA' }}
        >
          Mesajı Gönder
          <Send className="font-medium-3 ms-2" color="white" />
        </div>
      </Col>
    </Row>
  )
}

export default Index
