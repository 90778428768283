import { ReactNode, useState } from 'react'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { BsInfoCircleFill } from 'react-icons/bs'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

interface ContentProps {
  tabs: { id: number; title: string; content: ReactNode; tooltip: string }[]
  height?: number | string
  backgroundColor?: string
  tooltip?: string
  topRightBorder?: boolean
  marginBottom?: number
  marginRight?: number
  selectedTab?: string | number
  justify?: boolean
  width?: number | string
  margin?: boolean
  marginTop?: number
  half?: boolean
  scroll?: boolean
}

const Content = (props: ContentProps) => {
  const [active, setActive] = useState(1)

  const toggle = (tab: number) => (active !== tab ? setActive(tab) : null)

  return (
    <div className="d-flex flex-column me-1">
      <Nav className="mb-0" tabs>
        {props.tabs.map((tab) => (
          <NavItem
            style={{
              marginRight: 3,
              backgroundColor: active === tab.id ? '#ede9fe' : 'white',
              borderTopLeftRadius: 18,
              borderTopRightRadius: 18,
              boxShadow: '3px -2px 15px gray',
            }}
          >
            <OverlayTrigger
              placement="right"
              delay={{ show: 400, hide: 100 }}
              overlay={
                <Tooltip placement="right" className="in">
                  {tab.tooltip}
                </Tooltip>
              }
            >
              <NavLink
                className="d-flex"
                active={active === tab.id}
                onClick={() => {
                  toggle(tab.id)
                }}
              >
                <p
                  style={{
                    color: '#7367f0',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {tab.title}
                </p>
                <BsInfoCircleFill color={'#7367f0'} style={{ marginLeft: 5 }} />
              </NavLink>
            </OverlayTrigger>
          </NavItem>
        ))}
      </Nav>
      <TabContent
        className="bg-white"
        style={{
          marginRight: props.margin ? 20 : 0,
          borderBottomLeftRadius: 18,
          borderBottomRightRadius: 18,
          borderTopRightRadius: 18,
          boxShadow: '3px 3px 15px gray',
          overflow: props.scroll ? 'scroll' : 'hidden',
        }}
        activeTab={active}
      >
        {props.tabs.map((tab) => (
          <TabPane tabId={tab.id}>{tab.content}</TabPane>
        ))}
      </TabContent>
    </div>
  )
}
export default Content
