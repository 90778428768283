import { Col, Row } from 'reactstrap'
import CustomDate from 'src/views/components/customDate/CustomDate'

export default () => (
  <Col xs="12" className="mb-1 ">
    <Row>
      <Col className=" d-flex justify-content-center align-items-center ">
        İki Tarih Arası Sorgulama
      </Col>
      <Col className=" d-flex justify-content-center align-items-start ">
        <CustomDate placeholder="Başlangıç Tarihi"></CustomDate>
      </Col>
      <Col>
        <CustomDate placeholder="Bitiş Tarihi"></CustomDate>
      </Col>
      <Col>
        <div className="btn text-white" style={{ backgroundColor: '#1F59AA' }}>
          Sorgula
        </div>
      </Col>
    </Row>
  </Col>
)
