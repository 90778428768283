import React, { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import Guides from '../../../../../components/guides'
import {
  FcBusinessman,
  FcBusinesswoman,
  FcCheckmark,
  FcReading,
} from 'react-icons/fc'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import debounce from 'lodash/debounce'
import { useFormik } from 'formik'
import CustomDate from 'src/views/components/customDate/CustomDate'
const Index = (props: any) => {
  const { t }: any = useTranslation()
  const [isClicked1, setIsClicked1] = useState(true)
  const [isClicked2, setIsClicked2] = useState(false)
  const [isClicked3, setIsClicked3] = useState(false)
  const [count, setCount] = useState(121)
  const [isHover, setİsHover] = useState(false)
  const [isHover1, setİsHover1] = useState(false)
  const [isHover2, setİsHover2] = useState(false)

  const formik: any = useFormik({
    initialValues: {
      message:
        'Sayın [veli ad soyad], [okulno] numaralı öğrenciniz [ad soyad] [tarih] tarihinde [devamsizlikturu] devamsızlık yapmıştır.',
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  const fprmiks = props.formik ? props.formik : null
  useEffect(() => {
    if (fprmiks) {
      fprmiks.setFieldValue('message', formik.values.message)
    }
  }, [formik.values.message])

  const handleClick1 = () => {
    if (isClicked2 === false && isClicked3 === false) {
      setIsClicked1(true)
    } else {
      setIsClicked1(!isClicked1)
    }
  }

  const handleClick2 = () => {
    if (isClicked1 === false && isClicked3 === false) {
      setIsClicked2(true)
    } else {
      setIsClicked2(!isClicked2)
    }
  }

  const handleClick3 = () => {
    if (isClicked1 === false && isClicked2 === false) {
      setIsClicked3(true)
    } else {
      setIsClicked3(!isClicked3)
    }
  }

  const messageEle = document.getElementById('textarea')

  if (messageEle) {
    const debounceHandler = debounce(function (e: any) {
      const target = e.target
      const maxLength = target.getAttribute('maxlength')
      const currentLength = target.value.length
      setCount(currentLength)
    }, 300)
    messageEle.addEventListener('input', debounceHandler)
  }

  return (
    <Row className="px-1 ">
      <Col md="12" className="mb-1">
        <Label className="">
          <b>Orta Okul Günlük Devamsızlık Gönderimi Ekranı</b>
        </Label>
        <div
          style={{ width: '100%' }}
          className="d-flex align-items-center justify-content-between "
        >
          <Guides />
        </div>
      </Col>

      <Col md="12">
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Gönderici Adı')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Türü')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomDate label={t('Devamsızlık Tarihi')} />
          </div>
        </Col>
      </Col>

      <Col md="12" className="mt-1 ">
        <div
          className="btn text-white d-flex align-items-center justify-content-center btn-block"
          style={{ backgroundColor: '#1F59AA' }}
        >
          Mesajı Gönder
          <Send className="font-medium-3 ms-2" color="white" />
        </div>
      </Col>
    </Row>
  )
}

export default Index
