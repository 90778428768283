import React, { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import {
  FcBusinessman,
  FcBusinesswoman,
  FcCheckmark,
  FcReading,
} from 'react-icons/fc'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import debounce from 'lodash/debounce'
import { useFormik } from 'formik'
import CustomDate from 'src/views/components/customDate/CustomDate'
import Guides from '../../../../../components/guides'
import SelectOptions from 'src/views/components/Select/SelectOptions'
const Index = (props: any) => {
  const { t }: any = useTranslation()
  const [isClicked1, setIsClicked1] = useState(true)
  const [isClicked2, setIsClicked2] = useState(false)
  const [isClicked3, setIsClicked3] = useState(false)
  const [count, setCount] = useState(0)

  const formik: any = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  const fprmiks = props.formik ? props.formik : null
  useEffect(() => {
    if (fprmiks) {
      fprmiks.setFieldValue('message', formik.values.message)
    }
  }, [formik.values.message])

  const handleClick1 = () => {
    if (isClicked2 === false && isClicked3 === false) {
      setIsClicked1(true)
    } else {
      setIsClicked1(!isClicked1)
    }
  }

  const handleClick2 = () => {
    if (isClicked1 === false && isClicked3 === false) {
      setIsClicked2(true)
    } else {
      setIsClicked2(!isClicked2)
    }
  }

  const handleClick3 = () => {
    if (isClicked1 === false && isClicked2 === false) {
      setIsClicked3(true)
    } else {
      setIsClicked3(!isClicked3)
    }
  }

  const messageEle = document.getElementById('textarea1')

  if (messageEle) {
    const debounceHandler = debounce(function (e: any) {
      const target = e.target
      const maxLength = target.getAttribute('maxlength')
      const currentLength = target.value.length
      setCount(currentLength)
    }, 300)
    messageEle.addEventListener('input', debounceHandler)
  }

  const messageContent = [
    {
      id: 1,
      name: 'Genel',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) devamsızlık yapmıştır.',
    },
    {
      id: 2,
      name: 'Tam Gün',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) TAM GÜN devamsızlık yapmıştır.',
    },
    {
      id: 3,
      name: 'Yarım Gün',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) Yarım Gün devamsızlık yapmıştır.',
    },
    {
      id: 4,
      name: 'Geç Kaldı',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) okula geç gelmiştir.',
    },
    {
      id: 5,
      name: 'Öğleden Önce',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) öğleden önce devamsızlık yapmıştır.',
    },
    {
      id: 6,
      name: 'Öğleden Sonra',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) öğleden sonra devamsızlık yapmıştır. ',
    },
    {
      id: 7,
      name: 'Önceki Gün',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 18/01/2024 tarihinde (dün) devamsızlık yapmıştır.',
    },
    {
      id: 8,
      name: 'Okul Kursu',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) okul kursuna katılmamıştır. Bilginize.',
    },
    {
      id: 9,
      name: 'Seçmeli Ders',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) seçmeli derse girmemiştir. Bilginize. ',
    },
    {
      id: 10,
      name: 'Evci İzni',
      message:
        'Sayın [veli ad soyad] öğrenciniz [ad soyad] 19/01/2024 tarihinde (bugun) Evci İznine ayrılmıştır. Bilginize. ',
    },
    {
      id: 11,
      name: 'Devamsızlık Mektubu',
      message:
        'Sayın [veli ad soyad], birikmiş devamsızlığı nedeniyle [okul no] numaralı öğrenciniz [ad soyad] hakkında görüşmek için en kısa sürede ilgili müdür yardımcısına başvurunuz..',
    },
  ]
  const content = messageContent.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
      message: item.message,
    }
  })
  return (
    <Row className="px-1 ">
      <Col md="12" className="mb-1">
        <Label className="">
          <b>Orta Okul Günlük Devamsızlık Gönderimi Ekranı</b>
        </Label>
        <div
          style={{ width: '100%' }}
          className="d-flex align-items-center justify-content-between "
        >
          <Guides />
        </div>
      </Col>

      <Col md="6" className="mb-">
        <Label>Mesaj</Label>
        <Input
          placeholder="Mesajınızı Buraya Giriniz..."
          id="textarea1"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          maxlength="400"
          style={{
            height: '190px',
            overflowY: 'scroll',
            scrollbarWidth: 'auto',
            scrollbarColor: 'dark thin',
          }}
          type="textarea"
        />
        <div className="text-center mt-1 d-flex align-items-center  ">
          <b style={{ fontSize: '13px' }}>1 SMS({count} Karakter)</b>
        </div>
      </Col>
      <Col md="6">
        <Col md="12" className="mb-1">
          <div>
            <Label>Devamsızlık Mesaj Seçenekleri</Label>
            <SelectOptions
              options={content}
              onChange={(e: any) => {
              
                formik.setFieldValue('message', e.message)
              }}
            />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Gönderici Adı')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Türü')} />
          </div>
        </Col>
      </Col>

      <Col md="12" className="mt-1 ">
        <div
          className="btn text-white d-flex align-items-center justify-content-center btn-block"
          style={{ backgroundColor: '#1F59AA' }}
        >
          Mesajı Gönder
          <Send className="font-medium-3 ms-2" color="white" />
        </div>
      </Col>
    </Row>
  )
}

export default Index
