import React from 'react'
import { Send } from 'react-feather'
import { Col } from 'reactstrap'
import phone from '../../../../../../../src/assets/images/main/phone.png';
import './index.scss'
const index = (props: any) => {
    return (
        < >
            <Col id="phone" style={{position:"relative",right:"165px",bottom:"40px" }} className='' xs='12' sm='3 ' >
                <div className='phone-container'>
                    <div>
                        <img
                            width={550}
                            src={phone} />
                    </div>
                    <div className='phone'>
                        <div className='user-bar'>
                            <div className='d-flex align-items-center textContainer'>
                                <Send className='phoneSendIcon' color='gray' size={18} />
                                <input className='inputPhone' />
                            </div>
                        </div>
                        <div className='message'>
                            <div className='sent'>
                                <div>
                                    <div className=''>
                                        {props.formik.values.message}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}

export default index