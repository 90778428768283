import Page from '../Page'
import { useState } from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { InfoCircleFill } from 'react-bootstrap-icons'
import Modal from 'react-bootstrap/Modal'

const Updates = () => {
  return (
    <Page overflow="scroll">
      <VerticalTimeline>
        {events.map((event) => {
          const { handleShow, modal } = setModal(event.title, event.description)
          return (
            <>
              <VerticalTimelineElement
                className="mb-0 mt-0 p-0"
                contentStyle={{
                  margin: 0,
                  boxShadow: 'none',
                  backgroundColor: '#ede9fe',
                  borderRadius: 18,
                }}
                onTimelineElementClick={handleShow}
                iconOnClick={handleShow}
                date={event.date}
                iconStyle={{ marginRight: 0, boxShadow: 'none' }}
                contentArrowStyle={{ borderRight: '8 px solid  #ede9fe' }}
                icon={<InfoCircleFill className="text-primary" />}
              >
                <p
                  className="p-0 m-0 text-primary"
                  style={{ wordWrap: 'break-word' }}
                >
                  {event.title}
                </p>
              </VerticalTimelineElement>
              {modal}
            </>
          )
        })}
      </VerticalTimeline>
    </Page>
  )
}

function setModal(title: string, description: string) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const modal = CustomModal(handleClose, show, title, description)

  return { handleShow, modal }
}

const CustomModal = (
  handleClose: any,
  show: any,
  title: string,
  description: string
) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary text-center mt-2">
          <InfoCircleFill size={25} className="text-primary me-2" />
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-secondary text-center mb-2">
        {description}
      </Modal.Body>
    </Modal>
  )
}

export default Updates

const events = [
  {
    date: '12.07.2023',
    title:
      'BULUT SANTRAL-Gelen arama yönetiminde arayan numara bazlı kurallar oluşturabilirsiniz',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni minima ut culpa molestiae laboriosam sit cum molestias dolorem commodi aliquid!',
  },
  {
    date: '12.07.2023',
    title:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda, eligendi earum dolorum deserunt nemo voluptas.',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni minima ut culpa molestiae laboriosam sit cum molestias dolorem commodi aliquid!',
  },
  {
    date: '12.07.2023',
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, distinctio mollitia officia suscipit nisi debitis.',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni minima ut culpa molestiae laboriosam sit cum molestias dolorem commodi aliquid!',
  },
  {
    date: '12.07.2023',
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, distinctio mollitia officia suscipit nisi debitis.',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni minima ut culpa molestiae laboriosam sit cum molestias dolorem commodi aliquid!',
  },
  {
    date: '12.07.2023',
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, distinctio mollitia officia suscipit nisi debitis.',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni minima ut culpa molestiae laboriosam sit cum molestias dolorem commodi aliquid!',
  },
  {
    date: '12.07.2023',
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, distinctio mollitia officia suscipit nisi debitis.',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni minima ut culpa molestiae laboriosam sit cum molestias dolorem commodi aliquid!',
  },
]
