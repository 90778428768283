import { CategoryScale } from 'chart.js'
import { useState } from 'react'
import { Chart, Doughnut } from 'react-chartjs-2'

// Chart.register(CategoryScale)

const Messages = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const data = {
    labels: ['Sms', 'Whatsapp'],
    datasets: [
      {
        label: 'Messages Sent',
        data: [42, 123],
        backgroundColor: ['#ff9e01', '#25D366'],
        hoverOffset: 2,
      },
    ],
  }

  return (
    <div
      style={{
        display: 'flex',
        height: 300,
        width: '100%',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
      }}
    >
      <div style={{ width: 250 }}>
        <Doughnut width={'5%'} data={data} />
      </div>
      <div style={{ marginTop: 100 }}>
        <InfoBox title="Whatsapp" value={123} color="#25D366" />
        <InfoBox title="SMS" value={42} color="#ff9e01" />
      </div>
    </div>
  )
}

export default Messages

const InfoBox = ({
  title,
  value,
  color,
}: {
  title: string
  value: number
  color: string
}) => {
  return (
    <div className="d-flex w-100 justify-content-end" style={{ fontSize: 18 }}>
      <p className={`me-1 `} style={{ color: color }}>
        {title}
      </p>
      <p className="text-black me-1">{value}</p>
    </div>
  )
}
