import { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import HighSchool from '../dailyAbsenteeism/highSchool'
import MiddleSchool from '../dailyAbsenteeism/middleSchool'

import { FcGraduationCap, FcOrganization } from 'react-icons/fc'

const index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])
  return (
    <>
      <Nav
        style={{ width: '100%' }}
        pills
        className="mb-2 d-flex align-items-center justify-content-start "
      >
        <NavItem className="" style={{ width: '20%', marginRight: 'px' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#FFA24A',
              backgroundColor: active === 1 ? '#FFA24A' : 'inherit',
            }}
            className="border-2  rounded"
            active={active === 1}
            onClick={() => {
              toggleTab(1)
            }}
          >
            <FcGraduationCap />
            <span className="fw-bold">{t('Lise')}</span>
          </NavLink>
        </NavItem>

        <NavItem className="mx-1" style={{ width: '20.8%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#28B5DC',
              backgroundColor: active === 2 ? '#28B5DC' : 'inherit',
            }}
            className=" border-2  rounded"
            active={active === 2}
            onClick={() => {
              toggleTab(2)
            }}
          >
            <span
              style={{
                color: active === 2 ? 'white' : '#28B5DC',
              }}
              className="fw-bold"
            >
              <FcOrganization />
              {t('Orta Okul')}
            </span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent
        style={{ display: activeType === 2 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <HighSchool />
        </TabPane>

        <TabPane tabId={2}>
          <MiddleSchool />
        </TabPane>
      </TabContent>
    </>
  )
}

export default index
