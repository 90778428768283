import { useFormik } from 'formik'
import { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import Guides from '../../../components/guides'

const index = () => {
  const [modal, setModal] = useState(false)
  const formik = useFormik({
    initialValues: {
      dailyAbsenteeism:
        'Sayin [veli ad soyad], [okul no] numarali ogrenciniz [ad soyad] [tarih] tarihinde [devamsizliksebep][ozurtipi] devamsizlik yapmistir. Bilginize',
      massAbsenteeism:
        'Sayın [veli ad soyad], [okul no] numarali ogrenciniz [ad soyad] [ozursuztoplam] gun ozursuz, [ozurlutoplam] gun ozurlu olmak uzere toplam [devamsizliktoplam] gun devamsizlik yapmistir. Bilginize',
      letterAbsenteeism:
        'Sayin [veli ad soyad], [okul no] numarali ogrenciniz [ad soyad] toplam [devamsizliktoplam] gun devamsizlik yapmistir. Toplam devamsızlık içerisinde OZURLU ve OZURSUZ devamsızlık toplamı bulunmaktadır.',
      twoDaysAbsenteeism:
        'Sayin [veli ad soyad], [okul no] numarali ogrenciniz [ad soyad] [tarih1] art arda iki gün devamsizlik yapmistir. Bilginize',
    },
    onSubmit: (values) => { },
  })
  return (
    <div>
      <Row className="d-flex justify-content-center ">
        <Col className="mt-2" md="10">
          <Card className="px-1 py-1" style={{ backgroundColor: '#FFFFFF' }}>
            <CardBody>
              <Row>
                <Col>
                  <Button
                    color='#1F59AA'
                    style={{ backgroundColor: " #1F59AA" }}
                    onClick={() => setModal(true)}
                    className="btn text-white"
                  >
                    E-OKUL ENTEGRASYONU
                  </Button>
                </Col>
              </Row>
              <Row className="my-2">
                <Col xs="12" sm="12" md="12" lg="6" xl="4">
                  <SelectOptions />
                </Col>
                <Col xs="12" sm="12" md="12" lg="6" xl="4">
                  <Guides />
                </Col>
                <Col xs="12" sm="12" md="12" lg="6" xl="4">
                  <SelectOptions />
                </Col>
              </Row>
              <Row>
                <Col
                  className=" text-center"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xxl="3"
                >
                  <Label>Günlük Devamsızlık Mesajı</Label>
                  <Input
                    value={formik.values.dailyAbsenteeism}
                    onChange={formik.handleChange}
                    style={{ height: '180px', fontSize: '11px' }}
                    type="textarea"
                  />
                </Col>
                <Col
                  className=" text-center"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xxl="3"
                >
                  <Label>Toplu Devamsızlık Mesajı</Label>
                  <Input
                    value={formik.values.massAbsenteeism}
                    onChange={formik.handleChange}
                    style={{ height: '180px', fontSize: '11px' }}
                    type="textarea"
                  />
                </Col>
                <Col
                  className=" text-center"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xxl="3"
                >
                  <Label>Mektup Devamsızlık Mesajı</Label>
                  <Input
                    value={formik.values.letterAbsenteeism}
                    onChange={formik.handleChange}
                    style={{ height: '180px', fontSize: '11px' }}
                    type="textarea"
                  />
                </Col>
                <Col
                  className=" text-center"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xxl="3"
                >
                  <Label>İki Gün Art Arda Devamsızlık Mesajı</Label>
                  <Input
                    value={formik.values.twoDaysAbsenteeism}
                    onChange={formik.handleChange}
                    style={{ height: '180px', fontSize: '11px' }}
                    type="textarea"
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-end my-1">
                <Col xs="12" sm="10" md="8" lg="6" xl="3">
                  <div className="d-flex justify-content-center mb-1">
                    <Input className="mx-1" type="checkbox" />
                    <div>1. Devamsızlık Tarihi Eklensin</div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Input className="mx-1" type="checkbox" />
                    <div>2. Devamsızlık Tarihi Eklensin</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Button
                    color="#1F59AA"
                    style={{ width: '100%', backgroundColor: '#1F59AA' }}
                    className="btn btn-block text-white"
                  >
                    Mesaj İçeriklerini Güncelle
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        className="modal-dialog-centered modal-lg py-2"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          style={{ borderBottom: '2px solid #3C4876' }}
          closeButton
          onClick={() => setModal(!modal)}
        >
          <div
            style={{ width: '760px' }}
            className="d-flex justify-content-between"
          >
            <div className="d-flex align-items-center">
              Önemli Bilgilendirme
            </div>
            <div
              style={{ backgroundColor: '#FFC100' }}
              className="btn text-white"
            >
              Kurulum Desteği İçin Yardım Al
            </div>
          </div>
        </ModalHeader>
        <ModalBody className=" px-1  ">
          <Row className="my-1">
            <h4>
              <b>
                E-Okul Entegrasyonu İçin Öncelikle İLKSMS E-OKUL EKLENTİSİ
                Kurulu Olması Gerekmektedir.
              </b>
            </h4>
          </Row>
          <Row
            className="px-1 py-1"
            style={{ borderBottom: '2px solid #3C4876' }}
          >
            <Col md="5">
              <img src="/image/ıntegration-image.jpg" alt="" />
            </Col>
            <Col className="d-flex align-items-center" md="7">
              <a
                style={{ textDecoration: 'underline' }}
                href="https://chromewebstore.google.com/detail/ilksms-e-okul-entegrasyon/bicbnjpfekgocaphbaeoblfiefeainep?hl=tr&utm_source=ext_sidebar"
              >
                {
                  "Chrome WEB Mağaza'dan İLKSMS uzantısını kurmak için tıklayın."
                }
              </a>
            </Col>
          </Row>
          <Row>
            <Col className='my-1'>
              <div className='mb-1'>
                Eklenti kurulu ise bundan sonra e-okul entegrasyon için
                aşağıdaki adımlarla işlem yapınız:
              </div>
              <ol>
                <li>İlksms hesabınıza giriş yapnız.</li>
                <li className='my-1'>Mebbis üzerinden E-Okul sayfanıza giriş yapınız.</li>
                <li>
                  İLKSMS e-OKUL EKLENTİSİ’ne tıklayınız. Ardından İlksms
                  sayfasına otomatik olarak yönlendirileceksiniz. Açılan
                  sayfadan Eokul aracını çalıştır butonuna basarak işlem
                  gerçekleştiriniz.
                </li>
              </ol>
            </Col>
            <Col className='d-flex justify-content-center mb-1' xs="12">
              <img src="/image/integration-image2.jpg" alt="" />
            </Col>
            <Col>
              <div className='text-center text-danger'>İLKSMS e-OKUL EKLENTİSİ’ni açık olan e-Okul sayfanızda çalıştırınız.</div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
