import Modal from 'react-bootstrap/esm/Modal'
import CustomTable from 'src/views/components/customTable/customTable'
const ReportCustomModal = (
  handleClose: any,
  show: any,
  data: any,
  columns: any
) => {
  return (
    <Modal
      size="xl"
      className="mt-2"
      style={{ height: 850 }}
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Rapor Detayları</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-secondary text-center mx-2 ">
        <CustomTable notPage={true} data={innerData} columns={innerColumns} />
        <CustomTable data={data} columns={columns} />
      </Modal.Body>
    </Modal>
  )
}
export default ReportCustomModal

const innerData = [
  {
    sent: '1',
    successMessages: '1',
    unsuccessMessages: '0',
    waitingMessages: '0 ',
    refundedMessages: '0 Kredi',
    lostCredits: '1 Kredi',
  },
]

const innerColumns = [
  {
    name: 'Gönderilen',
    sortable: true,
    selector: (row: { sent: string }) => row.sent,
  },
  {
    name: 'Başarılı Mesaj',
    sortable: true,
    selector: (row: { successMessages: string }) => row.successMessages,
  },
  {
    name: 'Başarısız Mesaj',
    sortable: true,
    selector: (row: { unsuccessMessages: string }) => row.unsuccessMessages,
  },
  {
    name: 'Bekleyen Mesaj',
    sortable: true,
    selector: (row: { waitingMessages: string }) => row.waitingMessages,
  },
  {
    name: 'İade Mesaj Toplamı',
    sortable: true,
    selector: (row: { refundedMessages: string }) => row.refundedMessages,
  },
  {
    name: 'Düşen Kredi',
    sortable: true,
    selector: (row: { lostCredits: string }) => row.lostCredits,
  },
]
