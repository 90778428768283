import React from 'react'
import { Col, Input, Row } from 'reactstrap'
import CustomTable from 'src/views/components/customTable/customTable'

const index = () => {
  const columns = [
    {
      name: 'Tarih',
      sortable: true,
      selector: (row: { date: any }) => row.date,
    },
    {
      name: 'İşlem',
      sortable: true,
      selector: (row: { operation: any }) => row.operation,
    },
    {
      name: 'Tutar',
      sortable: true,
      selector: (row: { amount: any }) => row.amount,
    },
    {
      name: 'Yüklenen Kredi',
      sortable: true,
      selector: (row: { loadedCredit: any }) => row.loadedCredit,
    },
    {
      name: 'İşlem Öncesi kredi',
      sortable: true,
      selector: (row: { PreTransactionCredit: any }) =>
        row.PreTransactionCredit,
    },
    {
      name: 'İşlem Sonrası Kredi',
      sortable: true,
      selector: (row: { PostTransactionCredit: any }) =>
        row.PostTransactionCredit,
    },
    {
      name: 'İşlem Açıklaması',
      sortable: true,
      selector: (row: { transactionDescription: any }) =>
        row.transactionDescription,
    },
    {
      name: 'Alındı Belgesi',
      sortable: true,
      selector: (row: { certificateOfReceipt: any }) =>
        row.certificateOfReceipt,
    },
  ]
  const document = [
    {
      date: '12/10/2024',
      operation: 'Kredi Düzenleme',
      amount: '159',
      loadedCredit: '1000',
      PreTransactionCredit: '1500',
      PostTransactionCredit: '2500',
      transactionDescription: '',
      certificateOfReceipt: '',
    },
    {
      date: '12/10/2024',
      operation: 'Kredi Düzenleme',
      amount: '159',
      loadedCredit: '1000',
      PreTransactionCredit: '1500',
      PostTransactionCredit: '2500',
      transactionDescription: '',
      certificateOfReceipt: '',
    },
    {
      date: '12/10/2024',
      operation: 'Kredi Düzenleme',
      amount: '159',
      loadedCredit: '1000',
      PreTransactionCredit: '1500',
      PostTransactionCredit: '2500',
      transactionDescription: '',
      certificateOfReceipt: '',
    },
    {
      date: '12/10/2024',
      operation: 'Kredi Düzenleme',
      amount: '159',
      loadedCredit: '1000',
      PreTransactionCredit: '1500',
      PostTransactionCredit: '2500',
      transactionDescription: '',
      certificateOfReceipt: '',
    },
  ]
  const data = document.map((item: any) => {
    return {
      date: item.date,
      operation: item.operation,
      amount: item.amount,
      loadedCredit: item.loadedCredit,
      PreTransactionCredit: item.PreTransactionCredit,
      PostTransactionCredit: item.PostTransactionCredit,
      transactionDescription: item.transactionDescription,
      certificateOfReceipt: item.certificateOfReceipt,
    }
  })
  return (
    <div>
      <Row>
        <Col>
        <div className='d-flex justify-content-center align-items-center'>
            <h3 className='text-danger'>
            Alındı Belgesi, Sadece Ödemesi Yapılan Satışlar Üzerinden Alınabilmektedir.
            </h3>
        </div>
          <CustomTable data={data} columns={columns}>
            <Input placeholder="Ara"></Input>
          </CustomTable>
        </Col>
      </Row>
    </div>
  )
}

export default index
