import React, { useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import Accordion from 'react-bootstrap/Accordion'
import FieldTwo from './fieldTwo'
import { Scrollbar } from 'react-scrollbars-custom'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import CustomTable from 'src/views/components/customTable/customTable'
import { useTranslation } from 'react-i18next'

const index = () => {
  const [childrens, setChildrens] = useState<any>([
    {
      schoolNumber: '10',
      nameSurname: 'Muhammetcan Akcura',
      late: false,
      notCome: false,
    },
    {
      schoolNumber: '11',
      nameSurname: 'Muhammetcan Akcura',
      late: false,
      notCome: false,
    },
    {
      schoolNumber: '12',
      nameSurname: 'Muhammetcan Akcura',
      late: false,
      notCome: false,
    },
    {
      schoolNumber: '13',
      nameSurname: 'Muhammetcan Akcura',
      late: false,
      notCome: false,
    },
    {
      schoolNumber: '14',
      nameSurname: 'Muhammetcan Akcura',
      late: false,
      notCome: false,
    },
  ])
  const [modal, setModal] = useState(false)
  const { t }: any = useTranslation()
  const [childrensModal, setChildrensModal] = useState(false)
  const columns = [
    {
      name: 'tarih',
      sortable: true,
      selector: (row: { date: string }) => row.date,
    },
    {
      name: 'Durum',
      sortable: true,
      selector: (row: { status: string }) => row.status,
    },
    {
      name: 'işlemler',
      sortable: true,
      selector: (row: { operations: string }) => row.operations,
    },
  ]
  const datax1 = [
    { date: '12-08-2024', status: 0 },
    { date: '13-08-2024', status: 1 },
    { date: '14-08-2024', status: 2 },
    { date: '15-08-2024', status: 1 },
    { date: '16-08-2024', status: 2 },
    { date: '16-08-2024', status: 0 },
    { date: '16-08-2024', status: 1 },
    { date: '16-08-2024', status: 3 },
    { date: '16-08-2024', status: 0 },
  ]
  const data = datax1.map((item: any) => {
    return {
      date: item.date,
      status:
        item.status === 0 ? (
          <>
            <div
              style={{ width: '230px' }}
              className="text-white rounded text-center bg-success py-1 "
            >
              Mesaj Gönderildi
            </div>
          </>
        ) : item.status === 1 ? (
          <>
            <div
              style={{ width: '230px' }}
              className="text-white rounded text-center bg-warning px-1 py-1 "
            >
              Gönderilemeyen Mesajlar Mevcut
            </div>
          </>
        ) : (
          <>
            <div
              style={{ width: '230px' }}
              className="text-white rounded text-center bg-danger  py-1"
            >
              Mesaj Gönderilemedi
            </div>
          </>
        ),
      operations: (
        <>
          <div
            onClick={() => setModal(true)}
            style={{ backgroundColor: '#1F59AA' }}
            className="btn text-white"
          >
            Yoklamayı Görüntüle
          </div>
        </>
      ),
    }
  })
  const datax = [
    {
      name: '2.Saat Muhammetcan Akcura-(Matematik Dersi) - (09 Kasım 2023)',
    },
    {
      name: '2.Saat Muhammetcan Akcura-(Matematik Dersi) - (09 Kasım 2023)',
    },
    {
      name: '2.Saat Muhammetcan Akcura-(Matematik Dersi) - (09 Kasım 2023)',
    },
    {
      name: '2.Saat Muhammetcan Akcura-(Matematik Dersi) - (09 Kasım 2023)',
    },
    {
      name: '2.Saat Muhammetcan Akcura-(Matematik Dersi) - (09 Kasım 2023)',
    },
    {
      name: '2.Saat Muhammetcan Akcura-(Matematik Dersi) - (09 Kasım 2023)',
    },
    {
      name: '2.Saat Muhammetcan Akcura-(Matematik Dersi) - (09 Kasım 2023)',
    },
    {
      name: '2.Saat Muhammetcan Akcura-(Matematik Dersi) - (09 Kasım 2023)',
    },
  ]
  const columns1 = [
    {
      name: t('Okul No'),
      sortable: true,
      selector: (row: { schoolNumber: string }) => row.schoolNumber,
    },
    {
      name: t('Ad Soyad'),
      sortable: true,
      selector: (row: { nameSurname: string }) => row.nameSurname,
    },
    {
      late: (
        <>
          <Input type="checkbox" />
        </>
      ),
      selector: (row: { late: any }) => row.late,
    },
    {
      notCome: (
        <>
          <Input type="checkbox" />
        </>
      ),
      selector: (row: { notCome: any }) => row.notCome,
    },
  ]
  const selected = (e: string, index: number) => {
    const child = [...childrens]
    const updateItem = { ...child[index], [e]: !child[index][e] }
    if (updateItem[e]) {
      Object.keys(updateItem).forEach((key) => {
        if (typeof updateItem[key] === 'boolean') {
          if (key !== e) {
            updateItem[key] = false
          }
        }
      })
    }
    child[index] = updateItem
    setChildrens(child)
  }

  const child = childrens.map((item: any, index: number) => {
    return {
      schoolNumber: item.schoolNumber,
      nameSurname: item.nameSurname,
      late: (
        <>
          <Input
            className="mx-1"
            type="checkbox"
            onChange={() => selected('late', index)}
            checked={item.late}
          />
          <span>Geç</span>
        </>
      ),
      notCome: (
        <>
          <Input
            className="mx-1"
            type="checkbox"
            onChange={() => selected('notCome', index)}
            checked={item.notCome}
          />
          <span>Gelmedi</span>
        </>
      ),
    }
  })
  return (
    <div>
      <Row className="d-flex justify-content-center">
        <Col md="7" style={{ borderRight: '2px solid #ccc' }}>
          <CustomTable notPage columns={columns} data={data} />
        </Col>
        <Col md="5">
          <FieldTwo />
        </Col>
      </Row>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          style={{ backgroundColor: '#1F59AA' }}
          className=" d-flex justify-content-center align-items-center text-center"
          closeButton
          toggle={() => {
            setModal(!modal)
          }}
        >
          <div className="text-white">
            Mesajınız Aşağıdaki Kayıtlara Gönderilecektir Listeleniyor
          </div>
        </ModalHeader>
        <ModalBody>
          <Col>
            <Col className="b" md="12">
              {/* <Scrollbar
                className="mt-1 d-flex justify-content-center"
                style={{ width: '100%', height: 520 }}
              >
                {datax.map((item, index) => (
                  <div
                    onClick={() => {
                      setChildrensModal(true)
                    }}
                    key={index}
                    style={{
                      width: '100%',
                      paddingTop: '9px',
                      paddingBottom: '9px',
                      cursor: 'pointer',
                    }}
                    className="my-1 d-flex justify-content-between border border-2 rounded-5"
                  >
                    <div style={{ width: '90%' }} className="mx-5">
                      <span style={{ fontSize: '20px' }}>{item.name}</span>
                    </div>
                    <div style={{ fontSize: '20px' }} className="mx-5">
                      0
                    </div>
                  </div>
                ))}
              </Scrollbar> */}
              <Accordion flush>
                {datax.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{item.name}</Accordion.Header>
                    <Accordion.Body>
                      <CustomTable notPage data={child} columns={columns1} />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
            <Col
              md="11"
              className=" mt-2 d-flex justify-content-center align-items-center"
            >
              <button
                style={{ backgroundColor: '#1F59AA', width: '30%' }}
                className="btn text-white py-1"
              >
                Sonraki Aşama
              </button>
            </Col>
          </Col>
          {/* <CustomTable notPage data={child} columns={columns1} /> */}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
