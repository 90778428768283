import { useState } from 'react'
import Accordion from 'react-bootstrap/esm/Accordion'
import Button from 'react-bootstrap/esm/Button'
import Form from 'react-bootstrap/esm/Form'
import Modal from 'react-bootstrap/esm/Modal'
import { MdCall } from 'react-icons/md'

const Support = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const data = getData()

  let i = 0

  return (
    <div className="p-2 rounded ms-2">
      <div className="d-flex mb-2 text-center align-items-center justify-content-between">
        <div style={{ width: '33%' }}>
          <Button
            onClick={handleShow}
            className="d-flex align-items-center me-5"
            variant="success"
          >
            <h5 className="mb-0 me-1 text-white">
              Canlı Destek Talebinde Bulun
            </h5>
            <MdCall size={15} />
          </Button>
        </div>
        <h5 className="mb-0">
          Sistem kullanımında karşılaşabileceğiniz durumlarla ilgili çözüm
          önerilerimizi inceleyiniz.
        </h5>
        <div style={{ width: '33%' }} />
      </div>
      {data.map((item) => (
        <Accordion className="shadow mb-1 rounded" flush>
          <Accordion.Item eventKey={`${i++}`}>
            <Accordion.Header>{item.header}</Accordion.Header>
            <Accordion.Body>
              <p>{item.description}</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
      {CustomModal(handleClose, show)}
    </div>
  )
}
function getData() {
  return [
    {
      header:
        'Numara doğru ama mesaj hala beklemede/ulaştırılamadı diyor, neden ?',
      description:
        '- Mesaj gönderdiğiniz numaraya ait telefon kapalı veya çekmiyor ise ”Beklemede” olarak görünür. 24 saat boyunca mesaj ulaştırılamazsa “Ulaştırılamadı” uyarısı alırsınız. Ayrıca numara servis dışı kalmış veya yanlış ise yine “Ulaştırılamadı” uyarısı alırsınız.',
    },
    {
      header:
        'Mesajım iletildi olarak görünüyor ama veli mesaj gelmediğini söylüyor',
      description:
        '- Sistemdeki iletildi raporu operatörden alınmaktadır. İletildi raporu var ama veli mesaj gelmedi diyorsa; -Telefonda kullandığı bir uygulamayla mesajları engellemiş olabilir. - Aynı koda (B050) sahip başka bir firmadan gelen mesajları engellemişse yine mesajlar ulaşmaz. Bu nedenle iletildi görünecektir, Operatör tarafında iletilmiştir fakat mesaj sadece veliye görünmez bu nedenle “iletildi” olarak görünecektir. -Operatörleri vasıtasıyla reklam/tanıtım mesajlarını engellemiş olabilirler. Arayıp engellemeyi kaldırabilirler.',
    },
    {
      header:
        'Rehberimde "Mezun Grubu" var ve onlara da mesaj gidiyor. / "Öğretmen Grubu" oluşturdum ama öğretmenlere mesaj gönderirken öğrencilere de mesaj gidiyor. Neden ?',
      description:
        '- Bu durum yeni öğrencilerle mezun ve öğretmen gruplarının numara çakışmasından kaynaklanmaktadır. Rehberinizde Mezun veya Öğretmen Grubu oluşturacaksanız öğrenci numarasını sıfır “0” olarak kaydetmelisiniz. Bir diğer çözüm ise Öğretmen ve Mezun Grupları için ayrı bir rehber türü oluşturmanızdır. Ayarlar-Rehber Türleri – Yeni Rehber Türü Kaydı’ndan yeni bir rehber türü oluşturarak, Rehberim-Sınıf/Grup İşlemleri‘nden rehber türünü güncellemeniz gerekir. Mesaj gönderilirken rehber türünü seçilip gönderim yapılırsa artık numaralar çakışmayacaktır.',
    },
    {
      header: 'Gönderici adını nasıl değiştirebilirim, kuralları nedir ?',
      description:
        '- Panelizden Ayarlar-Gönderici Adı – Yeni Gönderici Adı Kaydı bölümünden talepte bulunabilirsiniz. -Gönderici adınız BTK yönetmeliğine göre maksimum 11 karakterden oluşabilir. Ayrıca okul türünüzü belirten IO,OO,AL,FL,AIHL gibi ibareler bulundurmak zorundadır. -Gönderici adında Türkçe karakter kullanılamaz.',
    },
    {
      header:
        'Mesajlarımın her iki veliye de gitmesini istiyorum. Nasıl Yapabilirim ?',
      description: ` Panelden SMS gönderiyorsanız mesaj yazma ekranından Veli Telefonu seçim ekranından “1.Veli ve 2.Veli” seçilebilir.Eğer entegrasyondan mesaj gönderiliyorsa panelinizde sol menüde bulunan “E-Okul Programı(Yeni)” yazan bölüme tıklayıp Veli Seçimi ayarından “1.Veli ve 2.Veli” seçilebilir.`,
    },
    {
      header:
        'Kurumumuzda iki ayrı okul var, E-okul entegrasyon ile ikisini de nasıl çekeceğim ?',
      description: `- Bir okul türünü aktardıktan sonra, entegrasyona tekrar giriş yapıp diğer okul türünü de aktarabilirsiniz.`,
    },
    {
      header:
        'Günlük Devamsızlıklarda önceki günlük devamsızlıkları da gönderebiliyor muyuz ?',
      description:
        '- Entegrasyon ile geriye dönük günlük devamsızlıkları gönderebilirsiniz. Entegrasyona giriş yaptığınızda Günlük Devamsızlıkları Al bölümünde tarihi seçip , “Günlük Devamsızlıkları Al” butonuna tıklamanız yeterlidir.',
    },
    {
      header: 'Seçtiğim kişi sayısından daha az kişiye mesaj gidiyor, neden?',
      description:
        '- Mesaj gönderme ekranında mükerrer kayıtları temizle seçeneği işaretliyse, aynı veli numarasına sahip öğrencilere bir kere mesaj gider. Mükerrer kayıtları temizle özelliği ile aynı mesajın aynı veliye birden fazla gönderilmesi amaçlanmıştır. Ayrıca sadece 1.Veli yada sadece 2.Veli ye gönderiyorsanız, bazı öğrencilerin 1.veli veya 2.veli bilgileri kayıtlı olmayabilir ve mesaj gitmez.',
    },
    {
      header:
        'Entegrasyon ile devamsızlık mektubu gönderdim ama 2-3 öğrenciye devamsızlık mektubu gönderilmedi. Neden ?',
      description:
        '- Bu öğrencilerin rehberinizde kayıtlı veli bilgisi yoksa mesaj gönderilmeyebilir. Ya da daha öncesinde devamsızlık mektubu bu öğrenciye gönderilmişse E-Okulda “Gönderildi” kutusu işaretlenmiş olabilir ve bu öğrencilere tekrar mesaj gönderilmez.',
    },
    {
      header: 'Öğrencinin velisi SMS almak istemiyor, ne yapabilirim ?',
      description:
        '- Ayarlar- Kara Liste ‘ye girip velinin numarasını kara listeye eklediğinizde bir daha o veliye mesaj gitmeyecektir.        ',
    },
    {
      header: 'Öğrenci listemi güncellemek istiyorum, nasıl yapabilirim ?',
      description:
        '- Entegrasyona giriş yapıp , “Öğrenci ve Veli Bilgilerini Al” butonuna tıkladığınızda rehberinizde eksik bilgi varsa eksikler tamamlanacaktır ve rehberinizde olmayan kişiler varsa eklenecektir. Rehberiniz böylece güncellenmiş olacaktır.',
    },
    {
      header: 'E-okuldan aldığım sınav sonuçlarını gönderemiyorum ?',
      description:
        '- E-Okul’dan indirdiğiniz Excel formatındaki raporun kodunu doğruluğunu kontrol edin. Eokuldan aldığınız dosya tek bir derse ait olmalı. Bütün derslerin sınav sonucunu tek bir mesajda göndermek istiyorsanız; ilk sütununda öğrencilerin okul numaraları ve diğer sütunlarda sınav sonuçları olacak şekilde bir Excel dosyası oluşturup, SMS Gönder –Excel Özel Tasarım alanından gönderebilirsiniz.',
    },
    {
      header: 'Excel ile öğrenci kaydında sorun yaşıyorum.',
      description:
        '-Sayfada dosya yükleme kutusu bulunur. Kutunun içine hazırladığınız Excel dosyanızı sürükleyip bırakın veya kutunun içine tıklayarak yüklemek istediğiniz dosyalarınızı seçin. Ardından altta yer alan REHBERE KAYDET butonuna basın. İşlemin ardından kaç adet kayıt yüklendiği bilgisi yer alacak. Yüklenen kayıtları görmek için Rehberim-Sınıf/Grup İşlemleri menüsünden gruplarınızı ve içindeki kayıtları inceleyebilirsiniz. -Excel dosyanızın örnek dosyaya uygun olduğunu kontrol ediniz. Özellikle sayfanın en üstünde fazladan satır olmaması gerekir. Sayfada yer alana açıklamalara ve Örnek Excel kayıt dosyamıza uygun formatta olması gerekir.',
    },
    {
      header: 'Excel özel tasarım ile mesaj gönderemiyorum.',
      //TODO: FIX HERE !!!
      description: `
        - Excel Dosyamı Hazırlayamadım
        Sunduğumuz mesaj gönderim seçeneklerinden farklı olarak dinamik (değişken değerli) mesajlar göndermek için bu alanı kullanabilirsiniz. Standart şablonlarımızla uyuşmayan Okul deneme sonuçlarını, başarı tablolarını, öğrenciye özel mesajlarınızı toplu olarak bu alandan yollayabilirsiniz. Excel tablonuzun 1. satırında başlık satırında sütün başlıkları yazmalıdır, boş bırakılmamalı. Excel dosyanızın ilk sütununda okul numarası veya telefon numarası olmalıdır (bu verilere göre sistem işlem yapar). En fazla 25 sütunluk Excel dosyası kabul etmektedir (Excel dosyanızın ilk 15 sütunu bu alanda görünür, diğer alanları mesaj yazma alanında görebilirsiniz).
        
        - Excel dosyamı kopyaladım fakat hala sorun yaşıyorum
        Excel dosyanızda yer alan verileri fare ile seçerek kopyalayın ve Excel Özel Tasarım mesaj gönderme sayfasında gösterilen tablonun birinci hücresine tıklayıp yapıştırın. Kopyaladığını verilere göre tablo oluştuğunu göreceksiniz. Ardından altta yer alan BİR SONRAKİ AŞAMA butonuna basın.
        
        - Dosyamı yükledim ama mesaj yazamıyorum
        Mesaj yazma alanında dinamik mesaj yazabilirsiniz. Kırmızı renkli kutular dosyanızdaki sütün başlıklarını, mavi kutular ise rehberde kayıtlı öğrenci bilgilerini ifade eder. Mesajınızı yazarken yüklediğiniz verilerden hangilerinin yer almasını istiyorsanız ilgili kutuya tıklayın. MESAJI ÖNİZLE butonuna basarak gidecek her bir mesajı içeriği karşınıza çıkacaktır. MESAJI GÖNDER butonu ile mesajınızı yollayın. Yazdığınız mesajda düzenleme yapmak istiyorsanız tarayıcıdan geri işaretine basarak mesajınızı kaldığınız yerden düzenleyebilirsiniz.
        `,
    },
    {
      header: 'Kredilerimi hiç harcamadım ama kredilerim eksik görünüyor.',
      description:
        '- Eğer ödemeniz henüz yapılmamışsa (Anasayfanızda üstte borç yazısı bulunur) aldığınız SMS paketinin %10’u önden hesabınıza yüklenir. Ödemeden sonra tamamı yüklenecektir. Ödemeniz yapıldığı halde kredileriniz eksik görünüyorsa, Ayarlar-Alt Kullanıcı alanından alt kullanıcılara tanımlanan kredi miktarını kontrol edebilirsiniz. Alt kullanıcılara tanımladığınız krediler Ana kullanıcıdan düşer.        ',
    },
    {
      header: 'Sınıf atlatma / Mezun sınıfı oluşturma',
      description:
        '- Sınıf atlatma işlemi için Rehberim/Sınıf grup işlemleri menüsünde yer alan sınıf listenizde görünen sınıf isimlerinin değiştirilip GÜNCELLE butonuna basılması yeterlidir. Örneğin 9/A grubun adını 10/A olarak değiştirip, ardından GÜNCELLE butonuna basmanız yeterlidir. Yukarıdaki açıklamalara göre işlem yaptım fakat hala sorun yaşıyorum.        ',
    },
    {
      header:
        "179TL'lik paket almak istiyorum ama ödeme ekranında 223TL çıkıyor. Neden?",
      description:
        '-Tüm paketlerde Kredi kartı ile alımda komisyon uygulanır. Komisyon ödememek için EFT ve Havale ile ödeme yapınız.        ',
    },
  ]
}

export default Support

const CustomModal = (handleClose: any, show: any) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header
        className="text-center"
        style={{ fontSize: 25 }}
        closeButton
      >
        Canlı Destek
      </Modal.Header>
      <Modal.Body className="text-secondary text-center">
        <div className="px-2 pb-1">
          <Form>
            <Form.Group className="mb-1">
              <Form.Label>E-Posta adresiniz</Form.Label>
              <Form.Control type="email" placeholder="E-Posta" />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Destek Almak İstedğiniz Konu</Form.Label>
              <Form.Select>
                <option>Konu 1</option>
                <option>Konu 2</option>
                <option>Konu 3</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>
                Destek Almak İstediğiniz Konunun Açıklaması
              </Form.Label>
              <Form.Control type="text" placeholder="Açıklamanız" />
            </Form.Group>
            <Button className="w-100" variant="outline-success" type="submit">
              Gönder
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
