import { Dispatch, SetStateAction, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Form from 'react-bootstrap/esm/Form'
import CustomModal from 'src/views/components/customModal/CustomModal'

interface Props {
  modal: boolean
  setModal: Dispatch<SetStateAction<boolean>>
}

const SecurityModal = (props: Props) => {
  return (
    <CustomModal modal={props.modal} setModal={props.setModal}>
      <div className="px-3 py-2">
        <h1 className="mt-1 text-center text-black">Güvenlik Ayarları</h1>
        <Form className="d-flex p-2">
          <div className="d-flex flex-column w-100 me-1">
            <Form.Group className="mb-1">
              <Form.Label>Kullanıcı Adı</Form.Label>
              <Form.Control value="kullanıcı adı" disabled />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Şifre</Form.Label>
              <Form.Control type="password" placeholder="Şifre" />
            </Form.Group>
          </div>
          <div className="d-flex flex-column w-100">
            <Form.Group className="mb-1">
              <Form.Label>Sabit IP Ayarı</Form.Label>
              <Form.Control value="Pasif" disabled />
            </Form.Group>
            <Form.Group className="d-flex flex-column mb-1">
              <Form.Label>API Bilgileri</Form.Label>
              <Button>API Bilgilerimi Göster</Button>
            </Form.Group>
          </div>
        </Form>
        <Button className="m-1 w-100" variant="primary">
          Bilgilerimi Güncelle
        </Button>
      </div>
    </CustomModal>
  )
}

export default SecurityModal
