import React, { useState } from 'react'
import { MdOutlineTextsms } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'

const index = () => {
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()
  const [packages, setPackages] = useState({
    price: 0,
    quantity: 0,
    name:""
  })
  const dataPackages = [
    { quantity: '500', name:"Mini Paket", price: '59' },
    { quantity: '1000', name:"Midi Paket", price: '99' },
    { quantity: '2500', name:"Eko Paket", price: '179' },
    { quantity: '5000', name:"Super Paket", price: '279' },
    { quantity: '10000',name:"Pro Paket", price: '359' },
    { quantity: '20000', name:"Plus Paket", price: '669' },
  ]
  return (
    <div>
      <Row className="mt-1">
        {dataPackages.map((item: any, index: any) => (
          <Col md="4" key={index}>
            <Card className="">
              <CardHeader className="d-flex flex-column justify-content-center">
                <MdOutlineTextsms color="#25D366" fontSize={'80px'} />
              </CardHeader>
              <CardBody className="d-flex flex-column justify-content-center">
                <CardText style={{ fontSize: '25px' }} className="text-center">
                  {item.quantity} Sms
                </CardText>
                {item.price === '' ? (
                  ''
                ) : (
                  <CardText
                    style={{ fontSize: '25px' }}
                    className="text-center"
                  >
                    {item.price} + KDV
                  </CardText>
                )}
              </CardBody>

              <div
                style={{ width: '100%' }}
                className=" d-flex flex-column justify-content-center align-items-center"
              >
                <div
                  onClick={() => {
                    setPackages({
                      quantity: item?.quantity,
                      price: item?.price,
                      name:item?.name,
                    })
                    setModal(true)
                  }}
                  style={{ width: '60%', backgroundColor: '#1F59AA' }}
                  className="btn rounded-5 text-white mb-2"
                >
                  Satın Al
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        className="modal-dialog-centered modal-md py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        ></ModalHeader>
        <ModalBody className="text-start px-1 d-flex flex-column justify-content-center align-items-center   ">
          <h3 className="txet-center my-1">
            {' '}
            {packages.quantity} - {packages.name}  {packages.price} Tl Karşılığında{' '}
          </h3>
          <h3 className="text-center my-1">
            Satın almayı onaylıyor musunuz?
          </h3>
          <div className="d-flex justify-content-around align-items-center my-1">
            <div className="btn btn-danger mx-1 my-1">Kredi Kartı</div>
            <div onClick={()=>{
                navigate("/remmitance")
            }} className="btn btn-success mx-1 my-1">Havale İle</div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
