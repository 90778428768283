import React, { useState } from 'react'
import { GiCheckMark } from 'react-icons/gi'
import {
  Button,
  
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import {
  FcBusinessman,
  FcExpand,
} from 'react-icons/fc'
import { Scrollbar } from 'react-scrollbars-custom'

import CustomTable from 'src/views/components/customTable/customTable'
import { useTranslation } from 'react-i18next'
const Index = (props: any) => {
  const { t }: any = useTranslation()
  const [modal, setModal] = useState(false)
  const [childrensModal, setChildrensModal] = useState(false)
  const [teachersModal, setTeachersModal] = useState(false)
  const [isStudentClicked, setIsStudentClicked] = useState(true)
  const [isTeacherClicked, setIsTeacherClicked] = useState(false)
  const [checkboxValues, setCheckboxValues] = useState<{
    [key: string]: boolean
  }>({})
  const [checkboxValuesModal, setCheckboxValuesModal] = useState<{
    [key: string]: boolean
  }>({})
  const [selectAllClasses, setSelectAllClasses] = useState(false)
  const [selectAllTeachers, setSelectAllTeachers] = useState(false)

  const handleCheckboxChange = (itemName: any) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [itemName]: !prevValues[itemName],
    }))
  }
  const handleCheckboxModal = (index: any) => {
    setCheckboxValuesModal((prevValues) => ({
      ...prevValues,
      [index]: !prevValues[index],
    }))
  }

  const handleClickStudent = () => {
    setIsStudentClicked(true)
    setIsTeacherClicked(false)
    setSelectAllClasses(false)
  }

  const handleClickTeacher = () => {
    setIsStudentClicked(false)
    setIsTeacherClicked(true)
    setSelectAllTeachers(false)
    setSelectAllClasses(false)
    setCheckboxValues({})
  }

  const handleSelectAllClasses = () => {
    setSelectAllClasses(!selectAllClasses)
    setCheckboxValues((prevValues) => {
      const updatedValues: { [key: string]: boolean } = {}
      data.forEach((item) => {
        updatedValues[item.name] = !selectAllClasses
      })
      return {
        ...prevValues,
        ...updatedValues,
      }
    })
  }

  const handleSelectAllTeachers = () => {
    setSelectAllTeachers(!selectAllTeachers)
    setCheckboxValues((prevValues) => {
      const updatedValues: { [key: string]: boolean } = {}
      datax.forEach((item) => {
        updatedValues[item.name] = !selectAllTeachers
      })
      return {
        ...prevValues,
        ...updatedValues,
      }
    })
  }

  const data = [
    {
      name: '8/A Sınıfı',
    },
    {
      name: '8/B Sınıfı',
    },
    {
      name: '8/C Sınıfı',
    },
    {
      name: '8/D Sınıfı',
    },
    {
      name: '8/E Sınıfı',
    },
    {
      name: '8/F Sınıfı',
    },
    {
      name: '8/G Sınıfı',
    },
    {
      name: '8/H Sınıfı',
    },
  ]
  const datax = [
    {
      name: 'Türkçe Öğretmeni',
    },
    {
      name: 'İngilizce Öğretmeni',
    },
    {
      name: 'Biyoloji Öğretmeni',
    },
    {
      name: 'Fizik Öğretmeni',
    },
    {
      name: 'Tarih Öğretmeni',
    },
    {
      name: 'Matematik Öğretmeni',
    },
    {
      name: 'Resim Öğretmeni',
    },
    {
      name: 'Müzik Öğretmeni',
    },
  ]
  const columns = [
    {
      name: (
        <>
          <Input type="checkbox" checked={true} title={t('Hepsini Seç')} />
        </>
      ),
      selector: (row: { is_selected: any }) => row.is_selected,
      width: '60px',
    },
    {
      name: t('Okul No'),
      sortable: true,
      selector: (row: { schoolNumber: string }) => row.schoolNumber,
    },
    {
      name: t('Ad Soyad'),
      sortable: true,
      selector: (row: { nameSurname: string }) => row.nameSurname,
    },
    {
      name: (
        <>
          <div className="">
            <span>1.veli Tel</span>
            {props.isClicked1 ? (
              <GiCheckMark
                color="#96D56C"
                size={'25px'}
                style={{
                  position: 'absolute',
                  bottom: '15px',
                  fontSize: '35px',
                }}
              />
            ) : (
              ''
            )}
          </div>
        </>
      ),
      sortable: true,
      selector: (row: { phones: string }) => row.phones,
    },
    {
      name: (
        <>
          <div className="">
            <span>2.veli Tel</span>
            {props.isClicked2 ? (
              <GiCheckMark
                color="#96D56C"
                size={'25px'}
                style={{
                  position: 'absolute',
                  bottom: '15px',
                  fontSize: '35px',
                }}
              />
            ) : (
              ''
            )}
          </div>
        </>
      ),
      sortable: true,
      selector: (row: { phones: string }) => row.phones,
    },
    {
      name: (
        <>
          <div className="">
            <span>Öğrenci</span>
            {props.isClicked3 ? (
              <GiCheckMark
                color="#96D56C"
                size={'25px'}
                style={{
                  position: 'absolute',
                  bottom: '15px',
                  fontSize: '35px',
                }}
              />
            ) : (
              ''
            )}
          </div>
        </>
      ),
      sortable: true,
      selector: (row: { phones: string }) => row.phones,
    },
  ]
  const columnsTeacher = [
    {
      name: (
        <>
          <Input type="checkbox" checked={true} title={t('Hepsini Seç')} />
        </>
      ),
      selector: (row: { is_selected: any }) => row.is_selected,
      width: '60px',
    },
    {
      name: t('Adı Soyadı'),
      sortable: true,
      selector: (row: { name: string }) => row.name,
    },
    {
      name: t('Telefon Numarası'),
      sortable: true,
      selector: (row: { phone: string }) => row.phone,
    },
  ]
  const TeacherData = [
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
    { name: 'Can Ataman', phone: '5306556724' },
  ]
  const teacher = TeacherData.map((item: any) => {
    return {
      is_selected: (
        <>
          <div>
            <Input type="checkbox" checked={true} />
          </div>
        </>
      ),
      name: item.name,
      phone: item.phone,
    }
  })
  const childrens = [
    {
      schoolNumber: '123',
      nameSurname: 'Can Aslan',
      phones: '5306556720',
    },
    {
      schoolNumber: '1234',
      nameSurname: 'Emir Aslan',
      phones: '5306556721',
    },
    {
      schoolNumber: '1235',
      nameSurname: 'Müge Aslan',
      phones: '5306556722',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },

    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
    {
      schoolNumber: '1236',
      nameSurname: 'Aslı Aslan',
      phones: '5306556723',
    },
  ]

  const students = childrens.map((item, index) => {
    return {
      is_selected: (
        <>
          <div>
            <Input
              type="checkbox"
              checked={checkboxValuesModal[index] || true}
              onChange={() => handleCheckboxModal(index)}
            />
          </div>
        </>
      ),
      schoolNumber: item.schoolNumber,
      nameSurname: item.nameSurname,
      phones: item.phones,
    }
  })

  if (typeof props.dataChange === 'function') {
    const data = isTeacherClicked ? true : false
    props.dataChange(data)
  }

  return (
    <>
      <Row
        style={{ paddingRight: '15px' }}
        className="d-flex justify-content-center "
      >
        <Col
          style={{ width: '100%' }}
          xs={12}
          className="d-flex "
        >
          <div
            onClick={handleClickStudent}
            className='handleClickStudent'
            style={{
              backgroundColor: isStudentClicked ? '#7367F0' : 'transparent',
              color: isStudentClicked ? 'white' : '#7367F0',
            }}
          >
            {isStudentClicked && (
              <GiCheckMark
                color="#96D56C"
                size={'35px'}
                className='checkMark'
              />
            )}
            {isStudentClicked ? (
              <div
              className="teacherClick"
              >
                <input
                 className='customCheckbox'
                  type="checkbox"
                  checked={selectAllClasses}
                  onChange={handleSelectAllClasses}
                />
              </div>
            ) : (
              ''
            )}
            <FcBusinessman style={{ fontSize: '20px' }} />
            <span className="">Öğrenci</span>
          </div>
          <div
            onClick={handleClickTeacher}
            style={{
              backgroundColor: isTeacherClicked ? '#7367F0' : 'transparent',
              color: isTeacherClicked ? 'white' : '#7367F0',
              left: "5px"
            }}
            className="py-1 handleClickStudent" 
          >
            {isTeacherClicked && (
              <GiCheckMark
                color="#96D56C"
                size={'35px'}
                className='checkMark'
              />
            )}
            {isTeacherClicked ? (
              <div
                className="teacherClick"
              >
                <input
                  className='customCheckbox'
                  type="checkbox"
                  checked={selectAllTeachers}
                  onChange={handleSelectAllTeachers}
                />
              </div>
            ) : (
              ''
            )}
            <FcBusinessman style={{ fontSize: '20px' }} />
            <span className="ml-2">Öğretmen / Personel</span>
          </div>
        </Col>
        <Col md="12" className="">
          <Scrollbar className="mt-1" style={{ width: '100%', height: 380 }}>
            {isStudentClicked &&
              data.map((item, index) => (
                <div
                  key={index}
                  className="my-1 d-flex justify-content-between customFieldOne border border-2 rounded  "
                >
                  <input
                    style={{ transform: 'scale(1.5)', }}
                    className="mx-1"
                    type="checkbox"
                    checked={checkboxValues[item.name] || false}
                    onChange={() => handleCheckboxChange(item.name)}
                  />

                  <div
                    style={{ width: '90%' }}
                    className="cursor-pointer"
                  >
                    <span>{item.name}</span>
                  </div>
                  <div>{students.length}/5</div>
                  <div
                    onClick={() => {
                      setChildrensModal(true)
                    }}
                   
                    className="mx-1 cursor-pointer "
                  >
                    <FcExpand
                      className="text-dark"
                      style={{ fontSize: '20px' }}
                    />
                  </div>
                </div>
              ))}
            {isTeacherClicked &&
              datax.map((item, index) => (
                <div
                  key={index}
                  className="bg- my-1 customFieldOne d-flex justify-content-between border border-2  rounded  "
                >
                  <div
                    style={{ width: '90%'}}
                    className="cursor-pointer"
                  >
                    <input
                      style={{ transform: 'scale(1.5)' }}
                      className="mx-1"
                      type="checkbox"
                      checked={checkboxValues[item.name] || false}
                      onChange={() => handleCheckboxChange(item.name)}
                    />
                    <span>{item.name}</span>
                  </div>
                  <div>{TeacherData.length}/5</div>
                  <div
                    onClick={() => {
                      setChildrensModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                    className="mx-1 "
                  >
                    <FcExpand style={{ fontSize: '20px' }} />
                  </div>
                </div>
              ))}
          </Scrollbar>
        </Col>
        <Col md="12" style={{ marginTop: '10px' }} className="">
          <div
            onClick={() => {
              setModal(true)
            }}
            className="btn text-white mt-2 d-flex align-items-center justify-content-center btn-block"
            style={{ backgroundColor: '#1f59aa' }}
          >
            Ekle
          </div>
        </Col>
      </Row>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          style={{ backgroundColor: '#1F59AA' }}
          className=" d-flex justify-content-center align-items-center text-center"
          closeButton
          toggle={() => {
            setModal(!modal)
          }}
        >
          <div className="text-white">
            Mesajınız Aşağıdaki Kayıtlara Gönderilecektir ({students.length}{' '}
            Listeleniyor)
          </div>
        </ModalHeader>
        <ModalBody>

          <CustomTable fixedHeaderScrollHeight={"600px"} data={students} columns={columns} />

          <Col className="d-flex justify-content-center align-items-center">
            <Button
              onClick={() => {
                setModal(false)
              }}
              className=" btn btn-success  mx-1 "
            >
              Onayla
            </Button>
          </Col>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={childrensModal}
        toggle={() => setChildrensModal(!childrensModal)}
      >
        <ModalHeader
          style={{ backgroundColor: '#1F59AA' }}
          className=" d-flex justify-content-center align-items-center text-center"
          closeButton
          toggle={() => {
            setChildrensModal(!childrensModal)
          }}
        >
          <div className="text-white">
            8/A Sınıfı ({students.length} Listeleniyor)
          </div>
        </ModalHeader>
        <ModalBody>

          <CustomTable  fixedHeaderScrollHeight={"600px"} data={students} columns={columns} />

          <Col className="d-flex justify-content-center align-items-center">
            <Button
              onClick={() => {
                setChildrensModal(false)
              }}
              className=" btn btn-success  mx-1 "
            >
              Onayla
            </Button>
          </Col>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={teachersModal}
        toggle={() => setTeachersModal(!teachersModal)}
      >
        <ModalHeader
          style={{ backgroundColor: '#1F59AA' }}
          className=" d-flex justify-content-center align-items-center text-center"
          closeButton
          toggle={() => {
            setTeachersModal(!teachersModal)
          }}
        >
          <div className="text-white">
            8/A Sınıfı ({TeacherData.length} Listeleniyor)
          </div>
        </ModalHeader>
        <ModalBody>
          <CustomTable fixedHeaderScrollHeight={"600px"} data={teacher} columns={columnsTeacher} />
          <Col className="d-flex justify-content-center align-items-center">
            <Button
              onClick={() => {
                setTeachersModal(false)
              }}
              className=" btn btn-success  mx-1 "
            >
              Onayla
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Index
