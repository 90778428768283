// ** React Imports
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

// ** Icons Imports
import { Disc, X, Circle } from 'react-feather'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from '@utils'

const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    rgb,
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover,
  } = props

  // ** Vars
  const user = getUserData()

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    <div
      style={{
        backgroundColor: rgb,
        transition: 'all .5s ease',
        WebkitTransition: 'all .5s ease',
        MozTransition: 'all .5s ease',
       
      }}
      className="navbar-header "
    >
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto  ">
          <NavLink to={'/'} className="navbar-brand">
            <span className="brand-logo">
              {/* <img src='/image/wtm-logo.png' alt="logo" style={{maxWidth:'189px !important'}}/> */}
              {menuHover ? (
                <img
                  src="/image/wtm-logo.png"
                  alt="logo"
                  style={{
                    maxWidth: '189px !important',
                    position: 'relative',
                    bottom: '10px',
                  }}
                />
              ) : (
                <img
                
                  src="/image/wtm-logo5.png"
                  alt="logo"
                  style={{
                    backgroundColor:"transparent",
                    maxWidth: '189px !important',
                    position: 'relative',
                    right: '15px',
                    bottom: '23px',
                  }}
                />
              )}
            </span>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle cursor-pointer">
            <div></div>
            {/* <Toggler />
            <X
              onClick={() => setMenuVisibility(false)}
              className="toggle-icon icon-x d-block d-xl-none"
              size={20}
            />*/}
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
