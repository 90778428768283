import { array } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import { IoMdTrash } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomTable from 'src/views/components/customTable/customTable'

const index = () => {
  const navigate = useNavigate()
  const [updateModal, setUpdateModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [allCheck, setAllCheck] = useState(false)
  const [hasTrueValue, setHasTrueValue] = useState(false)
  const [datax, setDatax] = useState([
    {
      selected: false,
      classGroup: '1.Sınıf B / Şubesi',
      guideType: 'Ana Rehber',
      saveType: 'Öğrenci',
      count: '25',
    },
    {
      selected: false,
      classGroup: '2.Sınıf B / Şubesi',
      guideType: 'Ana Rehber',
      saveType: 'Öğrenci',
      count: '23',
    },
    {
      selected: false,
      classGroup: '3.Sınıf B / Şubesi',
      guideType: 'Ana Rehber',
      saveType: 'Öğrenci',
      count: '30',
    },
    {
      selected: false,
      classGroup: '4.Sınıf B / Şubesi',
      guideType: 'Ana Rehber',
      saveType: 'Öğrenci',
      count: '26',
    },
    {
      selected: false,
      classGroup: '5.Sınıf B / Şubesi',
      guideType: 'Ana Rehber',
      saveType: 'Öğrenci',
      count: '28',
    },
    {
      selected: false,
      classGroup: '6.Sınıf B / Şubesi',
      guideType: 'Ana Rehber',
      saveType: 'Öğrenci',
      count: '29',
    },
  ])
  const allContactChange = () => {
    setAllCheck(!allCheck)
    const array = [...datax]
    array.forEach((item, index) => {
      item.selected = !allCheck
    })
    setDatax(array)
  }

  const columns = [
    {
      name: (
        <>
          <Input
            onChange={() => {
              allContactChange()
            }}
            checked={allCheck}
            type="checkbox"
          ></Input>
        </>
      ),
      sortable: true,
      selector: (row: { selected: any }) => row.selected,
      width: '5%',
    },
    {
      name: 'Sınıf/Grup Adı',
      sortable: true,
      selector: (row: { classGroup: any }) => row.classGroup,
      width: '20%',
    },
    {
      name: 'Rehber Türü',
      sortable: true,
      selector: (row: { guideType: any }) => row.guideType,
      width: '20%',
    },
    {
      name: 'Kayıt Türü',
      sortable: true,
      selector: (row: { saveType: any }) => row.saveType,
      width: '20%',
    },
    {
      name: 'Sayı',
      sortable: true,
      selector: (row: { count: any }) => row.count,
      width: '10%',
    },
    {
      name: 'İşlemler',
      sortable: true,
      selector: (row: { allUpdated: any }) => row.allUpdated,
      width: '25%',
    },
  ]
  const guideChange = (i: any) => {
    const array = [...datax]
    array[i].selected = !array[i].selected
    setDatax(array)
  }

  const data = datax.map((item: any, index: number) => {
    return {
      selected: (
        <>
          <Input
            onChange={() => {
              guideChange(index)
            }}
            type="checkbox"
            checked={item.selected}
          />
        </>
      ),
      classGroup: item.classGroup,
      guideType: item.guideType,
      saveType: item.saveType,
      count: item.count,
      allUpdated: (
        <div className="d-flex">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('classGroupDetails/')
            }}
            className="px-1 py-1 bg-primary text-white rounded-2"
          >
            Görüntüle
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setUpdateModal(true)
            }}
            className="px-1 py-1 bg-warning text-white rounded-2 mx-1"
          >
            Güncelle
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setDeleteModal(true)
            }}
            className="px-1 py-1 bg-danger text-white rounded-2"
          >
            Sil
          </div>
        </div>
      ),
    }
  })

  useEffect(() => {
    const hasTrue = datax.some((item) => item.selected === true)
    setHasTrueValue(hasTrue)
  }, [datax])

  return (
    <div>
      <Row>
        <Col md="12" className="pl-0 d-flex justify-content-between my-2">
          <div>
            <Button
              onClick={(e: any) => {}}
              className="float-right"
              color="primary"
            >
              <Plus size={15} />
              <span className="align-middle">Yeni Kayıt ekle Ekle</span>
            </Button>
          </div>
          <div>
            <Input placeholder="Arama" />
          </div>
        </Col>

        <Col md="12">
          <CustomTable columns={columns} data={data} />
        </Col>
        {hasTrueValue ? (
          <Col
            className="d-flex  fixed-bottom fixed-right justify-content-center"
            md="12"
          >
            <Button
              style={{ width: '800px' }}
              className="btn btn-danger  mb-2 "
            >
              Seçili Sınıfları Sil
            </Button>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={updateModal}
        toggle={() => setUpdateModal(!updateModal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center "
          closeButton
          toggle={() => setUpdateModal(!updateModal)}
        ></ModalHeader>
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col className="mb-1" md="8">
              <Label>Sınıf/Grup Adı</Label>
              <Input placeholder="Sınıf/Grup Adı"></Input>
            </Col>
            <Col md="8">
              <Label>Rehber Türü</Label>
              <SelectOptions />
            </Col>
            <Col md="8">
              <Label>Kayıt Türü</Label>
              <SelectOptions />
            </Col>
            <Col md="8" className="d-flex justify-content-center">
              <Button
                onClick={() => setUpdateModal(false)}
                className="btn btn-block d-flex btn-danger"
              >
                Güncelle
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-md py-1"
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
      >
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col className="my-1 d-flex justify-content-center " md="12">
              <IoMdTrash fontSize={'120px'} color="red" />
            </Col>
            <Col className="my-1 d-flex justify-content-center" md="12">
              <h1>Emin misiniz?</h1>
            </Col>
            <Col className="my-1 d-flex justify-content-center " md="12">
              <h6 className="text-center">
                DEGERLI OKUL YETKİLİSİ, EOKUL TARAFINDA YAPILAN GÜNCELLEME
                NEDENİ İLE ŞU ANDA REHBER BİLGİLERİ ÇEKİLEMEMKTEDİR. REHBERİNİZİ
                SİLMENİZ DURUMUNDA TEKRAR ÇEKEMEYECEKSİNİZ.{' '}
                <b className="text-danger">REHBERİNİZİ LÜTFEN SİLMEYİNİZ</b>
              </h6>
            </Col>
            <Col md="12" className="d-flex justify-content-around">
              <Button
                onClick={() => setDeleteModal(false)}
                className="btn btn-block d-flex btn-danger"
              >
                Evet,Sil
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                className="btn btn-block d-flex btn-success"
              >
                Vazgeç
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
