// ** React Imports
import { Fragment, useState, useRef, useEffect } from 'react'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Vertical Menu Components
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'
import { useTranslation } from 'react-i18next'
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi'
import { Input } from 'reactstrap'

const Sidebar = (props) => {
  const { t } = useTranslation()
  const [color, setColor] = useState(false)
  const [color1, setColor1] = useState(false)
  const [color2, setColor2] = useState(false)
  const [rgb, setRgb] = useState(
    localStorage.getItem('rgb') &&
      typeof localStorage.getItem('rgb') === 'string'
      ? JSON.parse(localStorage.getItem('rgb'))
      : ''
  )
  // ** Props
  const { menuCollapsed, menu, skin, menuData } = props

  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])
  const [activeItem, setActiveItem] = useState(null)
  const [isChecked, setIsChecked] = useState(() => {
    const isCheck = localStorage.getItem('isChecked')
    return isCheck ? JSON.parse(isCheck) : true
  })

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState()

  // ** Ref
  const shadowRef = useRef(null)

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    setMenuHover(true)
  }
  // ** Function to prevent Vertical Menu closure
  const checked = () => {
    setIsChecked(!isChecked)
  }
  const selectColor = (e) => {
    e === 'color'
      ? (setColor(true), setColor1(false), setColor2(false), setRgb('#122639'))
      : e === 'color1'
        ? (setColor1(true), setColor(false), setColor2(false), setRgb(''))
        : (setColor2(true), setColor1(false), setColor(false), setRgb('#A07BE5'))
  }

  localStorage.setItem('rgb', JSON.stringify(rgb))
  localStorage.setItem('isChecked', JSON.stringify(isChecked))
  useEffect(() => {
    localStorage.setItem('menuHover', JSON.stringify(menuHover))
  }, [isChecked])
  // useEffect(() => {
  //   const storedMenuHover = localStorage.getItem('menuHover');
  //   const storedIsChecked = localStorage.getItem('isChecked');
  //     setMenuHover(JSON.parse(storedMenuHover));
  //     setIsChecked(JSON.parse(storedIsChecked));
  // }, []);

  useEffect(() => {
    const contentDiv = document.getElementById('content-div')
    const navDiv = document.getElementById('nav-div')
    if (isChecked === false) {
      contentDiv.style.marginLeft = menuHover ? '260px' : '5%'
      navDiv.style.marginLeft = menuHover ? '210px' : '30px'
    } else {
      if (contentDiv) {
        contentDiv.style.marginLeft = '60px'
        navDiv.style.marginLeft = '0px'
      }

    }
  }, [menuHover, isChecked])

  // ** Scroll Menu
  const scrollMenu = (container) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.add('d-block')
      }
    } else {
      if (shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.remove('d-block')
      }
    }
  }

  return (
    <Fragment>
      <div
        className={classnames(
          'main-menu menu-fixed menu-accordion menu-shadow',
          {
            expanded: menuHover || menuCollapsed === false,
            'menu-light': skin !== 'semi-dark' && skin !== 'dark',
            'menu-dark': skin === 'semi-dark' || skin === 'dark',
          }
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => {
          isChecked ? setMenuHover(false) : setMenuHover(true)
        }}
      >
        {menu ? (
          menu({ ...props })
        ) : (
          <Fragment>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader
              rgb={rgb}
              setGroupOpen={setGroupOpen}
              menuHover={menuHover}
              {...props}
            />
            {/* Vertical Menu Header Shadow */}
            <div className="shadow-bottom" ref={shadowRef}></div>
            <div
              style={{
                backgroundColor: rgb,
                transition: 'all .5s ease',
                WebkitTransition: 'all .5s ease',
                MozTransition: 'all .5s ease',
              }}
              className="d-flex justify-content-end"
            >
              {/* İçerik */}
              {/* Vertical Menu closure */}
              {menuHover && [
                isChecked ? (
                  <div
                    style={{ cursor: 'pointer', backgroundColor: '#FF9F02' }}
                    onClick={() => checked()}
                    className="border-none border-1   rounded-5 mx-1  px-1"
                  >
                    <FiChevronsRight fontSize={"20px"} color="white" />
                  </div>
                ) : null,
                isChecked ? null : (
                  <div
                    style={{ cursor: 'pointer', backgroundColor: '#FF9F02' }}
                    onClick={() => checked()}
                    className="border-none border-1 rounded-5 mx-1 px-1"
                  >
                    < FiChevronsLeft fontSize={"20px"} color="white" />
                  </div>
                ),
              ]}
            </div>
            {/* Perfect Scrollbar */}
            <PerfectScrollbar
              style={{
                backgroundColor: rgb,
                transition: 'all .5s ease',
                WebkitTransition: 'all .5s ease',
                MozTransition: 'all .5s ease',
              }}
              className="main-menu-content  "
              options={{ wheelPropagation: false }}
              onScrollY={(container) => scrollMenu(container)}
            >
              <ul
                style={{
                  backgroundColor: rgb,
                  transition: 'all .5s ease',
                  WebkitTransition: 'all .5s ease',
                  MozTransition: 'all .5s ease',
                }}
                className="navigation navigation-main"
              >
                <VerticalNavMenuItems
                  rgb={rgb}
                  items={menuData}
                  menuData={menuData}
                  menuHover={menuHover}
                  groupOpen={groupOpen}
                  activeItem={activeItem}
                  groupActive={groupActive}
                  setGroupOpen={setGroupOpen}
                  menuCollapsed={menuCollapsed}
                  setActiveItem={setActiveItem}
                  setGroupActive={setGroupActive}
                  currentActiveGroup={currentActiveGroup}
                  setCurrentActiveGroup={setCurrentActiveGroup}
                />
              </ul>
              {menuHover && [
                <div className=" d-flex justify-content-center mt-0">
                  <div
                    style={{
                      width: '40%',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                    className="bg-light d-flex justify-content-center rounded-5  px-1"
                  >
                    <Input
                      style={{ backgroundColor: '#122639' }}
                      onChange={() => selectColor('color')}
                      type="radio"
                      checked={color}
                    />
                    <Input
                      style={{ backgroundColor: '#FFFFFF' }}
                      onChange={() => selectColor('color1')}
                      className="mx-1"
                      type="radio"
                      checked={color1}
                    />
                    <Input
                      style={{ backgroundColor: '#A07BE5' }}
                      onChange={() => selectColor('color2')}
                      type="radio"
                      checked={color2}
                    />
                  </div>
                </div>,
              ]}
            </PerfectScrollbar>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Sidebar
