import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import AccountModal from './account'
import SecurityModal from './security'
import SmsModal from './sms'
import AuthModal from './auth'
import * as yup from 'yup'
import {
  MdDetails,
  MdManageAccounts,
  MdOutlineDetails,
  MdPeople,
  MdSecurity,
  MdSms,
  MdSmsFailed,
  MdSuperscript,
  MdTty,
} from 'react-icons/md'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import DetailsModal from './details'

const Settings = () => {
  const [accountM, setAccountM] = useState(false)
  const [securityM, setSecurityM] = useState(false)
  const [smsM, setSmsM] = useState(false)
  const [authM, setAuthM] = useState(false)
  const [detailsM, setDetailsM] = useState(false)

  return (
    <>
      <div className="d-flex ms-1 w-100 mb-5">
        <SettingsContainer
          title="Kullanıcı Detayları"
          icon={<MdPeople size={50} className="mb-1" />}
          onClick={() => setDetailsM(true)}
        >
          <DetailsModal modal={detailsM} setModal={setDetailsM} />
        </SettingsContainer>
        <SettingsContainer
          title="Hesap Bilgilerim"
          icon={<MdManageAccounts size={50} className="mb-1" />}
          onClick={() => setAccountM(true)}
        >
          <AccountModal modal={accountM} setModal={setAccountM} />
        </SettingsContainer>
        <SettingsContainer
          title="Güvenlik Ayarları"
          icon={<MdSecurity size={50} className="mb-1" />}
          onClick={() => setSecurityM(true)}
        >
          <SecurityModal modal={securityM} setModal={setSecurityM} />
        </SettingsContainer>
        <SettingsContainer
          title="SMS Ayarları"
          icon={<MdSms size={50} className="mb-1" />}
          onClick={() => setSmsM(true)}
        >
          <SmsModal modal={smsM} setModal={setSmsM} />
        </SettingsContainer>
        <SettingsContainer
          title="Çoklu SMS Doğrulama"
          icon={<MdSmsFailed size={50} className="mb-1" />}
          onClick={() => setAuthM(true)}
        >
          <AuthModal modal={authM} setModal={setAuthM} />
        </SettingsContainer>
      </div>
      <div
        className="d-flex w-100 bg-white shadow ms-1 me-1 p-3 rounded"
        style={{ justifyContent: 'space-between' }}
      >
        <div className="w-100">
          <div className="me-3 w-100 d-flex flex-column justify-content-center mb-5">
            <h3 className="m-0 mb-1 p-0 text-danger">
              Whatsapp Bağlantısı Kesildi
            </h3>
            <h3 className="m-0 mb-1 p-0 ">
              Whatsapp Numarası
              <span className="text-danger ms-1">0555 555 55 55</span>
            </h3>
            <Button className="w-75" variant="primary">
              <h4 className="text-white m-0">
                Whatsapp Numarasını Hesaptan Kaldır
              </h4>
            </Button>
          </div>
          <div className="me-3 w-100 d-flex flex-column justify-content-center">
            <h3 className="m-0 mb-1 p-0 text-success">
              Whatsapp Kredisi: 2407
            </h3>
            <h3 className="m-0 mb-1 p-0 text-danger">Bağlantı Koptu</h3>
            <Button className="w-75" variant="success">
              <h4 className="text-white m-0">Tekrar Bağlan</h4>
            </Button>
          </div>
        </div>

        <div className="embed-responsive embed-responsive-16by9">
          <h3 className="text-center mb-1">
            Okul Telefonuna Whatsapp Nasıl Kurulur ?
          </h3>
          <iframe
            width={700}
            height={300}
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/4OlZYhS9kbI"
            allowFullScreen
          />
        </div>
      </div>
    </>
  )
}

export default Settings

interface Props {
  icon: any
  title: string
  onClick: any
  children: JSX.Element
}

const SettingsContainer = (props: Props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        width: window.innerWidth / 4,
        height: 150,
      }}
      className="d-flex me-1 align-items-center bg-white justify-content-center flex-column shadow rounded"
    >
      {props.icon}
      <p>{props.title}</p>
      {props.children}
    </div>
  )
}
