import { Col } from 'reactstrap'
// ** Third Party Components
import Select from 'react-select'
import { selectThemeColors } from '@utils'

const SelectOptions = (props) => {
  return (
    <Col md={12} xs={12} className="mb-1">
      <div
        className="border border-primary rounded"
        style={{ borderColor: 'red' }}
      >
        <Select
          theme={selectThemeColors}
          classNamePrefix="select"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          options={props.options}
          isClearable={false}
          isOptionSelected={props.selected}
          onChange={props.onChange}
          name={props.name}
          selected={props.selected}
          defaultValue={props.defaultValue}
          isDisabled={props.isDisabled}
          value={props.value}
          placeholder={props.placeholder}
        />
      </div>
    </Col>
  )
}
export default SelectOptions
