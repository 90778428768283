import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { SiMicrosoftexcel } from 'react-icons/si'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { FaWhatsapp } from 'react-icons/fa'
// @ts-ignore ????
import WpReports from './views/reports/wp.tsx'
// @ts-ignore ????
import SmsReports from './views/reports/sms.tsx'

import WpCallRollReports from './views/callRollReports/wp'
import SearchReports from './components/searchReports/sms'
import WpSearchReports from './components/searchReports/wp'
import CallRollReports from './views/callRollReports/sms'

// https://www.alpemix.com/site/alpemix.exe

const index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const toggleTabType = (tab: any) => {
    if (activeType !== tab) {
      setActiveType(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])
  return (
    <>
      <Nav
        style={{ width: '100%', backgroundColor: '#FFFFFF' }}
        pills
        className="mb-2 d-flex align-items-center justify-content-start "
      >
        <NavItem className="" style={{ width: '17%', marginRight: '10px' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#1F59AA',
              backgroundColor: active === 1 ? '#1F59AA' : 'inherit',
              color: active === 1 ? 'white' : '#1F59AA',
            }}
            className=" border-2  rounded"
            active={active === 1}
            onClick={() => {
              toggleTab(1)
            }}
          >
            <Send className="font-medium-3 " />
            <span className="fw-bold">{t('Raporlar')}</span>
          </NavLink>
        </NavItem>

        <NavItem className="mx-1" style={{ width: '18%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              color: active === 3 ? 'white' : '#1F59AA',
              borderColor: '#1F59AA',
              backgroundColor: active === 3 ? '#1F59AA' : 'inherit',
            }}
            className="border-  border-2  rounded"
            active={active === 3}
            onClick={() => {
              toggleTab(3)
            }}
          >
            <SiMicrosoftexcel className="font-medium-3 me-50" />
            <span className="fw-bold">{t('E-yoklama Raporları')}</span>
          </NavLink>
        </NavItem>
        <NavItem className="mx-1" style={{ width: '18%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#1F59AA',
              backgroundColor: active === 2 ? '#1F59AA' : 'inherit',
              color: active === 2 ? 'white' : '#1F59AA',
            }}
            className="border-  border-2  rounded"
            active={active === 2}
            onClick={() => {
              toggleTab(2)
            }}
          >
            <SiMicrosoftexcel className="font-medium-3 me-50" />
            <span className="fw-bold">{t('Rapor Ara')}</span>
          </NavLink>
        </NavItem>
      </Nav>
      <Nav
        style={{ width: '100%', backgroundColor: '#FFFFFF' }}
        pills
        className="mb-2 d-flex align-items-center justify-content-start "
      >
        <NavItem
          className=""
          style={{
            width: '16%',
          }}
        >
          <NavLink
            style={{
              backgroundColor: activeType === 1 ? '#FFAB00' : 'inherit',
              color: activeType === 1 ? '#F3F2F7' : '#5E5873',
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#FFAB00',
            }}
            className="  border-2  rounded"
            active={activeType === 1}
            onClick={() => {
              toggleTabType(1)
            }}
          >
            <Send className="font-medium-3 me-50" />
            <span className="fw-bold">{t('SMS')}</span>
          </NavLink>
        </NavItem>
        <NavItem
          style={{
            width: '16%',
          }}
          className="mx-1"
        >
          <NavLink
            style={{
              backgroundColor: activeType === 2 ? '#25D366' : 'inherit',
              color: activeType === 2 ? '#F3F2F7' : '#5E5873',
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#25D366',
            }}
            className="  border-2  rounded"
            active={activeType === 2}
            onClick={() => {
              toggleTabType(2)
            }}
          >
            <FaWhatsapp className="font-medium-3 me-50" />
            <span className="fw-bold">{t('Whatsapp')}</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        style={{ display: activeType === 2 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <SmsReports />
        </TabPane>
        <TabPane tabId={2}>
          <SearchReports />
        </TabPane>
        <TabPane tabId={3}>
          <CallRollReports />
        </TabPane>
      </TabContent>
      <TabContent
        style={{ display: activeType === 1 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <WpReports />
        </TabPane>
        <TabPane tabId={2}>
          <WpSearchReports />
        </TabPane>
        <TabPane tabId={3}>
          <WpCallRollReports />
        </TabPane>
      </TabContent>
    </>
  )
}

export default index
