// ** React Imports
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Share, Grid } from 'react-feather'
import './customTable.scss'
// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Input,
  Label,
  CardTitle,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledButtonDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'
import { MdChevronRight, MdChevronLeft } from 'react-icons/md'
import axios from 'axios'
import '../../../@core/scss/react/libs/tables/react-dataTable-component.scss'
import { application } from '../../../redux/store'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type IType = {
  data?: any
  columns?: any
  title?: any
  showNotFoundImage?: any
  setmodal?: any
  modal?: boolean
  setModal?: React.Dispatch<React.SetStateAction<boolean>>
  excelDownload?: any
  excelData?: any
  method?: 'get' | 'post' | 'put' | 'delete'
  Export?: boolean
  children?: any
  subButton?: any
  customStyles?: any
  conditionalRowStyles?: any
  onRowClicked?: any
  end?: boolean
  Search?: any
  style?: any
  pdfColumns?: any
  loading?: boolean
  notPage?: boolean
  center?: boolean
  totalData?: number
  multiPaginate?: boolean
  setPage?: any
  page?: number
  detailPaginate?: boolean
  isTitleFull?: boolean
  fixedHeaderScrollHeight?: any
}
const CustomTable = (props: IType) => {
  const { t }: any = useTranslation()
  const { search } = useLocation()
  const router = useNavigate()
  const page: any = props.detailPaginate
    ? props.page
    : new URLSearchParams(search).get('page') ?? 1
  const [searchValue, setSearchValue] = useState('')
  const [multiPage, setMultiPage]: any = useState([])
  const count: any = props.totalData
  const countPage = Math.ceil(count / 10)
  const handleFilter = (e: any) => {
    const value = e.target.value
    setSearchValue(value)
    setSearchValue(value)
  }
  useEffect(() => {
    const count = props.totalData
    let NewData: any[] = []
    if (count) {
      const countPage = Math.ceil(count / 10)
      for (let index = 1; index <= countPage; index++) {
        NewData.push({
          id: index,
          page: index,
        })
      }
      setMultiPage(NewData)
    }
    NewData = []
  }, [props.totalData])
  const increasePage = () => {
    if (props.detailPaginate) {
      props.setPage(page + 1)
    } else {
      router({
        search: `page=${Number(page) + 1}`,
      })
    }
  }

  const decrasePage = () => {
    if (props.detailPaginate) {
      props.setPage(page - 1)
    } else {
      router({
        search: `page=${Number(page) - 1}`,
      })
    }
  }
  const excelDownload = () => {
    if (props.method === 'get') {
      axios
        .get(
          `${application.api}${props.excelDownload}${
            props.excelData?.is_excel ? '?is_excel=true' : ''
          }`,
          {
            responseType: 'blob',
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${Date.now()}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
    } else {
      axios
        .post(
          `${application.api}${props.excelDownload}${
            props.excelData?.is_excel ? '?is_excel=true' : ''
          }`,
          props.excelData,
          {
            responseType: 'blob',
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${Date.now()}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
    }
  }
  // ** Custom Pagination
  const CustomPagination = () => {
    if (props.notPage) {
      return
    } else {
      return (
        <div
          id="customTablePages"
          className={
            'd-flex w-100 justify-content-center align-items-center position-relative'
          }
          style={{ position: 'relative', bottom: '0' }}
        >
          {props.multiPaginate ? (
            <Pagination
              size="md"
              style={{
                display:
                  Math.ceil(Number(props?.totalData) / 10) > 1
                    ? 'flex'
                    : 'none',
              }}
            >
              <PaginationItem disabled={page === 1}>
                <PaginationLink
                  first
                  onClick={() =>
                    props.detailPaginate
                      ? props.setPage(1)
                      : router({
                          search: `page=${1}`,
                        })
                  }
                />
              </PaginationItem>
              <PaginationItem disabled={page === 1}>
                <PaginationLink onClick={decrasePage} previous />
              </PaginationItem>
              {multiPage
                .map((data: any, index: number) => (
                  <PaginationItem
                    key={index}
                    active={parseInt(page) === data.page}
                  >
                    <PaginationLink
                      onClick={() =>
                        props.detailPaginate
                          ? props.setPage(data.page)
                          : router({
                              search: `page=${Number(data.page)}`,
                            })
                      }
                    >
                      {data.page}
                    </PaginationLink>
                  </PaginationItem>
                ))
                .slice(
                  page + 4 > countPage
                    ? page >= 4
                      ? countPage - 4
                      : 0
                    : page === Math.ceil(Number(props?.totalData) / 10)
                    ? page - 4
                    : page - 1,
                  page + 4 > countPage
                    ? countPage
                    : page === Math.ceil(Number(props?.totalData) / 10)
                    ? page
                    : page + 3
                )}
              <PaginationItem
                disabled={page === Math.ceil(Number(props?.totalData) / 10)}
              >
                <PaginationLink onClick={increasePage} next />
              </PaginationItem>
              <PaginationItem
                disabled={page === Math.ceil(Number(props?.totalData) / 10)}
              >
                <PaginationLink
                  onClick={() =>
                    props.detailPaginate
                      ? props.setPage(Math.ceil(Number(props?.totalData) / 10))
                      : router({
                          search: `page=${Math.ceil(
                            Number(props?.totalData) / 10
                          )}`,
                        })
                  }
                  last
                />
              </PaginationItem>
            </Pagination>
          ) : (
            <>
              <button
                className={page > 1 ? 'chevron-active' : 'chevron-passive'}
                disabled={page > 1 ? false : true}
                onClick={() =>
                  props.detailPaginate
                    ? props.setPage(page - 1)
                    : router({
                        search: `page=${Number(page) - 1}`,
                      })
                }
              >
                <MdChevronLeft fontSize={20} className="chevron-itself" />
              </button>
              <span className="mx-1">{page}</span>
              <button
                className={
                  props.data?.length >= 10
                    ? 'chevron-active'
                    : 'chevron-passive'
                }
                disabled={props.data?.length >= 10 ? false : true}
                onClick={() =>
                  props.detailPaginate
                    ? props.setPage(page + 1)
                    : router({
                        search: `page=${Number(page) + 1}`,
                      })
                }
              >
                <MdChevronRight fontSize={20} className="chevron-itself" />
              </button>
            </>
          )}
        </div>
      )
    }
  }

  return (
    <div className="custom-table">
      <Card>
        <CardHeader
          style={{ position: 'relative', backgroundColor: '#FFFFFF' }}
          className={`${props.center ? 'justify-content-center' : ''} ${
            props.end ? 'justify-content-end' : ''
          } d-none `}
        >
          <CardTitle
            style={{ width: props.isTitleFull ? '100%' : 'auto' }}
            tag="h3"
          >
            {' '}
            {!props.subButton ? props.children : ''}{' '}
          </CardTitle>
          <h4
            style={{ position: 'absolute', textAlign: 'center', width: '100%' }}
          >
            {props?.title}
          </h4>
          <div className="d-flex mt-md-0 mt-1">
            {props.Export ? (
              <UncontrolledButtonDropdown>
                <DropdownToggle color="secondary" caret outline>
                  <Share size={15} />
                  <span className="align-middle ms-50">{t('Dışa Aktar')}</span>
                </DropdownToggle>
                <DropdownMenu>
                  {/*  <DropdownItem className="w-100">
                    <Printer size={15} />
                    <span className="align-middle ms-50">Print</span>
                  </DropdownItem>
                  <DropdownItem
                    className="w-100"
                    onClick={() => downloadCSV(data)}
                  >
                    <FileText size={15} />
                    <span className="align-middle ms-50">CSV</span>
                  </DropdownItem> */}
                  <DropdownItem
                    className="w-100"
                    onClick={() => excelDownload()}
                  >
                    <Grid size={15} />
                    <span className="align-middle ms-50">Excel</span>
                  </DropdownItem>
                  {/* <DropdownItem className="w-100" onClick={() => pdfDownload()}>
                    <Printer size={15} />
                    <span className="align-middle ms-50">Yazdır</span>
                  </DropdownItem> */}
                  {/* <DropdownItem className="w-100">
                    <Copy size={15} />
                    <span className="align-middle ms-50">Copy</span>
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            ) : (
              ''
            )}
          </div>
        </CardHeader>
        {props.Search ? (
          <Row className="justify-content-end mx-0">
            <Col
              className="d-flex align-items-center justify-content-end mt-1"
              md="6"
              sm="12"
            >
              <Label className="me-1" for="search-input">
                {t('Arama')}
              </Label>
              <Input
                className="dataTable-filter mb-50"
                type="text"
                bsSize="sm"
                id="search-input"
                value={searchValue}
                onChange={handleFilter}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}
        {props.subButton ? (
          <Row className="justify-content-end mx-0">
            <Col
              className="d-flex align-items-center justify-content-end mt-1"
              md="6"
              sm="12"
            >
              {props.children}
            </Col>
          </Row>
        ) : (
          ''
        )}
        <div className="react-dataTable" style={props.style}>
          <DataTable
            striped
            noHeader
            fixedHeaderScrollHeight={props.fixedHeaderScrollHeight}
            fixedHeader
            pagination={
              (props.notPage ? false : true) &&
              (props.data?.length > 10 ? false : true)
            }
            // selectableRows
            columns={props.columns}
            conditionalRowStyles={props.conditionalRowStyles}
            customStyles={props.customStyles}
            // paginationPerPage={10}
            className="react-dataTable"
            onRowClicked={props.onRowClicked}
            sortIcon={<ChevronDown size={10} />}
            // paginationDefaultPage={currentPage + 1}
            // style={{ height: '200px !important', overflowY: 'scroll' }}
            paginationComponent={!props.notPage && (CustomPagination as any)}
            progressComponent={
              <>
                <div
                  style={{
                    display: 'block',
                    lineHeight: 2,
                    padding: '1rem',
                    width: '100%',
                  }}
                >
                  <Skeleton count={10} />
                </div>
              </>
            }
            progressPending={props.loading}
            data={props.data ?? []}
            noDataComponent={
              <>
                <div style={{ padding: 15 }}>
                  {props.showNotFoundImage && props.data.length === 0 ? (
                    <div></div>
                  ) : (
                    <img
                      src="https://app.salonrandevu.com/static/media/no_data_table.d0e6394b.svg"
                      width={320}
                    />
                  )}
                  <p className="text-center">{t('Kayıt Bulunamadı')}</p>
                  {page > 0 && !props.notPage && CustomPagination()}
                </div>
              </>
            }
          />
        </div>
      </Card>
    </div>
  )
}

export default CustomTable
