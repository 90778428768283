import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlineCloudUpload } from 'react-icons/ai';

import './index.css';

const index = (props: { onImageChange: (image: string | null ,name:any ) => void }  ) => {
  const [image, setImage] = useState<string | null>(null);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            setImage(event.target.result as string);
            props.onImageChange(event.target.result as string, name);
          
          }
        };
        reader.readAsDataURL(file);
      }
    },
  });

  return (
    <>
      <div>
        <div className="text-center file-upload-container">
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? 'active' : ''}`}
          >
            <input {...getInputProps()} />
            <AiOutlineCloudUpload size={40} color="#AAAAAA" />
            <p style={{ cursor: 'pointer' }}>
              Resim dosyasını buraya sürükleyin ya da tıklayın
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
