import React, { useEffect, useState } from 'react'
import { IoIosCheckmarkCircleOutline, IoMdTrash } from 'react-icons/io'
import { Scrollbar } from 'react-scrollbars-custom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomTable from 'src/views/components/customTable/customTable'
const index = () => {
  const [active, setActive] = useState(false)
  const [modal, setModal] = useState(false)
  const [copyModal, setCopyModal] = useState(false)
  const [allActive, setAllActive] = useState(false)
  const [hasTrueValue, setHasTrueValue] = useState(false)
  const [students, setStudents] = useState<any>([
    { selected: false, schoolNo: '123', name: 'Can Aslan' },
    { selected: false, schoolNo: '124', name: 'Esma Avcı' },
    { selected: false, schoolNo: '125', name: 'Selen Yüksek' },
    { selected: false, schoolNo: '126', name: 'Ali Veli' },
    { selected: false, schoolNo: '127', name: 'Mehmet Acar' },
    { selected: false, schoolNo: '128', name: 'Yusuf Karaman' },
    { selected: false, schoolNo: '129', name: 'Buse Yaman' },
    { selected: false, schoolNo: '130', name: 'Ayten Kılıç' },
  ])
  const allSelected = () => {
    setAllActive(!allActive)
    const array = [...students]
    array.forEach((item: any) => {
      item.selected = !allActive
    })
    setStudents(array)
  }
  const columns = [
    {
      name: (
        <>
          <Input
            onChange={() => {
              allSelected()
            }}
            type="checkbox"
          />
        </>
      ),
      sortable: true,
      selector: (row: { selected: any }) => row.selected,
    },
    {
      name: 'Okul Numarası',
      sortable: true,
      selector: (row: { schoolNo: any }) => row.schoolNo,
    },
    {
      name: 'Ad Soyad',
      sortable: true,
      selector: (row: { name: any }) => row.name,
    },
  ]
  const selectOnchange = (i: number) => {
    const array = [...students]
    array[i].selected = !array[i].selected
    setStudents(array)
  }
  const data = students.map((item: any, index: number) => {
    return {
      selected: (
        <>
          <Input
            onChange={() => {
              selectOnchange(index)
            }}
            type="checkbox"
            checked={item.selected}
          />
        </>
      ),
      schoolNo: item.schoolNo,
      name: item.name,
    }
  })
  useEffect(() => {
    const hasTrue = students.some((item: any) => item.selected === true)
    setHasTrueValue(hasTrue)
  }, [students])
  return (
    <div className='mt-3'>
      <Row>
        <Col style={{ borderRight: '2px solid #ccc' }} className="bg-" xs="12" xl="3">
          <Card
            className="mx-0 my-0"
            style={{ width: '100%', backgroundColor: '#FFFFFF' }}
          >
            <CardHeader>1.Adım</CardHeader>
            <CardBody>
              <Col className="mb-1 d-flex justify-content-center">
                <h3>
                  <b>Listelencek Olan Grubu Seçiniz</b>
                </h3>
              </Col>
              <Col className="mb-1 d-flex justify-content-center">
                <SelectOptions />
              </Col>
              <Col className="mb-1 d-flex justify-content-center">
                <Button
                  onClick={() => {
                    setActive(true)
                  }}
                  className="btn btn-success"
                >
                  Kayıtları Getir
                </Button>
              </Col>
              <Col>
                Taşımak veya kopyalamak istediğiniz kayıt(ların) bulunduğu grubu
                seçin.
              </Col>
            </CardBody>
          </Card>
        </Col>
        {active ? (
          <Col style={{ borderRight: '2px solid #ccc' }} className="my-1"   sm="12"  xl="6" >
            <Card
              className="mx-0 my-0"
              style={{ width: '100%', backgroundColor: '#FFFFFF' }}
            >
              <CardHeader>2.Adım</CardHeader>
              <CardBody>
                <Col className="mb-1 d-flex flex-column justify-content-center">
                  <h3 className="mb-1">
                    <b>
                      FL - 9. Sınıf / A Şubesi (FEN BİLİMLERİ ALANI (FEN LİS.))
                      Kayıt Listesi
                    </b>
                  </h3>
                  <div className="d-flex justify-content-center">
                    <span>
                      (Taşınacak veya kopyalanacak olan kayıtları işaretleyin)
                    </span>
                  </div>
                </Col>

                <Scrollbar className="" style={{ width: '100%', height: 440 }}>
                  <CustomTable data={data} columns={columns} notPage >
                    <Input placeholder='arama'></Input>
                  </CustomTable>
                </Scrollbar>
              </CardBody>
            </Card>
          </Col>
        ) : (
          ''
        )}
        {hasTrueValue ? (
          <Col className="bg-" sm="12"  xl="3" >
            <Card
              className="mx-0 my-0"
              style={{ width: '100%', backgroundColor: '#FFFFFF' }}
            >
              <CardHeader>3.Adım</CardHeader>
              <CardBody>
                <Col className="mb-1 d-flex justify-content-center">
                  <h3>
                    <b>
                      Aktarılacak olan grubu ve yapılacak olan işlemi seçiniz
                    </b>
                  </h3>
                </Col>
                <Col className="mb-1 d-flex justify-content-center">
                  <SelectOptions />
                </Col>
                <Col className="mb-1 d-flex justify-content-center">
                  <Button
                  onClick={()=>{
                    setCopyModal(true)
                  }} className="btn btn-warning mx-1">
                    Seçileni Kopyala
                  </Button>
                  <Button
                    className="btn btn-success mx-1"
                    onClick={() => {
                      setModal(true)
                    }}
                  >
                    Seçileni Taşı
                  </Button>
                </Col>
                <Col>
                  Taşımak veya kopyalamak istediğiniz grubu seçin ve tercihinize
                  uygun işlem buttonuna basın
                </Col>
              </CardBody>
            </Card>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Modal
        className="modal-dialog-centered modal-md py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col className="my-1 d-flex justify-content-center " md="12">
              <IoIosCheckmarkCircleOutline fontSize={'120px'} color="green" />
            </Col>
            <Col className="mb-1 d-flex justify-content-center" md="12">
              <h1>Başarılı</h1>
            </Col>
            <Col
              className="mb-3 d-flex flex-column justify-content-center "
              md="12"
            >
              <div className="d-flex justify-content-center">
                <b>İşlem Başarıyla Gerçekleştirildi</b>
              </div>
              <div className="d-flex justify-content-center">
                <b>
                  1 Adet Kayıt 9/A Sınıf/Grubundan 9/B Sınıf/Grubuna Taşındı{' '}
                </b>
              </div>
            </Col>
            <Col md="12" className="d-flex justify-content-around">
              <Button
                onClick={() => setModal(false)}
                className="btn  btn-primary"
                color='blue'
              >
                Tamam
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-md py-1"
        isOpen={copyModal}
        toggle={() => setCopyModal(!copyModal)}
      >
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col className="my-1 d-flex justify-content-center " md="12">
              <IoIosCheckmarkCircleOutline fontSize={'120px'} color="green" />
            </Col>
            <Col className="mb-1 d-flex justify-content-center" md="12">
              <h1>Başarılı</h1>
            </Col>
            <Col
              className="mb-3 d-flex flex-column justify-content-center "
              md="12"
            >
              <div className="d-flex justify-content-center">
                <b>İşlem Başarıyla Gerçekleştirildi</b>
              </div>
              <div className="d-flex justify-content-center">
                <b>
                  1 Adet Kayıt 9/A Sınıf/Grubundan 9/B Sınıf/Grubuna Kopyalandı{' '}
                </b>
              </div>
            </Col>
            <Col md="12" className="d-flex justify-content-around">
              <Button
                onClick={() => setCopyModal(false)}
                className="btn  btn-primary"
                color='blue'
              >
                Tamam
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
