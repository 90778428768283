import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'

type ICustomModal = {
  modal: boolean
  setModal: React.Dispatch<React.SetStateAction<boolean>>
  fullScreen?: boolean
  children?: any
  size?: string
}
const CustomModal = (props: ICustomModal) => {
  return (
    <div className="on-opened-modal">
      <Modal
        style={{
          paddingBottom: props.fullScreen ? 300 : 0,
          marginTop: props.fullScreen ? '5%' : 0,
        }}
        fullscreen={props.fullScreen}
        isOpen={props.modal}
        toggle={() => props.setModal(!props.modal)}
        className={`modal-dialog-centered ${
          props.size ? props.size : 'modal-lg'
        }
        ${props.fullScreen ? 'px-5' : ''}
        `}
      >
        <ModalHeader
          style={{ height: 0, paddingBottom: 0, margin: 0 }}
          toggle={() => props.setModal(!props.modal)}
        />
        <div>{props.children}</div>
      </Modal>
    </div>
  )
}

export default CustomModal
