import { useEffect, useState } from 'react'
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import SmsPackages from './smsPackages/index'
import WpPackages from './wpPackages/index'
import PaymentHistory from './paymentHistory'
import BankAccount from './bankAccount'

const index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const toggleTabType = (tab: any) => {
    if (activeType !== tab) {
      setActiveType(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])
  return (
    <div className="fontFamily">
      <Nav
        pills
        className="mb-0 d-flex align-items-center justify-content-center "
      >
        <Col xs="12" md="5" lg="2" className="my-1 mx-1">
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#FFAB00',
              backgroundColor: active === 1 ? '#FFAB00' : 'inherit',
            }}
            className=" py-1  border-2  rounded"
            active={active === 1}
            onClick={() => {
              toggleTab(1)
            }}
          >
            <span className="fw-bold">{t('Sms Kredisi Satın Al')}</span>
          </NavLink>
        </Col>

        <Col xs="12" md="5" lg="2" className="my-1 mx-1">
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#25D366',
              backgroundColor: active === 2 ? '#25D366' : 'inherit',
            }}
            className="border- py-1  border-2  rounded"
            active={active === 2}
            onClick={() => {
              toggleTab(2)
            }}
          >
            <span className="fw-bold">{t('Wp Kredisi Satın Al')}</span>
          </NavLink>
        </Col>
        <Col xs="12" md="5" lg="2" className="my-1 mx-1">
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#25D366',
              backgroundColor: active === 4 ? '#25D366' : 'inherit',
            }}
            className="border- py-1  border-2  rounded"
            active={active === 4}
            onClick={() => {
              toggleTab(4)
            }}
          >
            <span className="fw-bold">{t('Banka Hesapları')}</span>
          </NavLink>
        </Col>
        <Col xs="12" md="5" lg="2" className="my-1 mx-1">
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#25D366',
              backgroundColor: active === 3 ? '#25D366' : 'inherit',
            }}
            className="border- py-1  border-2  rounded"
            active={active === 3}
            onClick={() => {
              toggleTab(3)
            }}
          >
            <span className="fw-bold">{t('Ödeme Geçmişi')}</span>
          </NavLink>
        </Col>
      </Nav>

      <TabContent
        style={{ display: activeType === 2 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <SmsPackages />
        </TabPane>
        <TabPane tabId={2}>
          <WpPackages />
        </TabPane>
        <TabPane tabId={3}>
          <PaymentHistory />
        </TabPane>
        <TabPane tabId={4}>
          <BankAccount />
        </TabPane>
      </TabContent>
    </div>
  )
}

export default index
