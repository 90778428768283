const Footer = () => {
  return (
    <div
      style={{
        marginTop: 10,
      }}
    >
      COPYRIGHT © {new Date().getFullYear()}{' '}
      <a
        href="https://vatansoft.net/"
        target="_blank"
        rel="noopener noreferrer"
      >
        VATANSOFT
      </a>
      <span className="d-none d-sm-inline-block">, All rights Reserved</span>
    </div>
  )
}

export default Footer
