import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { SiMicrosoftexcel } from 'react-icons/si'
import { useFormik } from 'formik'
import * as yup from 'yup'
import './index.css'
import { useTranslation } from 'react-i18next'
import ClassGroup from './components/classGroup/index'
import Numbers from './components/phoneNumbers'
import SchoolNumbers from './components/schoolNumbers'
import SpecialExcel from './components/specialExcel'

import WpPhone from './components/whatsAppPages/wpPhone'
import SmsPhone from './components/phone'
import WpClassGroup from './components/whatsAppPages/wpClassGroup'
import WpSchoolNumbers from './components/whatsAppPages/wpSchoolNumbers'
import WpPhoneNumbers from './components/whatsAppPages/wpPhoneNumbers'
import WpSpecialExcel from './components/whatsAppPages/wpSpecialExcel'

import { FaWhatsapp } from 'react-icons/fa'
import Guides from 'src/views/components/guides'

const index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const toggleTabType = (tab: any) => {
    if (activeType !== tab) {
      setActiveType(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])

  return (
    <div>
      <Nav
        style={{ width: '100%', backgroundColor: '#FFFFFF' }}
        pills
        className="mb-0 d-flex align-items-center justify-content-start "
      >
        <NavItem
          style={{
            width: '19.4%',
          }}
        >
          <NavLink
            style={{
              color: active === 1 ? 'white' : '#1F59AA',
              borderColor: active === 1 ? '#1F59AA' : '#1F59AA',
              backgroundColor: active === 1 ? '#1F59AA' : 'inherit',
            }}
            className=" border-2  rounded "
            active={active === 1}
            onClick={() => {
              toggleTab(1)
            }}
          >
            <span className="fw-bold">{t('Sınıf / Grup')}</span>
          </NavLink>
        </NavItem>

        <NavItem
          className="mx-1"
          style={{
            width: '19.4%',
          }}
        >
          <NavLink
            style={{
              color: active === 3 ? 'white' : '#1F59AA',
              borderColor: active === 3 ? '#1F59AA' : '#1F59AA',
              backgroundColor: active === 3 ? '#1F59AA' : 'inherit',
            }}
            className="border-2  rounded"
            active={active === 3}
            onClick={() => {
              toggleTab(3)
            }}
          >
            <span className="fw-bold">{t('Okul No')}</span>
          </NavLink>
        </NavItem>
        <NavItem
          className="m"
          style={{
            width: '19.4%',
          }}
        >
          <NavLink
            style={{
              color: active === 2 ? 'white' : '#1F59AA',
              borderColor: active === 2 ? '#1F59AA' : '#1F59AA',
              backgroundColor: active === 2 ? '#1F59AA' : 'inherit',
            }}
            className=" border-2  rounded"
            active={active === 2}
            onClick={() => {
              toggleTab(2)
            }}
          >
            <span className="fw-bold">{t('Telefon No')}</span>
          </NavLink>
        </NavItem>
        <NavItem
          className="mx-1"
          style={{
            width: '19.4%',
          }}
        >
          <NavLink
            style={{
              color: active === 4 ? 'white' : '#1F59AA',
              borderColor: active === 4 ? '#1F59AA' : '#1F59AA',
              backgroundColor: active === 4 ? '#1F59AA' : 'inherit',
            }}
            className=" border-2  rounded"
            active={active === 4}
            onClick={() => {
              toggleTab(4)
            }}
          >
            <span className="fw-bold">{t('Özel Excel ile Gönder')}</span>
          </NavLink>
        </NavItem>
      </Nav>
      <Nav
        pills
        className="m d-flex align-items-center mt-1 justify-content-start  "
      >
        <NavItem
          style={{
            width: '19.4%',
          }}
        >
          <NavLink
            style={{
              height: '40px',
              backgroundColor: activeType === 1 ? '#FF9F43' : 'inherit',
              color: activeType === 1 ? '#F3F2F7' : '#FF9F43',
              borderColor: '#FF9F43',
            }}
            className="  border-2  rounded"
            active={activeType === 1}
            onClick={() => {
              toggleTabType(1)
            }}
          >
            <Send className="font-medium-3 me-50" />
            <span className="fw-bold">{t('SMS')}</span>
          </NavLink>
        </NavItem>
        <NavItem
          className="mx-1"
          style={{
            width: '19.4%',
          }}
        >
          <NavLink
            style={{
              height: '40px',
              backgroundColor: activeType === 2 ? '#25D366' : 'inherit',
              color: activeType === 2 ? '#F3F2F7' : '#25D366',
              borderColor: '#25D366',
            }}
            className=" border-2  rounded"
            active={activeType === 2}
            onClick={() => {
              toggleTabType(2)
            }}
          >
            <FaWhatsapp className="font-medium-3 me-50" />
            <span className="fw-bold">{t('Whatsapp')}</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        style={{ display: activeType === 2 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <>
            <ClassGroup
              active={active}
              activeType={activeType}
              formik={formik}
            />
          </>
        </TabPane>
        <TabPane tabId={2}>
          <div>
            <Numbers active={active} activeType={activeType} formik={formik} />
          </div>
        </TabPane>
        <TabPane tabId={3}>
          <div>
            <SchoolNumbers
              active={active}
              activeType={activeType}
              formik={formik}
            />
          </div>
        </TabPane>
        <TabPane tabId={4}>
          <div>
            <SpecialExcel
              active={active}
              activeType={activeType}
              formik={formik}
            />
          </div>
        </TabPane>
      </TabContent>
      <TabContent
        style={{ display: activeType === 1 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <>
            <WpClassGroup
              active={active}
              activeType={activeType}
              formik={formik}
            />
          </>
        </TabPane>
        <TabPane tabId={3}>
          <div>
            <WpSchoolNumbers
              active={active}
              activeType={activeType}
              formik={formik}
            />
          </div>
        </TabPane>
        <TabPane tabId={2}>
          <div>
            <WpPhoneNumbers
              active={active}
              activeType={activeType}
              formik={formik}
            />
          </div>
        </TabPane>
        <TabPane tabId={4}>
          <div>
            <WpSpecialExcel
              active={active}
              activeType={activeType}
              formik={formik}
            />
          </div>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default index
