import { Dispatch, SetStateAction, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Form from 'react-bootstrap/esm/Form'
import CustomModal from 'src/views/components/customModal/CustomModal'

interface Props {
  modal: boolean
  setModal: Dispatch<SetStateAction<boolean>>
}

const AccountModal = (props: Props) => {
  return (
    <CustomModal modal={props.modal} setModal={props.setModal}>
      <div className="px-3 py-2">
        <h1 className="m-0 p-2 text-center text-black" style={{ fontSize: 25 }}>
          Hesap Bilgilerim
        </h1>
        <Form className="p-2">
          <div className="d-flex w-100 ">
            <div className="d-flex flex-column w-100 me-1">
              <Form.Group className="mb-1">
                <Form.Label>Kurum Kodu</Form.Label>
                <Form.Control
                  value="Kurum kodu eklemek için temsilcinizle irtibata geçin"
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Kurum Adı</Form.Label>
                <Form.Control value={'Test'} disabled />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Telefon Numarası</Form.Label>
                <Form.Control value={'05555555555'} disabled />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Yetkili Adı</Form.Label>
                <Form.Control value={'Test'} disabled />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>E-Posta adresiniz</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="E-Posta"
                  value={'test@gmail.com'}
                  disabled
                />
              </Form.Group>
            </div>
            <div className="d-flex flex-column w-100 ">
              <Form.Group className="mb-1">
                <Form.Label>Fatura Başlık</Form.Label>
                <Form.Control placeholder="Fatura Başlık" />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Vergi Daire No.</Form.Label>
                <Form.Control placeholder="Vergi daire numarası" />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Vergi Daire</Form.Label>
                <Form.Control placeholder="Vergi Dairesi" />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>İl</Form.Label>
                <Form.Select>
                  <option>İl 1</option>
                  <option>İl 2</option>
                  <option>İl 3</option>
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label>İlçe</Form.Label>
                <Form.Select>
                  <option>İlçe 1</option>
                  <option>İlçe 2</option>
                  <option>İlçe 3</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          <Form.Group className="mb-2">
            <Form.Label>Adres</Form.Label>
            <Form.Control as="textarea" placeholder="Adres" />
          </Form.Group>
          <Button className="w-100" variant="primary" type="submit">
            Bilgilerimi Güncelle
          </Button>
        </Form>
      </div>
    </CustomModal>
  )
}

export default AccountModal
