import { Dispatch, SetStateAction } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Form from 'react-bootstrap/esm/Form'
import CustomModal from 'src/views/components/customModal/CustomModal'
import { ModalHeader } from 'reactstrap'

interface Props {
  modal: boolean
  setModal: Dispatch<SetStateAction<boolean>>
}

const SmsModal = (props: Props) => {
  return (
    <CustomModal modal={props.modal} setModal={props.setModal}>
      <div className="px-3 py-2">
        <h1 className="mt-1 text-center text-black">SMS Ayarları</h1>
        <Form className="p-2">
          <Row className="mb-2">
            <Form.Group as={Col} className="mb-1">
              <Form.Label>Varsayılan Mesaj Tipi Seçimi</Form.Label>
              <Form.Select>
                <option>Mesaj Tipi 1</option>
                <option>Mesaj Tipi 2</option>
                <option>Mesaj Tipi 3</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} className="mb-1">
              <Form.Label>Varsayılan Gönderici Adı Seçimi</Form.Label>
              <Form.Select>
                <option>Test 1</option>
                <option>Test 2</option>
                <option>Test 3</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-1 text-center">
              <Form.Label>
                Gönderilen Veliyi Kontrol Et Yoksa Diğer Veliye Gönder
              </Form.Label>
              <Form.Check type="switch" />
            </Form.Group>
            <Form.Group as={Col} className="mb-1 text-center">
              <Form.Label>
                Toplu Gönderimlerde Hesap Sahibine Bilgi SMS Gönder
              </Form.Label>
              <Form.Check type="switch" />
            </Form.Group>
            <Form.Group as={Col} className="mb-1 text-center">
              <Form.Label>Okundu Bilgisi Gönder</Form.Label>
              <Form.Check type="switch" />
            </Form.Group>
          </Row>
        </Form>
        <Button className="m-1 w-100">Bilgilerimi Güncelle</Button>
      </div>
    </CustomModal>
  )
}

export default SmsModal
