import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { SiMicrosoftexcel } from 'react-icons/si'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import EschoolExamH from './sms/eSchoolExamH'
import EschoolExamM from './sms/eSchoolExamM'
import PracticeExamH from './sms/practiceExamH'
import PracticeExamM from './sms/practiceExamM'
import WpEschoolExamH from './wp/eSchoolExamH'
import WpEschoolExamM from './wp/eSchoolExamM'
import WpPracticeExamH from './wp/practiceExamH'
import WpPracticeExamM from './wp/practiceExamM'

import { FaWhatsapp } from 'react-icons/fa'

const index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const toggleTabType = (tab: any) => {
    if (activeType !== tab) {
      setActiveType(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])
  return (
    <div className="fontFamily">
      <Nav
        style={{ width: '100%', backgroundColor: '#FFFFFF' }}
        pills
        className="flex-wrap  "
      >
        <NavItem className="" style={{ width: '20.5%', marginRight: 'px' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              color: active === 1 ? 'white' : '#1F59AA',
              borderColor: '#1F59AA',
              backgroundColor: active === 1 ? '#1F59AA' : 'inherit',
            }}
            className="  border-2  rounded"
            active={active === 1}
            onClick={() => {
              toggleTab(1)
            }}
          >
            <span className="fw-bold">{t('Deneme Sınavı Lise')}</span>
          </NavLink>
        </NavItem>

        <NavItem className="mx-1" style={{ width: '20.5%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              color: active === 2 ? 'white' : '#1F59AA',
              borderColor: '#1F59AA',
              backgroundColor: active === 2 ? '#1F59AA' : 'inherit',
            }}
            className="border-  border-2  rounded"
            active={active === 2}
            onClick={() => {
              toggleTab(2)
            }}
          >
            <span className="fw-bold">{t('Deneme Sınavı Ortaokul')}</span>
          </NavLink>
        </NavItem>
        <NavItem className="mx-1" style={{ width: '20.5%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              color: active === 3 ? 'white' : '#1F59AA',
              borderColor: '#1F59AA',
              backgroundColor: active === 3 ? '#1F59AA' : 'inherit',
            }}
            className="border-  border-2  rounded"
            active={active === 3}
            onClick={() => {
              toggleTab(3)
            }}
          >
            <span className="fw-bold">{t('E-Okul Sınav Sonucu lise ')}</span>
          </NavLink>
        </NavItem>
        <NavItem className="mx-1" style={{ width: '20%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              color: active === 4 ? 'white' : '#1F59AA',
              borderColor: '#1F59AA',
              backgroundColor: active === 4 ? '#1F59AA' : 'inherit',
            }}
            className="border-  border-2  rounded"
            active={active === 4}
            onClick={() => {
              toggleTab(4)
            }}
          >
            <span className="fw-bold">{t('E-Okul Sınav Sonucu   ')}</span>
          </NavLink>
        </NavItem>
      </Nav>
      <Nav
        pills
        className="m d-flex align-items-center mt-1 justify-content-start  "
      >
        <NavItem
          style={{
            width: '20.5%',
          }}
        >
          <NavLink
            style={{
              backgroundColor: activeType === 1 ? '#FF9F43' : 'inherit',
              color: activeType === 1 ? '#F3F2F7' : '#FF9F43',
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#FF9F43',
            }}
            className="  border-2  rounded"
            active={activeType === 1}
            onClick={() => {
              toggleTabType(1)
            }}
          >
            <Send className="font-medium-3 me-50" />
            <span className="fw-bold">{t('SMS')}</span>
          </NavLink>
        </NavItem>
        <NavItem
          className="mx-1"
          style={{
            width: '20.5%',
          }}
        >
          <NavLink
            style={{
              backgroundColor: activeType === 2 ? '#25D366' : 'inherit',
              color: activeType === 2 ? '#F3F2F7' : '#25D366',
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#25D366',
            }}
            className=" border-2  rounded"
            active={activeType === 2}
            onClick={() => {
              toggleTabType(2)
            }}
          >
            <FaWhatsapp className="font-medium-3 me-50" />
            <span className="fw-bold">{t('Whatsapp')}</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent
        style={{ display: activeType === 2 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <PracticeExamH formik={formik} />
        </TabPane>
        <TabPane tabId={2}>
          <PracticeExamM formik={formik} />
        </TabPane>
        <TabPane tabId={3}>
          <EschoolExamH formik={formik} />
        </TabPane>
        <TabPane tabId={4}>
          <EschoolExamM formik={formik} />
        </TabPane>
      </TabContent>
      <TabContent
        style={{ display: activeType === 1 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <WpPracticeExamH formik={formik} />
        </TabPane>
        <TabPane tabId={2}>
          <WpPracticeExamM formik={formik} />
        </TabPane>
        <TabPane tabId={3}>
          <WpEschoolExamH formik={formik} />
        </TabPane>
        <TabPane tabId={4}>
          <WpEschoolExamM formik={formik} />
        </TabPane>
      </TabContent>
    </div>
  )
}

export default index
