import {
  Col,
  Input,
  Row,
} from 'reactstrap'
import Accordion from 'react-bootstrap/Accordion'
import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import CustomTable from 'src/views/components/customTable/customTable'
const index = (props: any) => {
  const [count, setCount] = useState(0)
  const [open, setOpen] = useState('0')
  const [defaults, setDefault] = useState('0')
  const [childrens, setChildrens] = useState([
    {
      schoolNumber: '1539',
      class: '8/D',
      name: 'Muhammetcan Akcura',
      selected: true,
    },
    {
      schoolNumber: '1538',
      class: '8/C',
      name: 'Muhammetcan Akc',
      selected: true,
    },
    { schoolNumber: '1537', class: '8/F', name: 'Can Ak', selected: true },
    {
      schoolNumber: '1536',
      class: '8/G',
      name: 'Muhammetcan Ak',
      selected: true,
    },
    {
      schoolNumber: '1535',
      class: '8/A',
      name: 'Muhammet Akcura',
      selected: true,
    },
    { schoolNumber: '1534', class: '8/B', name: 'Can Akcura', selected: true },
    { schoolNumber: '1533', class: '8/B', name: 'Can Akcura4', selected: true },
    { schoolNumber: '1532', class: '8/B', name: 'Can Akcura2', selected: true },
    { schoolNumber: '1531', class: '8/B', name: 'Can Akcura1', selected: true },
    { schoolNumber: '1534', class: '8/B', name: 'Can Akcura5', selected: true },
    { schoolNumber: '1534', class: '8/B', name: 'Can Akcura6', selected: true },
  ])
  const { t }: any = useTranslation()
  const formik = useFormik({
    initialValues: {
      message: '',
      phone: '',
    },
    validationSchema: Yup.object({
      message: Yup.string().required(t('Bu alan zorunludur.')),
      phone: Yup.string().required(t('Bu alan zorunludur.')),
    }),
    onSubmit: (values) => {},
  })
  const columns = [
    {
      name: 'Seç',
      sortable: true,
      selector: (row: { selected: string }) => row.selected,
    },
    {
      name: 'Telefon Numarası',
      sortable: true,
      selector: (row: { schoolNumber: string }) => row.schoolNumber,
    },
    
  ]
  const selected = (index: number) => {
    const array = [...childrens]
    array[index].selected = !array[index].selected
    setChildrens(array)
  }
  const transfer = () => {
    const array = formik.values.message
      .split('\n')
      .filter((item) => item !== '')
      .join(',')
      .split(',')
      .map((element) => element.trim())
      .map((element) => element.replace(/\s/g, ''))
    const setarray = [...new Set(array)]
    setCount(setarray.length)
    setOpen('1')
  }
  const data = childrens.map((item, index) => {
    return {
      schoolNumber: item.schoolNumber,
      class: item.class,
      name: item.name,
      selected: (
        <>
          <Input
            type="checkbox"
            onChange={() => selected(index)}
            checked={item.selected}
          />
        </>
      ),
    }
  })
  useEffect(() => {
    if (open === '1') {
      setOpen('0')
      setDefault('1')
    }
  }, [open])
  return (
    <div style={{ height: '100%' }}>
      <Row className="">
        <Col
          md="12"
          className=" "
        >
          <Accordion
            className="pb-1"
            defaultActiveKey={defaults === '1' ? undefined : '0'}
            key={open}
            flush
          >
            <Accordion.Item eventKey={'0'}>
              <Accordion.Header>Telefon Numarası Ekle</Accordion.Header>
              <Accordion.Body>
                <Row className="">
                  <Col md="12" className="my-">
                    <Col className="my-" md="12">
                      <Input
                        style={{ height: '105px' }}
                        type="textarea"
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        placeholder={
                          'Okul numaralarını aralarına virgül koyarak, yada alt alta yazabilirsiniz'
                        }
                      ></Input>
                    </Col>
                    <Col
                      md="12"
                      className=" m-1 d-flex justify-content-center "
                    >
                      <div
                        onClick={() => {
                          transfer()
                        
                        }}
                        style={{ width: '65%', backgroundColor: '#1F59AA' }}
                        className=" btn  d-flex btn-block  align-items-center justify-content-center text-white text-center"
                      >
                        Telefonları Aktar
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        {defaults === '1' ? (
          <Col className="">
            <Col className="">
              <CustomTable fixedHeaderScrollHeight={"400px"} data={data} columns={columns} />
          </Col>
          </Col>
        ) : (
          ''
        )}
      </Row>
   
    </div>
  )
}

export default index
