import { useFormik } from 'formik'
import * as yup from 'yup'
import { Col, Input, Label, Row } from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomDate from 'src/views/components/customDate/CustomDate'
const Index = () => {
  const formik = useFormik({
    initialValues: {
      saveType: { id: '1', name: 'Öğrenci' },
      no: '',
      name: '',
      tel: '',
      parent: '',
      twondparent: '',
      twondparentTel: '',
      studentPhoneNumber: '',
      birthday: '',
    },
    validationSchema: yup.object({
      no: yup.string().required('Öğrenci Numarası zorunlu'),
      name: yup.string().required('Adı Soyadı zorunlu'),
      tel: yup.string().required('Telefon No zorunlu'),
    }),
    onSubmit: async (values) => {},
  })

  const saveType = [
    { id: '1', name: 'Öğrenci' },
    { id: '2', name: 'Öğretmen' },
    { id: '3', name: 'Personel' },
  ]
  const options = saveType.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
    }
  })

  return (
    <div style={{ backgroundColor: '#FFFFFF' }} className="mt-2 px-4 py-3 ">
      <Row className="my-1">
        <Col md="12">
          <h3>El İle Tek Kişi Kayıt</h3>
        </Col>
      </Row>

      <Row className="d-flex align-items-center justify-content-center my-1">
        <Col md="6">
          <Label>Kayıt Türü</Label>
          <SelectOptions
            style={{ borderColor: '#1F59AA' }}
            value={
              options.find(
                (item: any) => item?.name === formik.values.saveType.name
              ) ?? null
            }
            onChange={(e: any) => {
              formik.setFieldValue('saveType.id', e.id)
              formik.setFieldValue('saveType.name', e.name)
            }}
            options={options}
          />
        </Col>
        <Col md="6">
          <Label>Grup</Label>
          <SelectOptions />
        </Col>
        <Col>
          <b>
            {
              " Öğretmen kaydederken 'ÖĞRETMEN' servis şoförleri ve hizmet personeli kaydederken 'PERSONEL' seçiniz"
            }
          </b>
        </Col>
      </Row>
      {formik.values.saveType.id === '1' ? (
        <Row>
          <Col md="6" className="mb-1">
            <Label>Öğrenci Numarası</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Öğrencinin Okul Numarası"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Ad Soyad</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Kayıt Adı Ve Soyadı (Zorunlu)"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Veli Ad Soyad</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Öğrencinin Velisi"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Telefon Numarası</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Veli Telefonu (Zorunlu)"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>2. Veli Ad Soyad</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Öğrencinin 2. Velisi"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>2. Veli Telefon Numarası</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="2. Veli Telefonu"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Öğrenci Telefon Numarası</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Öğrenci Telefon No"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Doğum Tarihi</Label>
            <CustomDate onlyDate placeholder="Gün/Ay/Yıl" />
          </Col>
        </Row>
      ) : formik.values.saveType.id === '2' ? (
        <Row>
          <Col md="6" className="mb-1">
            <Label>Telefon Numarası</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Telefon Numarası"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Ad Soyad</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Kayıt Adı Ve Soyadı "
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Ders 1 </Label>
            <Input style={{ borderColor: '#1F59AA' }} placeholder="Ders 1" />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Ders 2</Label>
            <Input style={{ borderColor: '#1F59AA' }} placeholder="Ders 2" />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Doğum Tarihi</Label>
            <CustomDate onlyDate placeholder="Gün/Ay/Yıl" />
          </Col>
          <Col
            className="my-1 d-flex justify-content-start align-items-center"
            md="12"
          >
            <Input type="checkbox" />
            <div className="d-flex align-items-end mx-1">
              Öğretmeni aynı zamanda alt kullanıcı olarak ekle.
            </div>
          </Col>
          <Col className="my-2">
            Sisteme girip devamsızlık mesajları, sınıflara mesaj gönderebilecek
            izinleri Ayarlar/Alt Kullanıcı ayarlarından değiştirebilirsiniz.
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md="6" className="mb-1">
            <Label>Telefon Numarası</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Telefon Numarası"
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Ad Soyad</Label>
            <Input
              style={{ borderColor: '#1F59AA' }}
              placeholder="Kayıt Adı Ve Soyadı "
            />
          </Col>
          <Col className="mb-1" md="6">
            <Label>Doğum Tarihi </Label>
            <CustomDate placeholder="Gün/Ay/Yıl" />
          </Col>
        </Row>
      )}
      <Row
        style={{ width: '100%' }}
        className="d-flex justify-content-center my-1"
      >
        <div
          style={{ backgroundColor: '#1F59AA', width: '50%' }}
          className="btn text-white text-center"
        >
          {' '}
          Rehbere Kaydet{' '}
        </div>
      </Row>
    </div>
  )
}

export default Index
