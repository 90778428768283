import React from 'react'
import { Col } from 'reactstrap'
import phone from '../../../../../../../assets/images/main/phone.png'
import { ArrowLeft } from 'react-feather'
import { IoIosCall } from 'react-icons/io'
import { ImAttachment } from 'react-icons/im'
import parse from 'html-react-parser'
import { BsThreeDotsVertical } from 'react-icons/bs'
import './index.scss'
const index = (props: any) => {
    return (
        <>
            <Col style={{position:"relative",right:"165px" , bottom:"40px" }}
                xs='12' sm='4'
            >
                <div className="phone-container-wp">
                    <div>
                        <img width={550} src={phone} />
                    </div>
                    <div className="phone-wp">
                        <div className="user-bar-wp d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div>
                                    {' '}
                                    <ArrowLeft />
                                </div>
                                <div> online</div>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <div>
                                    <IoIosCall />
                                </div>
                                <div>
                                    <ImAttachment />
                                </div>
                                <div>
                                    <BsThreeDotsVertical />
                                </div>
                            </div>
                        </div>
                        <div className="message-wp">
                            <div className="sent-wp">
                                <div>
                                    {props.formik.values.files.type === 2 && (
                                        <img
                                            className="sent-image-wp"
                                            src={URL.createObjectURL(
                                                props.formik.values.files.file as any
                                            )}
                                        />
                                    )}
                                    {props.formik.values.files.type === 3 && (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img
                                                className=""
                                                width={100}
                                                height={80}
                                                src={`https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Icon_pdf_file.svg/898px-Icon_pdf_file.svg.png`}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className="">{parse(props.formik.values.message)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}

export default index