import { useState } from 'react'
import { Plus } from 'react-feather'
import { SiMicrosoftexcel } from 'react-icons/si'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomTable from 'src/views/components/customTable/customTable'

const index = () => {
  const [modal, setModal] = useState(false)

  const columns = [
    {
      name: 'Tarih',
      sortable: true,
      selector: (row: { date: any }) => row.date,
    },
    {
      name: 'Gönderici Adı',
      sortable: true,
      selector: (row: { senderName: any }) => row.senderName,
    },
    {
      name: 'Durum',
      sortable: true,
      selector: (row: { status: any }) => row.status,
    },

  ]
  const subUser = [
    {
      senderName: 'Muhammetcan',date:"12/03/2024",status:0
    },
    {
      senderName: 'Muhammetcan2',date:"12/04/2024",status:0
    },
    {
      senderName: 'Muhammetcan1',date:"12/05/2024",status:1
    },
  ]
  const data = subUser.map((item: any) => {
    return {
      date:item.date,
      senderName: item.senderName,
      status: (
        <>
          {
            item.status === 0 ? 
            <div className="btn btn-success">Onaylandı</div> : 
            <div className="btn btn-danger">Onaylanmadı</div>
          }
        </>
      ),
    }
  })
  return (
    <Row>
      <Col md="12">
        <CustomTable columns={columns} data={data}>
          <Button
            onClick={() => {
              setModal(true)
            }}
            className="btn btn-success d-flex justify-content-center align-items-center"
          >
            <Plus />
            <span>Yeni Gönderici Adı Kaydı</span>
          </Button>
        </CustomTable>
      </Col>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        >
          <h3>Gönderici Adı Kaydı</h3>
        </ModalHeader>
        <ModalBody>
          <Row md="6" className="d-flex justify-content-center">
            <Col className="mb-1" md="8">
              <Label>Yeni Gönderici Adı</Label>
              <Input placeholder="Yeni Gönderici Adı" />
            </Col>
            <Col className="mb-1" md="8">
              <Label>Açıklama</Label>
              <Input placeholder="Açıklama" />
            </Col>
            <Col className="d-flex justify-content-center" md="8">
              <Button
                onClick={() => {
                  setModal(false)
                }}
                className="btn-block btn-success"
              >
                Kaydet
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Row>
  )
}

export default index
