import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Col,
  Input,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import { RqErrorMessage } from '../../../../../helpers/RequiredMessage'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import SelectOptions from '../../../../components/Select/SelectOptions'
import moment from 'moment'
import axios from 'axios'
import CustomDate from 'src/views/components/customDate/CustomDate'
import { useFormik } from 'formik'

const index = () => {
  const { t }: any = useTranslation()
  const [time, setTime] = useState(0)
  const [modal, setModal] = useState(false)
  const Options = [
    { id: 1, name: t('QNB FinansBank') },
    { id: 2, name: t('Ziraat Bankası') },
    { id: 3, name: t('Garanti Bankası') },
    { id: 4, name: t('İş Bankası') },
  ]
  const accounts = [
    {
      accountId: 0,
      headerImg: '/image/QNB.04e3099d.svg',
      accountHeader: 'QNB Finansbank',
      accountName: 'Ersin Hacıoğlu',
      IBAN: '330011100000000037321440',
    },
    {
      accountId: 1,
      headerImg: '/image/Ziraat.702e8f6d.svg',
      accountHeader: 'Ziraat Bankası',
      accountName: 'Ersin Hacıoğlu',
      IBAN: '610001001945684381925002',
    },
    {
      accountId: 2,
      headerImg: '/image/Garanti.0999e0d0.svg',
      accountHeader: 'Garanti Bankası',
      accountName: 'Ersin Hacıoğlu',
      IBAN: '930006200072300006638768',
    },
    {
      accountId: 3,
      headerImg: '/image/is-bankasi.56eeaa7c.svg',
      accountHeader: 'İş Bankası',
      accountName: 'Ersin Hacıoğlu',
      IBAN: '910006400000112450241942',
    },
  ]

  const validationSchema = Yup.object({
    account: Yup.object().required(t('Bu alan Zorunludur.')),
    price: Yup.number()
      .integer()
      .min(1, t('positiveNumber'))
      .required(t('Bu alan zorunludur*')),
    noticeDate: Yup.date().required(t('Bu alan Zorunludur*')),
  })

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  }: any = useFormik({
    initialValues: {
      account: { id: 1, name: t('Hesap Seçiniz') },
      price: 10,
      noticeDate: new Date(),
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.account.name === t('Hesap Seçiniz')) {
        toast.error(t('Lütfen Hesap Seçiniz !'))
      } else {
        setModal(true)
      }
    },
  })
  const sendPaymentInfo = () => {
    if (time === 0) {
      axios
        .post(`/transfer/`, {
          amount: values.price,
          bank_name: values.account?.name,
          date: moment(values.noticeDate).format('DD.MM.YYYY'),
        })
        .then(() => {
          setTimeout(() => {
            setTime(0)
          }, 10000)
          setTime(1)
        })
        .catch((error) => {
          toast.error(error.response.data.description)
        })
    } else {
      toast.error(t('Her 1 dakikada sadece 1 ödeme bildirimi yapılabilir.'))
    }

    resetForm()
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Row className="d-flex">
            <Col sm="12" md="12" lg="8" xs="12">
              <Row>
                {accounts.map((account) => (
                  <Col lg="6" sm="12">
                    <Card
                      color="white"
                      key={account.accountId}
                      className="p-2 "
                      style={{ border: '1px solid #bbb' }}
                    >
                      <CardHeader
                        className="justify-content-center align-self-center"
                        style={{
                          width: '100%',
                          height: '10em',
                        }}
                      >
                        <img
                          style={{
                            width: '300px',
                          }}
                          src={account.headerImg}
                          alt={account.accountHeader}
                        />
                      </CardHeader>
                      <CardBody className="text-center">
                        <h1 className="mt-1" style={{ color: 'black' }}>
                          {account.accountHeader}
                        </h1>
                        <CardText style={{ color: 'black', fontSize: '16px' }}>
                          {account.accountName}
                        </CardText>
                        <CardText style={{ color: 'black' }}>
                          <strong>IBAN</strong>: {account.IBAN}
                        </CardText>
                        <CardText
                          style={{
                            cursor: 'pointer',
                            color: 'black',
                            fontSize: '14',
                            fontWeight: 'bold',
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(account.IBAN)
                            toast.success('Iban Copied')
                          }}
                        >
                          {t('Kopyala')} IBAN
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md="12" lg="4" sm="12" xs="12">
              <Row tag="form" className="d-flex">
                <Col xs="12">
                  <SelectOptions
                    value={values.account}
                    name="account"
                    type="select"
                    options={Options}
                    placeholder={t('Hesap Seçiniz')}
                  />
                  {RqErrorMessage(errors.account, touched.account)}
                </Col>
                <Col xs="12">
                  <Input
                    placeholder="Tutar"
                    invalid={errors.price && touched.price}
                  />
                  {RqErrorMessage(errors.price, touched.price)}
                </Col>
                <Col xs="12" className="mt-1">
                  <CustomDate
                    name="noticeDate"
                    onlyDate
                    onChange={(e: any) => {
                      setFieldValue('noticeDate', e[0])
                    }}
                    value={values.noticeDate}
                  />
                  {RqErrorMessage(errors.noticeDate, touched.noticeDate)}
                </Col>
                <Col xs="12" className="mt-1">
                  <Button type="submit" color="primary">
                    {t('Ödeme Bildirimi Yap')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => {
            resetForm()
            setModal(!modal)
          }}
        ></ModalHeader>
        <ModalBody className="text-center">
          <div className="text-center mb-2">
            <h1 className="mb-1">{t('Ödeme bildirimi')}</h1>
          </div>
          <Row>
            <Col>
              <h6 className="mb-2">{t('Bu işlem geri alınamaz.')}</h6>

              <span>
                {t(
                  'Ödeme bildirimi yapmanız durumunda müşteri temsilcilerimiz bilgilendirilecek ve üyeliğinize yönelik işlemler yapılacaktır. en kısa sürede gerçekleşir. tamamlamak istiyor musun ödemeniz için bildirim süreci? Ödemeniz yapılmadıysa henüz tamamlanmadı, lütfen iptale tıklayın.'
                )}
              </span>
            </Col>
          </Row>

          <Row className="justify-content-center m-2">
            <Col md="3">
              <Button
                onClick={() => {
                  setModal(false)
                  // sendPaymentInfo()
                }}
                size="lg"
                color="primary"
              >
                {t('Onayla')}
              </Button>
            </Col>
            <Col md="3">
              <Button
                onClick={() => {
                  setModal(false)
                  resetForm()
                }}
                size="lg"
              >
                {t('İptal')}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default index
