import { useState } from 'react'
import { Plus } from 'react-feather'
import { SiMicrosoftexcel } from 'react-icons/si'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomTable from 'src/views/components/customTable/customTable'

const index = () => {
  const [modal, setModal] = useState(false)
  const [excelModal, setExcelModal] = useState(false)
  const columns = [
    {
      name: 'Alt Kullanıcı',
      sortable: true,
      selector: (row: { subUser: any }) => row.subUser,
    },
    {
      name: (
        <div className="d-flex justify-content-around align-items-center">
          <Input type="checkbox"></Input>
          <span>Rehber İşlemleri</span>
        </div>
      ),
      sortable: true,
      selector: (row: { guide: any }) => row.guide,
    },
    {
      name: (
        <div className="d-flex justify-content-around align-items-center">
          <Input type="checkbox"></Input>
          <span>e-Yoklama SMS Gitsin</span>
        </div>
      ),
      sortable: true,
      selector: (row: { ePooling: any }) => row.ePooling,
    },
    {
      name: (
        <div className="d-flex justify-content-around align-items-center">
          <Input type="checkbox"></Input>
          <span>Whatsapp Gönderme</span>
        </div>
      ),
      sortable: true,
      selector: (row: { sendWp: any }) => row.sendWp,
    },
    {
      name: (
        <div className="d-flex justify-content-around align-items-center">
          <Input type="checkbox"></Input>
          <span>Sms Gönderme</span>
        </div>
      ),
      sortable: true,
      selector: (row: { sendSMS: any }) => row.sendSMS,
    },
  ]
  const subUser = [
    {
      subUser: 'Muhammetcan',
      guide: false,
      ePooling: false,
      sendWp: false,
      sendSMS: false,
    },
    {
      subUser: 'Muhammetcan',
      guide: false,
      ePooling: false,
      sendWp: false,
      sendSMS: false,
    },
    {
      subUser: 'Muhammetcan',
      guide: false,
      ePooling: false,
      sendWp: false,
      sendSMS: false,
    },
  ]
  const data = subUser.map((item: any) => {
    return {
      subUser: item.subUser,
      guide: (
        <>
          <Input type="checkbox"></Input>
        </>
      ),
      ePooling: (
        <>
          <Input type="checkbox"></Input>
        </>
      ),
      sendWp: (
        <>
          <Input type="checkbox"></Input>
        </>
      ),
      sendSMS: (
        <>
          <Input type="checkbox"></Input>
        </>
      ),
    }
  })
  return (
    <Row>
      <Col
        md="12"
        className="d-flex justify-content-between align-items-center my-1"
      >
        <Button
          onClick={() => {
            setModal(true)
          }}
          className="btn btn-success d-flex justify-content-center align-items-center"
        >
          <Plus />
          <span>Yeni Alt Kullanıcı Kaydı</span>
        </Button>
        <Button
          onClick={() => setExcelModal(true)}
          className="btn btn-success d-flex justify-content-center align-items-center "
        >
          <SiMicrosoftexcel className="mx-1" size={'20px'} />
          Excel İle Toplu Kayıt
        </Button>
      </Col>
      <Col md="12">
        <CustomTable columns={columns} data={data}></CustomTable>
      </Col>
      <Col className="d-flex justify-content-center">
        <Button className="w-50 btn btn-success btn-block mb-2">
          Güncelle
        </Button>
      </Col>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        ></ModalHeader>
        <ModalBody className=" px-1  ">
          <Row>
            <Col className="mb-1" md="6">
              <Label>Alt Kullanıcı</Label>
              <SelectOptions />
            </Col>
            <Col className="mb-1" md="6">
              <Label>Ad Soyad</Label>
              <Input placeholder="Ad Soyad" />
            </Col>
            <Col className="mb-1" md="6">
              <Label>Girdiği Ders 1</Label>
              <Input placeholder="Girdiği Ders 1(Opsiyonel)" />
            </Col>
            <Col className="mb-1" md="6">
              <Label>Girdiği Ders 2</Label>
              <Input placeholder="Girdiği Ders 2(Opsiyonel)" />{' '}
            </Col>
            <Col className="mb-1" md="6">
              <Label>İmza</Label>
              <Input placeholder="Örn:Ahmet Demir Matematik Öğr." />
            </Col>
            <Col className="mb-1" md="6">
              <Label>Telefon</Label>
              <Input placeholder="5**********" />{' '}
            </Col>
            <Col className="mb-1" md="6">
              <Label>Kullanıcı Adı</Label>
              <Input placeholder="Kullanıcı Adı" />{' '}
            </Col>
            <Col className="mb-1" md="6">
              <Label>Kullanıcı Şifre</Label>
              <Input placeholder="Kullanıcı Şifre" />
            </Col>

            <Col className="mb-1" md="6">
              <Label>Email</Label>
              <Input placeholder="Email" />
            </Col>
            <Col className="mb-1" md="6">
              <Label>Whatsapp Mesaj Gönderme Yetkisi</Label>
              <SelectOptions />
            </Col>
            <Col
              className="mb-1 d-flex align-items-center justify-content-between"
              md="6"
            >
              <span>Krediyi Ortak Kullan:</span>
              <Input type="checkbox" />
            </Col>
            <Col
              className="mb-1 d-flex align-items-center justify-content-between"
              md="6"
            >
              <span>Gelişmiş Ayarları Göster:</span>
              <Input type="checkbox" />
            </Col>
            <Col
              className="mb-1 d-flex justify-content-center align-items-center"
              md="12"
            >
              <Input className="mx-1" type="checkbox" />
              <div className="d-flex align-items-end">
                Giriş bilgilerini sms olarak gönder
              </div>
            </Col>
            <Col className="my-1 d-flex justify-content-center">
              <Button>Kaydet</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={excelModal}
        toggle={() => setExcelModal(!excelModal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setExcelModal(!excelModal)}
        ></ModalHeader>
        <ModalBody className=" px-4 py-1  ">
          <Col>
            <h3>
              Rehberim/Excel ile Toplu Kayıt sayfasından öğretmenlerinizi
              rehbere kaydederken Öğretmeni aynı zamanda alt kullanıcı olarak
              ekle seçeneğini işaretleyiniz.
            </h3>
          </Col>
          <Col className="d-flex justify-content-center my-1">
            <img src="/image/Excel-Toplu-Kayıt.jpg" alt="" />
          </Col>
          <Col className="d-flex justify-content-center my-1 align-items-center">
            <Button className="btn btn-success">
              Toplu Kayıt Sayfasına Git
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    </Row>
  )
}

export default index
