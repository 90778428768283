import { useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { SiMicrosoftexcel } from 'react-icons/si'
import { NavLink } from 'react-router-dom'
import { Button, Col, Input, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import * as yup from 'yup'
import FieldOne from './filedOne'
import FieldTwo from './fieldTwo'
import SmsPhone from '../../../sendMessage/components/phone'
import { useFormik } from 'formik'



const Index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)

  const toggleTab = (tab: number) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {
    },
  })
  

  return (
    <Row className="justify-content-center">
    <Col  xs="12" sm="12" md="12" lg="12" xl="6" xxl="5" style={{borderRight:"2px solid #ccc"}} >
      <FieldOne />
    </Col>
    <Col  xs="12" sm="12" md="12" lg="12" xl="6" xxl="5" style={{borderRight:"2px solid #ccc"}} >
      <FieldTwo />
    </Col>
    <Col  xs="12" sm="12" md="12" lg="12" xl="12" xxl="2"  className='d-none d-xxl-block' >
      <SmsPhone formik={formik} />
    </Col>
  </Row>
  )
}

export default Index
