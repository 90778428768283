import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { SiMicrosoftexcel } from 'react-icons/si'
import { useFormik } from 'formik'
import * as yup from 'yup'
import '../sendMessage/index.css'
import { useTranslation } from 'react-i18next'
import ManuallyAddRecord from './manuallyAddRecord'
import AddRecordsWithExcel from './addRecordsWithExcel'
import ClassGroupOperations from './classGroupOperations'
import TransferFromGroupToGroup from './transferFromGroupToGroup'
const index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const toggleTabType = (tab: any) => {
    if (activeType !== tab) {
      setActiveType(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])

  return (
    <div>
      <Nav
        pills
        className="mb-0 d-flex align-items-center justify-content-center "
      >
        <Col xs="12" sm="6" md="5" lg="2" className="my-1 mx-1">
          <NavLink
            style={{
              borderColor: '#1F59AA',
              backgroundColor: active === 1 ? '#1F59AA' : 'inherit',
              color: active === 1 ? 'white' : '#1F59AA',
            }}
            className=" border-2  rounded "
            active={active === 1}
            onClick={() => {
              toggleTab(1)
            }}
          >
            <span className="fw-bold">{t('El İle Kayıt Ekleme')}</span>
          </NavLink>
        </Col>

        <Col xs="12" sm="6" md="5" lg="2" className="mx-1">
          <NavLink
            style={{
              borderColor: '#1F59AA',
              backgroundColor: active === 2 ? '#1F59AA' : 'inherit',
              color: active === 2 ? 'white' : '#1F59AA',
            }}
            className="border-2  rounded"
            active={active === 2}
            onClick={() => {
              toggleTab(2)
            }}
          >
            <span className="fw-bold">{t('Excel İle Kayıt Ekleme')}</span>
          </NavLink>
        </Col>
        <Col xs="12" sm="6" md="5" lg="2" className="my-1 mx-1">
          <NavLink
            style={{
              borderColor: '#1F59AA',
              backgroundColor: active === 3 ? '#1F59AA' : 'inherit',
              color: active === 3 ? 'white' : '#1F59AA',
            }}
            className=" border-2  rounded"
            active={active === 3}
            onClick={() => {
              toggleTab(3)
            }}
          >
            <span className="fw-bold">{t('Sınıf Grup İşlemleri')}</span>
          </NavLink>
        </Col>
        <Col xs="12" sm="6" md="5" lg="2" className="mx-1">
          <NavLink
            style={{
              borderColor: '#1F59AA',
              backgroundColor: active === 4 ? '#1F59AA' : 'inherit',
              color: active === 4 ? 'white' : '#1F59AA',
            }}
            className=" border-2  rounded"
            active={active === 4}
            onClick={() => {
              toggleTab(4)
            }}
          >
            <span className="fw-bold">{t('Gruptan Gruba Transfer')}</span>
          </NavLink>
        </Col>
      </Nav>

      <TabContent
        style={{ display: activeType === 2 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <ManuallyAddRecord />
        </TabPane>
        <TabPane tabId={2}>
          <AddRecordsWithExcel />
        </TabPane>
        <TabPane tabId={3}>
          <ClassGroupOperations />
        </TabPane>
        <TabPane tabId={4}>
          <TransferFromGroupToGroup />
        </TabPane>
      </TabContent>
    </div>
  )
}

export default index
