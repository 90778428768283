import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { RqErrorMessage } from 'src/helpers/RequiredMessage'
import { useTranslation } from 'react-i18next'

type IType = {
  isLoading?: boolean | undefined
  isDisabled?: boolean | undefined
  isSearchable?: boolean | undefined
  options?: any
  className?: string
  name?: string
  onChange?: any
  value?: any
  defaultValue?: any
  style?: any
  textColor?: any
  label?: string
  required?: boolean | undefined
  errors?: any
  touched?: boolean
  nonDefaultValue?: boolean
  isMulti?: boolean
  id?: any
  ref?: any
}

const CustomSelect = (props: IType) => {
  const { t }: any = useTranslation()
  !props.nonDefaultValue && props.options?.unshift({ id: 0, name: t("Seçiniz...") })
  return (
    <div>
      {props.label && <Label>{props.label}</Label>}
      <Select
        id={props.id}
        ref={props.ref}
        className="custom-select"
        classNamePrefix="select"
        value={props.value}
        placeholder={t('Seçiniz...')}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.id}
        defaultValue={props.defaultValue}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        isSearchable={props.isSearchable}
        name={props.name}
        options={props.options}
        onChange={props.onChange}
        noOptionsMessage={() => 'Kayıt Bulunamadı'}
        loadingMessage={() => 'Yükleniyor'}
        isMulti={props.isMulti}
        styles={{
          // option: (baseStyles, state) => (
          //   {
          //     ...baseStyles,
          //     color: state.data.color,
          //   }),
        }}
      />
      {RqErrorMessage(props.errors, props.touched)}
    </div>
  )
}

export default CustomSelect
