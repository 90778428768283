import { useState } from 'react'
import { Col, Row } from 'reactstrap'
import './index.css'
import * as yup from 'yup'
import FieldOne from './fieldOne'
import FieldTwo from './fieldTwo'
import SmsPhone from '../phone/index'
import { useFormik } from 'formik'
import Tour from 'reactour'
import { FcSearch } from 'react-icons/fc'

type Props = {
  active: any
  formik: any
  activeType: any
}

const Index = (props: Props) => {
  const [count, setCount] = useState(1)
  const [isClicked1, setİsClicked1] = useState(false)
  const [isClicked2, setİsClicked2] = useState(false)
  const [isClicked3, setİsClicked3] = useState(false)
  const [guardian, setGuardian] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false)

  const steps = [
    {
      selector: '.step-1',
      content: 'Burada öğrenci adını girebilirsiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-2',
      content: 'Öğrenci soyadınızı.',
      stepInteraction: false,
    },
    {
      selector: '.step-3',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-4',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-5',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-6',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-7',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-8',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
      action: () => {
        openModal()
      },
    },
    {
      selector: '.step-9',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      content: (
        <div className="py-2 px-2">
          <button
            className="btn btn-danger "
            onClick={() => {
              closeTour()
              setCount(1)
            }}
          >
            Öğreticiyi Kapat
          </button>
        </div>
      ),
    },
  ]
  const openModal = () => {}

  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  const handleFieldTwoDataChange = (data1: any, data2: any, data3: any) => {
    setİsClicked1(data1)
    setİsClicked2(data2)
    setİsClicked3(data3)
  }
  const changeGuardian = (data: any) => {
    setGuardian(data)
  }

  const closeTour = () => {
    setIsTourOpen(false)
  }
  const CustomPrevButton = () => (
    <button className="mx-1 btn btn-warning">Önceki</button>
  )
  const CustomNexButton = () => (
    <button className="mx-1 btn btn-danger">Sonraki</button>
  )

  return (
    <Row className="justify-content-center ">
      <Col
        className="step-1"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="6"
        xxl="5"
        style={{ borderRight: '2px solid #ccc' }}
      >
        <FieldOne
          count={count}
          dataChange={changeGuardian}
          isClicked1={isClicked1}
          isClicked2={isClicked2}
          isClicked3={isClicked3}
        />
        <div className="d-flex mt-1">
          <div
            className="btn btn-success d-flex align-items-center justify-content-center"
            onClick={() => setIsTourOpen(true)}
          >
            <span>Nasıl Kullanılır</span>
            <FcSearch className="mx-1" size={30} />
          </div>
        </div>
      </Col>
      <Col
        className="step-2"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="6"
        xxl="5"
        style={{ borderRight: '2px solid #ccc' }}
      >
        <FieldTwo
          guardian={guardian}
          onDataChange={handleFieldTwoDataChange}
          formik={formik}
        />
      </Col>
      <Col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        xxl="2"
        className=" d-none d-xl-block"
      >
        <SmsPhone formik={formik} />
      </Col>
      <Tour
        getCurrentStep={(currentStep) => setCount(currentStep + 1)}
        rounded={20}
        steps={steps}
        isOpen={isTourOpen}
        showCloseButton={false}
        closeWithMask={false}
        onRequestClose={closeTour}
        accentColor="#5cb7b7"
        showNavigation={false}
        showNavigationNumber={true}
        prevButton={CustomPrevButton()}
        nextButton={CustomNexButton()}
      ></Tour>
    </Row>
  )
}

export default Index
