import { Link, Navigate, useNavigate } from 'react-router-dom'
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Form,
  Label,
  Input,
  InputGroup,
  Button,
  Spinner,
} from 'reactstrap'

import logo from 'src/assets/images/logo/vayonet-logo.png'


import { ChevronLeft } from 'react-feather'

import { useTranslation } from 'react-i18next'
import '@styles/react/pages/page-authentication.scss'
import { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { RqErrorMessage, minLength } from '../../../../helpers/RequiredMessage'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import { forgotPasswordConfirmHook, sendForgotPasswordHook } from './store/hook'
import Router from 'src/router/Router'
import { getUserIpInfo } from '../Login/store/service'
import formatNumber from 'src/functions/Number/formatNumber'
import { useSkin } from 'src/utility/hooks/useSkin'

const ForgotPassword = () => {
  const { t }: any = useTranslation()
  const { skin } = useSkin()
  const router = useNavigate()
  const illustration =
    skin === 'dark'
      ? 'reset-password-v2-dark.svg'
      : 'reset-password-v2.svg',
    source = require(`src/assets/images/pages/${illustration}`)
  const [step, setStep] = useState(0)
  const forgotPasswordConfirmMutation = forgotPasswordConfirmHook()
  const sendForgotPasswordMutation = sendForgotPasswordHook()
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: '',
      phone: "",
      password: ""
    },
    validationSchema: step === 1 && Yup.object({
      code: Yup.string().min(4, minLength).required(t('Bu alan zorunludur*')),
      phone: Yup.string().required(t('Bu alan zorunludur*')),
      password: Yup.string().min(6, minLength).required(t('Bu alan zorunludur*')),
    }),
    onSubmit: () => {
      step === 0 && (
        sendForgotPasswordMutation.mutate(formik.values.phone)
      )
      step === 1 && (
        forgotPasswordConfirmMutation.mutate({
          code: formik.values.code,
          identifier: formik.values.phone,
          new_pass: formik.values.password
        })
      )
    },
  })
  useEffect(() => {
    if (sendForgotPasswordMutation.isSuccess) {
      setStep(1)
    }
  }, [sendForgotPasswordMutation.isSuccess])
  useEffect(() => {
    if (forgotPasswordConfirmMutation.isSuccess) {
      router("/giris")
    }
  }, [forgotPasswordConfirmMutation.isSuccess])

  return (
    <div className="auth-wrapper auth-cover">
      <Row className="auth-inner m-0">
        <Link
          className="brand-logo"
          to="/forgot-password"
          onClick={() => {
            // dispatch({
            //   type: ForgotPasswordTypes.RESET_ALL_PASSWORD_STEPS,
            // })
          }}
        >
          <img src='/image/wtm-logo.png' alt="logo" height={45} />
        </Link>
        <Col className="d-none d-lg-flex align-items-center p-5" lg="8" sm="12">
          <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <img className="img-fluid" src={source?.default} alt="Login Cover" />
          </div>
        </Col>
        {step === 0 && (
          //1. AŞAMA
          <Col
            className="d-flex align-items-center auth-bg px-2 p-lg-5"
            lg="4"
            sm="12"
          >
            <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
              <CardTitle tag="h2" className="fw-bold mb-1">
                {t("Parolanızı mı unuttunuz")} 🔒
              </CardTitle>
              <CardText className="mb-2">
                {t("Telefon numaranızı girin ve şifrenizi kullanmanız için size doğrulama kodunu gönderelim")}
              </CardText>
              <div className="auth-forgot-password-form mt-2">
                <div className="mb-1">
                  <Label className="form-label" style={{ marginBottom: '8px' }} for="login-email">
                    {t('Telefon')}
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    placeholder="500 000 00 00"
                    invalid={formik.errors.phone && formik.touched.phone}
                    name="phone"
                    value={formik.values.phone}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'phone',
                        formatNumber(e.target.value, formik.values.phone)
                      )
                    }}
                  />
                  {RqErrorMessage(formik.errors.phone, formik.touched.phone)}
                </div>
                <Button
                  color="primary"
                  block
                  onClick={() => formik.handleSubmit()}
                >
                  {t("Sıfırlama kodu gönder")} {sendForgotPasswordMutation.isLoading && <Spinner size={'sm'} />}
                </Button>
              </div>
              <p className="text-center mt-2">
                <Link to="/giris">
                  <ChevronLeft className="rotate-rtl me-25" size={14} />
                  <span className="align-middle">{t("Giriş Sayfasına Dön")}</span>
                </Link>
              </p>
            </Col>
          </Col>
        )}
        {step === 1 && (
          //2. AŞAMA
          <Col
            className="d-flex align-items-center auth-bg px-2 p-lg-5"
            lg="4"
            sm="12"
            tag="form"
          >
            <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
              <CardTitle tag="h2" className="fw-bold mb-1">
                {t("Kod Gönderildi")}
              </CardTitle>
              <CardText className="mb-2">
                {t("Telefonunuza gelen 4 haneli kodu girin")}
              </CardText>
              <p className="mt-2 text-primary" style={{ fontSize: '12px' }}>
                <span className="text-secondary">{t("Kod gelmedi mi?")} </span>
                <span
                  className="align-middle cursor-pointer"
                  onClick={() => sendForgotPasswordMutation.mutate(formik.values.phone)}
                >
                  {t("Yeniden gönderin")}
                </span>
              </p>
              <div className="auth-forgot-password-form mt-2">
                <div className="mb-1">
                  <Label className="form-label" for="login-email">
                    {t("Kod")}
                  </Label>

                  <Input
                    type="text"
                    maxLength={6}
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                  />
                  {RqErrorMessage(formik.errors.code, formik.touched.code)}

                </div>
                <div className="mb-1">
                  <Label className="form-label" for="login-email">
                    {t("Şifre")}
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  {RqErrorMessage(formik.errors.password, formik.touched.password)}

                </div>
                <Button color="primary" block onClick={() => formik.handleSubmit()}>
                  {t("Gönder")}  {forgotPasswordConfirmMutation.isLoading && <Spinner size={'sm'} />}
                </Button>
              </div>
              <p
                className="text-center mt-2"
                onClick={() => formik.resetForm()}
              >
                <Link to="/giris">
                  <ChevronLeft className="rotate-rtl me-25" size={14} />
                  <span className="align-middle">{t("Giriş Sayfasına Dön")}</span>
                </Link>
              </p>
            </Col>
          </Col>
        )}
      </Row>
    </div >
  )
}

export default ForgotPassword
