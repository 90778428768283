import { FaLiraSign, FaUser } from 'react-icons/fa'
import {
  MdDashboard,
  MdMail,
  MdSettings,
  MdAttachMoney,
  MdContacts,
  MdChecklist,
  MdCreditCard,
  MdFolder,
  MdHelp,
  MdAccountBox,
  MdLogout,
  MdQuestionMark,
  MdSupport,
  MdSupportAgent,
  MdOutlineSupportAgent,
  MdHelpOutline,
} from 'react-icons/md'
export default [
  {
    id: 'dashboard',
    title: 'Ana Sayfa',
    icon: <MdDashboard size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/',
  },
  {
    id: 'message',
    title: 'Mesaj Gönder',
    icon: <MdMail size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/sendMessage',
  },
  {
    id: 'absences',
    title: 'Devamsızlık SMS',
    icon: <MdMail size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/absences',
  },
  {
    id: 'grades',
    title: 'Sınav Sonuç SMS',
    icon: <MdMail size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/grades',
  },
  {
    id: 'reports',
    title: 'Raporlar',
    icon: <MdChecklist size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/reports',
  },
  {
    id: 'contacts',
    title: 'Rehberim',
    icon: <MdContacts size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/contact',
  },
  {
    id: 'buyCredit',
    title: 'Satın Al',
    icon: <FaLiraSign size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/buyCredit',
  },
  {
    id: 'e-school',
    title: 'E Okul Entegrasyonu',
    icon: <MdFolder size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/eSchoolIntegration',
  },
  {
    id: 'help',
    title: 'Yardım',
    icon: (
      <MdOutlineSupportAgent
        size={20}
        style={{ width: '25px', height: '25px' }}
      />
    ),
    navLink: '/help',
  },
  {
    id: 'support',
    title: 'S.S.S.',
    icon: <MdHelpOutline size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/support',
  },
  {
    id: 'account',
    title: 'Ayarlar',
    icon: <MdSettings size={20} style={{ width: '25px', height: '25px' }} />,
    navLink: '/settings',
  },
]
