import { Button, Col, Input, Label, Row } from "reactstrap"
import SelectOptions from "src/views/components/Select/SelectOptions"
import Accordion from 'react-bootstrap/Accordion'

const index = () => {
    return (
        <div className="px-1 py-1" style={{ backgroundColor: "#FFFFFF" }}>
            <Col md="12">
                <Label>Doğum Günü Gönderici Adı</Label>
                <SelectOptions />
            </Col>
            <Accordion
                className="pb-1"
                flush
            >
                <Accordion.Item eventKey={'0'}>
                    <Accordion.Header>Öğrenci Doğum Günü Mesaj İçeriği</Accordion.Header>
                    <Accordion.Body>
                        <Row className="d-flex justify-content-center">
                            <Row>
                                <Col md="12" className="my-1">
                                    <h1 className="text-center">
                                        Öğrenci Doğum Günü Mesaj İçeriği
                                    </h1>
                                </Col>
                                <Col md="8" className="mx-1" >
                                    <Col md="12" className="d-flex justify-content-center  my-1">
                                        <Col
                                            style={{ backgroundColor: '#7367F0', cursor: 'pointer', paddingTop: "4px", paddingBottom: "4px" }}
                                            className="text-white  rounded-2  text-center "
                                            md="3"
                                        >
                                            Okul No
                                        </Col>
                                        <Col
                                            style={{ backgroundColor: '#7367F0', cursor: 'pointer', paddingTop: "4px", paddingBottom: "4px" }}
                                            className="text-white mx-1  rounded-2    text-center "
                                            md="3"
                                        >
                                            Öğrenci Ad Soyad
                                        </Col>
                                        <Col
                                            style={{ backgroundColor: '#7367F0', cursor: 'pointer', paddingTop: "4px", paddingBottom: "4px" }}
                                            className="text-white rounded-2   text-center "
                                            md="3"
                                        >
                                            Veli Ad Soyad
                                        </Col>
                                        <Col
                                            style={{ backgroundColor: '#7367F0', cursor: 'pointer', paddingTop: "4px", paddingBottom: "4px" }}
                                            className="text-white mx-1  rounded-2  text-center "
                                            md="3"
                                        >
                                            Tarih
                                        </Col>
                                    </Col>
                                    <Col md="12">
                                        <Input type="textarea"></Input>
                                    </Col>
                                </Col>
                                <Col md="3" >
                                    <h3>Alıcı</h3>
                                    <Col className="d-flex justify-content-between mt-1 align-items-end" >
                                        <Input type="checkbox"></Input>
                                        <div style={{ fontSize: "16x" }} className="">Veli 1</div>
                                    </Col>
                                    <Col className="d-flex justify-content-between mb-1 align-items-end" >
                                        <Input type="checkbox"></Input>
                                        <div style={{ fontSize: "16x" }} className="">Veli 2</div>
                                    </Col>
                                    <Col className="my-1">
                                        Gönderim Durumu : Açık
                                    </Col>
                                    <Col className="d-flex justify-content-between mt-1 align-items-end" >
                                        <Input type="radio"></Input>
                                        <div style={{ fontSize: "16x" }} className="">Açık</div>
                                    </Col>
                                    <Col className="d-flex justify-content-between mb-1 align-items-end" >
                                        <Input type="radio"></Input>
                                        <div style={{ fontSize: "16x" }} className="">Kapalı</div>
                                    </Col>
                                    <Col className="d-flex my-1 justify-content-center align-items-center">
                                        <Button className="btn btn-warning">Şablonu Güncelle</Button>
                                    </Col>
                                </Col>
                            </Row>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={'1'}>
                    <Accordion.Header>Öğretmen Doğum Günü Mesaj İçeriği</Accordion.Header>
                    <Accordion.Body>
                        <Row className="d-flex justify-content-center">
                            <Row>
                                <Col md="12" className="my-1">
                                    <h1 className="text-center">
                                        Öğretmen Doğum Günü Mesaj İçeriği
                                    </h1>
                                </Col>
                                <Col md="8" className="mx-1" >
                                    <Col md="12" className="d-flex justify-content-start  my-1">

                                        <Col
                                            style={{ backgroundColor: '#7367F0', cursor: 'pointer', paddingTop: "4px", paddingBottom: "4px" }}
                                            className="text-white mx-1  rounded-2    text-center "
                                            md="3"
                                        >
                                            Ad Soyad
                                        </Col>

                                    </Col>
                                    <Col md="12">
                                        <Input type="textarea"></Input>
                                    </Col>
                                </Col>
                                <Col md="3" >
                                    <Col className="my-1">
                                        Gönderim Durumu : Açık
                                    </Col>
                                    <Col className="d-flex justify-content-between mt-1 align-items-end" >
                                        <Input type="radio"></Input>
                                        <div style={{ fontSize: "16x" }} className="">Açık</div>
                                    </Col>
                                    <Col className="d-flex justify-content-between mb-1 align-items-end" >
                                        <Input type="radio"></Input>
                                        <div style={{ fontSize: "16x" }} className="">Kapalı</div>
                                    </Col>
                                    <Col className="d-flex my-1 justify-content-center align-items-center">
                                        <Button className="btn btn-warning">Şablonu Güncelle</Button>
                                    </Col>
                                </Col>
                            </Row>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={'2'}>
                    <Accordion.Header>Personel Doğum Günü Mesaj İçeriği</Accordion.Header>
                    <Accordion.Body>
                        <Row className="d-flex justify-content-center">
                            <Row>
                                <Col md="12" className="my-1">
                                    <h1 className="text-center">
                                        Personel Doğum Günü Mesaj İçeriği
                                    </h1>
                                </Col>
                                <Col md="8" className="mx-1" >
                                    <Col md="12" className="d-flex justify-content-start  my-1">

                                        <Col
                                            style={{ backgroundColor: '#7367F0', cursor: 'pointer', paddingTop: "4px", paddingBottom: "4px" }}
                                            className="text-white mx-1  rounded-2    text-center "
                                            md="3"
                                        >
                                            Ad Soyad
                                        </Col>

                                    </Col>
                                    <Col md="12">
                                        <Input type="textarea"></Input>
                                    </Col>
                                </Col>
                                <Col md="3" >
                                    <Col className="my-1">
                                        Gönderim Durumu : Açık
                                    </Col>
                                    <Col className="d-flex justify-content-between mt-1 align-items-end" >
                                        <Input type="radio"></Input>
                                        <div style={{ fontSize: "16x" }} className="">Açık</div>
                                    </Col>
                                    <Col className="d-flex justify-content-between mb-1 align-items-end" >
                                        <Input type="radio"></Input>
                                        <div style={{ fontSize: "16x" }} className="">Kapalı</div>
                                    </Col>
                                    <Col className="d-flex my-1 justify-content-center align-items-center">
                                        <Button className="btn btn-warning">Şablonu Güncelle</Button>
                                    </Col>
                                </Col>
                            </Row>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    )
}

export default index
