import { Fragment } from 'react'
import { Label } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'
// import { RqErrorMessage } from 'src/helpers/RequiredMessage'
type ICustomDate = {
  label?: string
  required?: boolean
  name?: string
  disabled?: boolean
  value?: any
  onChange?: (e: any) => void
  errors?: any
  touched?: boolean
  minDate?: any
  maxDate?: any
  placeholder?: string
  onlyDate?: boolean
}
const CustomDate = (props: ICustomDate) => {
  const { t } = useTranslation()
  return (
    <Fragment>
      {props.label && (
        <Label className="form-label" for="default-picker">
          {props.label}{' '}
          {props.required ? <span className="text-danger">*</span> : ''}
        </Label>
      )}
      <Flatpickr
        style={{ borderColor: '#1F59AA' }}
        options={{
          minDate: props.minDate,
          maxDate: props.maxDate,
          dateFormat: props.onlyDate ? 'd.m.Y' : 'd.m.Y H:i',
          enableTime: props.onlyDate ? false : true,
          time_24hr: true,
          locale: {
            weekdays: {
              longhand: [
                'Pazar',
                'Pazartesi',
                'Salı',
                'Çarşamba',
                'Perşembe',
                'Cuma',
                'Cumartesi',
              ],
              shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
            },
            months: {
              longhand: [
                t('Ocak'),
                t('Şubat'),
                t('Mart'),
                t('Nisan'),
                t('Mayıs'),
                t('Haziran'),
                t('Temmuz'),
                t('Ağustos'),
                t('Eylül'),
                t('Ekim'),
                t('Kasım'),
                t('Aralık'),
              ],
              shorthand: [
                t('Oca'),
                t('Şub'),
                t('Mar'),
                t('Nis'),
                t('May'),
                t('Haz'),
                t('Tem'),
                t('Ağu'),
                t('Eyl'),
                t('Eki'),
                t('Kas'),
                t('Ara'),
              ],
            },
          },
        }}
        className="form-control bg-white "
        name={props.name}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        id="default-picker"
      />
      {/* {RqErrorMessage(props.errors, props.touched)} */}
    </Fragment>
  )
}

export default CustomDate
