import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AiOutlineCloudUpload, AiOutlineQuestionCircle } from 'react-icons/ai'
import CustomTable from 'src/views/components/customTable/customTable'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { Scrollbar } from 'react-scrollbars-custom'
import './index.css'
import { FaSearchPlus } from 'react-icons/fa'
import { FcSearch } from 'react-icons/fc'
import Tour from 'reactour'

const CustomExcel = (props: any) => {
  const [modal, setModal] = useState(false)
  const [active, setActive] = useState(true)
  const [excelModal, setExcelModal] = useState(false)
  const [show, setShow] = useState(false)
  const { t }: any = useTranslation()
  const [excelData, setExcelData] = useState<any[][] | null>(null)
  const [columnsName, setColumnsName] = useState<any>('')
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [count, setCount] = useState(1)

  const steps = [
    {
      selector: '.step-1',
      content: 'Burada öğrenci adını girebilirsiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-2',
      content: 'Öğrenci soyadınızı.',
      stepInteraction: false,
    },
    {
      selector: '.step-3',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-4',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-5',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-6',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-7',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      selector: '.step-8',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
      action: () => {},
    },
    {
      selector: '.step-9',
      content: 'Mesaj Göndermek istediğiniz Alanı Seçiniz.',
      stepInteraction: false,
    },
    {
      content: (
        <div className="py-2 px-2">
          <button
            className="btn btn-danger "
            onClick={() => {
              setIsTourOpen(false)
              setCount(1)
            }}
          >
            Öğreticiyi Kapat
          </button>
        </div>
      ),
    },
  ]

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()

    reader.onload = (e: any) => {
      const binaryData = e.target.result
      const workbook = XLSX.read(binaryData, { type: 'binary' })

      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]

      const data: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      setExcelData(data)
    }

    reader.readAsBinaryString(file)
  }

  const col = excelData ? excelData[0]?.join(',') : ''

  const columns2 = excelData
    ? excelData[0].map((item: any) => ({
        name: item,
        Sortable: true,
        selector: (row: any) => row[item],
      }))
    : []

  const selectionColumn: any = {
    name: 'Seç',
    selector: (row: any) => row.selected,
    sortable: false,
    cell: (row: any) => (
      <Input
        type="checkbox"
        checked={row.selected}
        onChange={() => handleCheckboxChange(row)}
      />
    ),
  }

  columns2.unshift(selectionColumn)

  const data1 = excelData
    ? excelData.slice(1).map((item: any, index: number) => {
        const row: any = {
          selected: true,
          index: index,
        }
        excelData[0].forEach((columnName: any, i: number) => {
          row[columnName] = item[i]
        })
        return row
      })
    : []
  const handleCheckboxChange = (row: any) => {
    const update = [excelData ? [...excelData] : '']
  }

  if (typeof props.onDataChange === 'function') {
    props.onDataChange(col)
  }

  const columns = [
    {
      name: t('Okul No'),
      Sortable: true,
      selector: (row: { no: any }) => row.no,
      width: '25%',
    },
    {
      name: t('Ad Soyad'),
      Sortable: false,
      selector: (row: { name: any }) => row.name,
      width: '35%',
    },
    {
      name: t('Devamsızlık Türü'),
      Sortable: false,
      selector: (row: { type: any }) => row.type,
      width: '40%',
    },
  ]
  const data = excelData
    ? excelData.map((item: any) => {
        return {
          no: <div onClick={() => setExcelModal(true)}>{item.OkulNo}</div>,
          name: <div onClick={() => setExcelModal(true)}>{item.AdSoyad}</div>,
          type: <div onClick={() => setExcelModal(true)}>{item.Mesaj}</div>,
        }
      })
    : []
  useEffect(() => {
    if (excelData ? excelData.length > 0 : '') {
      setActive(false)
    }
  }, [excelData])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if (excelData ? excelData.length > 0 : '') {
      setShow(true)
    }
  }, [excelData])
  return (
    <div>
      <div className=" text-center ">
        <div className="text-center mb-1">
          <b>
            Excel Dosyanızı Sürükleyip Bırakarak Yada Kutuya Tıklayıp Seçerek
            Yükleyebilirsiniz.
          </b>
        </div>
        <div className="file-upload-container">
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? 'active' : ''}`}
          >
            <input {...getInputProps()} />
            <AiOutlineCloudUpload size={40} color="#AAAAAA" />
            <p style={{ cursor: 'pointer' }}>
              Dosyayı buraya sürükleyin ya da tıklayın
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex mt-1">
        <div
          className="btn btn-success d-flex align-items-center justify-content-center"
          onClick={() => setIsTourOpen(true)}
        >
          <span>Nasıl Kullanılır</span>
          <FcSearch className="mx-1" size={30} />
        </div>
      </div>
      <Tour
        getCurrentStep={(currentStep) => setCount(currentStep + 1)}
        rounded={20}
        steps={steps}
        isOpen={isTourOpen}
        showCloseButton={false}
        closeWithMask={false}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor="#5cb7b7"
        showNavigation={false}
        showNavigationNumber={true}
        prevButton={<button className="mx-1 btn btn-warning">Önceki</button>}
        nextButton={<button className="mx-1 btn btn-danger">Sonraki</button>}
      />
    </div>
  )
}

export default CustomExcel
