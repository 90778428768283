import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AiOutlineCloudUpload, AiOutlineQuestionCircle } from 'react-icons/ai'
import CustomTable from 'src/views/components/customTable/customTable'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import { IoReloadCircleSharp } from 'react-icons/io5'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { Scrollbar } from 'react-scrollbars-custom'
import { FaSearchPlus } from 'react-icons/fa'
import CustomExcel from 'src/views/pages/main/components/CustomExcel'

const index = (props: any) => {
  const [modal, setModal] = useState(false)
  const [active, setActive] = useState(true)
  const [excelModal, setExcelModal] = useState(false)
  const [show, setShow] = useState(false)
  const { t }: any = useTranslation()
  const [excelData, setExcelData] = useState<any[][] | null>(null)
  const [columnsName, setColumnsName] = useState<any>('')

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()

    reader.onload = (e: any) => {
      const binaryData = e.target.result
      const workbook = XLSX.read(binaryData, { type: 'binary' })

      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]

      const data: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      setExcelData(data)
    }

    reader.readAsBinaryString(file)
  }

  const col = excelData ? excelData[0]?.join(',') : ''

  const columns2 = excelData
    ? excelData[0].map((item: any) => ({
        name: item,
        Sortable: true,
        selector: (row: any) => row[item],
      }))
    : []

  const selectionColumn: any = {
    name: 'Seç',
    selector: (row: any) => row.selected,
    sortable: false,
    cell: (row: any) => (
      <Input
        type="checkbox"
        checked={row.selected}
        onChange={() => handleCheckboxChange(row)}
      />
    ),
  }

  columns2.unshift(selectionColumn)

  const data1 = excelData
    ? excelData.slice(1).map((item: any, index: number) => {
        const row: any = {
          selected: true,
          index: index,
        }
        excelData[0].forEach((columnName: any, i: number) => {
          row[columnName] = item[i]
        })
        return row
      })
    : []
  const handleCheckboxChange = (row: any) => {
    const update = [excelData ? [...excelData] : '']
  }

  if (typeof props.onDataChange === 'function') {
    props.onDataChange(col)
  }

  const columns = [
    {
      name: t('Okul No'),
      Sortable: true,
      selector: (row: { no: any }) => row.no,
      width: '25%',
    },
    {
      name: t('Ad Soyad'),
      Sortable: false,
      selector: (row: { name: any }) => row.name,
      width: '35%',
    },
    {
      name: t('Devamsızlık Türü'),
      Sortable: false,
      selector: (row: { type: any }) => row.type,
      width: '40%',
    },
  ]
  const data = excelData
    ? excelData.map((item: any) => {
        return {
          no: <div onClick={() => setExcelModal(true)}>{item.OkulNo}</div>,
          name: <div onClick={() => setExcelModal(true)}>{item.AdSoyad}</div>,
          type: <div onClick={() => setExcelModal(true)}>{item.Mesaj}</div>,
        }
      })
    : []
  useEffect(() => {
    if (excelData ? excelData.length > 0 : '') {
      setActive(false)
    }
  }, [excelData])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const scrollAreaTable = {
    width: '100%',
    height: '500px',
    overflow: 'scroll',
    padding: '10px',
  }

  useEffect(() => {
    if (excelData ? excelData.length > 0 : '') {
      setShow(true)
    }
  }, [excelData])
  return (
    <>
      {active ? (
        <CustomExcel />
      ) : (
        <>
          <Row>
            <Col md="12" className=" d-flex justify-content-center">
              <div className="">
                <div
                  onClick={() => setActive(true)}
                  style={{ cursor: 'pointer' }}
                  className=" d-flex justify-content-center align-items-center"
                >
                  <AiOutlineCloudUpload size={40} color="#AAAAAA" />
                </div>
                <div className=""> Excel Dosyasını Tekrar Yükle</div>
              </div>
            </Col>
            <Col md="12">
              <Scrollbar className="" style={{ width: '100%', height: 400 }}>
                <CustomTable notPage data={data1} columns={columns2}>
                  <Button color="primary" onClick={() => setExcelModal(true)}>
                    <FaSearchPlus size={'25px'} />
                  </Button>
                </CustomTable>
              </Scrollbar>
            </Col>
          </Row>
        </>
      )}

      <Modal
        className="modal-dialog-centered modal-xl py-1"
        isOpen={excelModal}
        toggle={() => setExcelModal(!excelModal)}
      >
        <ModalHeader
          style={{ backgroundColor: '#1F59AA' }}
          className="d-flex justify-content-center align-items-center text-center "
          closeButton
          toggle={() => setExcelModal(!excelModal)}
        >
          <h4 className="text-white">Aktarılan Kayıtlar</h4>
        </ModalHeader>
        <ModalBody>
          <div>
            <CustomTable notPage data={data1} columns={columns2} />
          </div>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        ></ModalHeader>
        <ModalBody className="text-center px-1  ">
          <div className="text-black">
            <AiOutlineQuestionCircle size={100} color="black" />
          </div>
          <div className=" mt-1 px-1 p ">
            <h4>Nasıl Kullanılır?</h4>
          </div>
          <div className="px-1 py-1 ">
            <p>
              E-Okuldan indireceğiniz data-only dosyasını hiçbir değişiklik
              yapmadan sisteme yükleyiniz.
            </p>
            <p>
              {
                "İlkokul-Ortaokul Kurum İşlemleri/ Not İşlemleri/ Hızlı Ders Notu Girişi/ Raporlar(Yazıcı Sembolü)/(en üstte 'Gösterici Seçenekleri'ni HTML5 Word Excel yapın) “IOK10006R - 4,5,6,7 ve 8 Sınıflar Öğretmen Puan Çizelgeleri(Genel ve Seçmeli Dersler)” raporunu açın."
              }
            </p>
            <p>
              {
                "Ardından 1 adet ders seçmeli ve açılan sayfada yazıcı sembolünün yanındaki oka tıklayıp 'Excel Sadece Veri'yi seçip indirebilirsiniz."
              }
            </p>
            <p>
              {
                'Rehberinizde çakışan numaralar varsa Rehber Türü seçerek karışıklığı önleyebilirsiniz.'
              }
            </p>
            <p>
              {
                'Dosyanızı hiç bir değişiklik yapmadan yandan sisteme aktarınız.'
              }
            </p>
            <p>
              {
                'Dosyanız aktarıldıktan sonra devamsızlık durumuna göre gerekli seçimleri yapıp ilerleyiniz.Devamsızlık bilgisinin gideceği kişiler ve mesaj içeriği listelenecektir.Dilerseniz mesajın sonundaki koyu yazılmış metinleri değiştirebilir veya kaldırabilirsiniz.Ardından Mesajı Gönder butonuyla seçilenlere mesaj gönderim işlemini tamamlayınız.'
              }
            </p>
            <p>
              <b>
                {
                  'Sadece E-Okuldan alınacak olan IOK10006R - 14,5,6,7 ve 8 Sınıflar Öğretmen Puan Çizelgeleri(Genel ve Seçmeli Dersler) dosyasıyla çalışır.'
                }
              </b>
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default index
