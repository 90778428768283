import React, { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import debounce from 'lodash/debounce';
import { useFormik } from 'formik'
import Guides from './../../../../../../../components/guides'
import '../../../../../../../../@core/scss/custom.scss'
import SelectOptions from 'src/views/components/Select/SelectOptions'
const Index = (props: any) => {
  const { t }: any = useTranslation()
  const [count, setCount] = useState(0)
  const formik: any = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {
    },
  })
  const fprmiks = props.formik ? props.formik : null
  useEffect(() => {
    if (fprmiks) {
      fprmiks.setFieldValue(
        'message',
        formik.values.message
      )
    }
  }, [formik.values.message])
  const messageEle = document.getElementById('textarea');

  if (messageEle) {
    const debounceHandler = debounce(function (e: any) {
      const target = e.target;
      const maxLength = target.getAttribute('maxlength');
      const currentLength = target.value.length;
      setCount(currentLength);
    }, 300);
    messageEle.addEventListener('input', debounceHandler);
  }



  return (
    <Row className="px-1">
      {
        props.guardian ? "" : (
          <Guides />
        )
      }
       <Col md="6" className="mb-1">
          <div>
            <Label>Dosya Seç</Label>
            <Input type='file'/>
          </div>
        </Col>
        <Col md="6" className="mb-1">
          <div>
            <Label>WhatsApp Numarası</Label>
            <Input disabled value={"5306556725"} label={t('WhatsApp Nuamarası')} />
          </div>
        </Col>
      <Col md="6" className="mb- bg-">
        <div className='d-flex justify-content-between align-items-center'>
          <Label>Mesaj</Label>
          <Label>
            <b style={{ fontSize: '12px' }}>1 SMS({count} Karakter)</b>
          </Label>
        </div>
        <Input
          id="textarea"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          maxlength="400"
          className='messageField'
          type="textarea"
        />
      </Col>
      <Col md="6" className='bg-' >
        <Col md="12" className="mb-1">
          <div>
          <Label>Türü</Label>
            <SelectOptions  />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
          <Label>Şablon Seçimi</Label>
            <SelectOptions />
          </div>
        </Col>
        <Col md="12" className="mb-">
          <div>
          <Label>Hızı</Label>
            <SelectOptions  />
          </div>
        </Col>

      </Col>
      {
        props.guardian ? (
          <Col md="6" className='d-flex justify-content-center my-1' >
            <Col
              md="6"
              style={{ paddingTop: "10px", paddingBottom: "10px", backgroundColor: "#5F53DC" }}
              className="rounded-5 border btn text-white  text-center d-flex justify-content-center align-items-center"
            >
              <b style={{ fontSize: '11px' }}>Ad Soyad</b>
            </Col>
          </Col>
        ) : (
          <Col md="6" className="">
            <Col md="12" className="d-flex justify-content-around align-items-center my-1 ">

              <div
                style={{ paddingTop: "5px", paddingBottom: "5px", backgroundColor: "#5F53DC" }}
                className="rounded-5 border btn text-white   text-center d-flex align-items-center"
              >
                <b style={{ fontSize: '11px' }}> Okul No</b>
              </div>
              <div
                style={{ paddingTop: "5px", paddingBottom: "5px", backgroundColor: "#5F53DC" }}
                className="rounded-5 border btn text-white  text-center d-flex align-items-center"
              >
                <b style={{ fontSize: '11px' }}> Öğrenci Ad Soyad</b>
              </div>
              <div
                style={{ paddingTop: "5px", paddingBottom: "5px", backgroundColor: "#5F53DC" }}
                className="rounded-5 border btn text-white   text-center d-flex align-items-center"
              >
                <b className='' style={{ fontSize: '11px' }}>Veli Ad Soyad</b>
              </div>
            </Col>
          </Col>
        )
      }

      <Row className="mb-">
        <Col md="6" style={{ marginBottom: '5px' }}>
          <div className="d-flex align-items-center">
            <Input type="checkbox" />
            <span
              className='messageCheckbox'
            >
              {t('Mesajı Şablon Olarak Kaydet')}
            </span>
          </div>
        </Col>
        <Col md="6">
          <div className="d-flex align-items-center">
            <Input type="checkbox" />
            <span
              className='messageCheckbox'
            >
              {t('Mükerrer Mesajları Sil')}
            </span>
          </div>
        </Col>
      </Row>

      <Col md="12" style={{ marginTop: "10px" }} className="m">
        <div
          className="btn text-white d-flex align-items-center justify-content-center btn-block"
          style={{ backgroundColor: '#1F59AA' }}
        >
          Mesajı Gönder
          <Send className="font-medium-3 ms-2" color="white" />
        </div>
      </Col>
    </Row>
  )
}

export default Index
