import { Button, Col, Input, Row } from "reactstrap"
import CustomTable from "src/views/components/customTable/customTable"

const index = () => {
  const columns = [
    {
      name:"Aktarılan Alt Kullanıcı",
      sortable:true,
      selector : (row : {subUserTransferred:any})=>row.subUserTransferred
    },
    {
      name:"Aktarıldığı Tarih",
      sortable:true,
      selector : (row : {dateTransferred:any})=>row.dateTransferred
    },
    {
      name:"Aktarılan Kredi Miktarı",
      sortable:true,
      selector : (row : {amountofCreditTransferred:any})=>row.amountofCreditTransferred
    },
    {
      name:"Aktarımdan Sonra Kalan Kredi",
      sortable:true,
      selector : (row : {creditRemainingAfterTransfer:any})=>row.creditRemainingAfterTransfer
    },
  ]
  const data = [
  {
    subUserTransferred:"Muhammetcan Akcua",
    dateTransferred:"12-06-2024",
    amountofCreditTransferred:"1200",
    creditRemainingAfterTransfer:"2400"
  }
  ].map((item:any)=>{
    return {
      subUserTransferred:item.subUserTransferred,
      dateTransferred:item.dateTransferred,
      amountofCreditTransferred:item.amountofCreditTransferred,
      creditRemainingAfterTransfer:item.creditRemainingAfterTransfer
    }
  })
  return (
    <div>
      <Row>
        <Col md="12" className="d-flex justify-content-between align-items-center mb-1">
        <Button className="btn btn-success">Excele Aktar</Button>
        <Input style={{ width: '200px' }}  placeholder="Arama"></Input>
        </Col>
        <Col>
        <CustomTable data={data}  columns={columns}/>
        </Col>
      </Row>
    </div>
  )
}

export default index