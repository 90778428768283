import axios from 'axios'
import { resolve } from 'path'
import { IForgorPasswordConfirm } from './model'

export const sendForgotPassword = async (phone: string) => {
  return await new Promise((resolve, reject) =>
    axios
      .post(`/user/pass/${phone}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  )
}
export const forgotPasswordConfirm = async (data: IForgorPasswordConfirm) => {
  return await new Promise((resolve, reject) =>
    axios
      .post('/user/forgot/pass', data)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  )
}
