import { useTranslation } from 'react-i18next'

export const columns = [
  {
    name: 'Tarih',
    sortable: true,
    selector: (row: { date: string }) => row.date,
  },
  {
    name: 'Mesaj',
    sortable: true,
    selector: (row: { message: string }) => row.message,
  },
  {
    name: 'Durum',
    sortable: true,
    selector: (row: { details: string }) => row.details,
  },
  {
    name: 'Gönderen',
    sortable: true,
    selector: (row: { sender: string }) => row.sender,
  },
  {
    name: 'Hesap',
    sortable: true,
    selector: (row: { sendAccount: string }) => row.sendAccount,
  },
  {
    name: 'Sonuç',
    sortable: true,
    selector: (row: { result: string }) => row.result,
  },
  {
    name: 'İşlem',
    sortable: true,
    selector: (row: { process: string }) => row.process,
  },
]

export const modalColumns = [
  {
    name: 'Tarih',
    sortable: true,
    selector: (row: { date: string }) => row.date,
  },
  {
    name: 'Durum',
    sortable: true,
    selector: (row: { readStatus: string }) => row.readStatus,
  },
  {
    name: 'Sınıf',
    sortable: true,
    selector: (row: { class: string }) => row.class,
  },
  {
    name: 'Okul No',
    sortable: true,
    selector: (row: { schoolNo: string }) => row.schoolNo,
  },
  {
    name: 'İsim',
    sortable: true,
    selector: (row: { name: string }) => row.name,
  },
  {
    name: 'Telefon',
    sortable: true,
    selector: (row: { phone: string }) => row.phone,
  },
  {
    name: 'Mesaj',
    sortable: true,
    selector: (row: { message: string }) => row.message,
  },
]
