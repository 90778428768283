import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Form from 'react-bootstrap/esm/Form'
import CustomModal from 'src/views/components/customModal/CustomModal'
import SubUser from '../../settings/subUser'
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import SenderName from '../../settings/senderName'
import CreditTransferHistort from '../../settings/creditTransferHistory'
import SmsTemplates from '../../settings/smsTemplates'
import AccountActivation from '../../settings/accountActivation'
import TypesOfGuides from '../../settings/typesofGuides/index'
import BlackList from '../../settings/blacklist/index'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

interface Props {
  modal: boolean
  setModal: Dispatch<SetStateAction<boolean>>
}

const DetailsModal = (props: Props) => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])
  return (
    <CustomModal fullScreen modal={props.modal} setModal={props.setModal}>
      <div className="fontFamily">
        <Nav
          pills
          className="mb-0 p-2 d-flex align-items-start justify-content-start "
        >
          <Col sm="6" lg="3" className="d-flex  mx-1">
            <Col sm="12" lg="6" className="mb-1 ">
              <NavLink
                style={{
                  marginTop: '3px',
                  marginBottom: '3px',
                  borderColor: '#1F59AA',
                  color: active === 1 ? 'white' : '#1F59AA',
                  backgroundColor: active === 1 ? '#1F59AA' : 'inherit',
                }}
                className="  border-2  rounded"
                active={active === 1}
                onClick={() => {
                  toggleTab(1)
                }}
              >
                <span className="fw-bold">{t('Alt Kullanıcı')}</span>
              </NavLink>
            </Col>

            <Col sm="12" lg="6" className="mb-1 mx-1">
              <NavLink
                style={{
                  marginTop: '3px',
                  marginBottom: '3px',
                  borderColor: '#1F59AA',
                  color: active === 2 ? 'white' : '#1F59AA',
                  backgroundColor: active === 2 ? '#1F59AA' : 'inherit',
                }}
                className="border-  border-2  rounded"
                active={active === 2}
                onClick={() => {
                  toggleTab(2)
                }}
              >
                <span style={{ fontSize: '12px' }} className="fw-bold">
                  {t('Kredi Aktarım Geçmişi')}
                </span>
              </NavLink>
            </Col>
          </Col>
          <Col sm="6" lg="3" className="d-flex   mx-1">
            <Col sm="12" lg="6" className="mb-1">
              <NavLink
                style={{
                  marginTop: '3px',
                  marginBottom: '3px',
                  borderColor: '#1F59AA',
                  color: active === 3 ? 'white' : '#1F59AA',
                  backgroundColor: active === 3 ? '#1F59AA' : 'inherit',
                }}
                className="border-  border-2  rounded"
                active={active === 3}
                onClick={() => {
                  toggleTab(3)
                }}
              >
                <span className="fw-bold">{t('Sms Şablonları')}</span>
              </NavLink>
            </Col>
            <Col sm="12" lg="6" className="mb-1 mx-1">
              <NavLink
                style={{
                  marginTop: '3px',
                  marginBottom: '3px',
                  borderColor: '#1F59AA',
                  color: active === 4 ? 'white' : '#1F59AA',
                  backgroundColor: active === 4 ? '#1F59AA' : 'inherit',
                }}
                className="  border-2  rounded"
                active={active === 4}
                onClick={() => {
                  toggleTab(4)
                }}
              >
                <span className="fw-bold">{t('Gönderici Adı')}</span>
              </NavLink>
            </Col>
          </Col>
          <Col sm="6" lg="3" className="d-flex mx-1">
            <Col sm="12" lg="6" className="mb-1">
              <NavLink
                style={{
                  marginTop: '3px',
                  marginBottom: '3px',
                  borderColor: '#1F59AA',
                  color: active === 5 ? 'white' : '#1F59AA',
                  backgroundColor: active === 5 ? '#1F59AA' : 'inherit',
                }}
                className="border-  border-2  rounded"
                active={active === 5}
                onClick={() => {
                  toggleTab(5)
                }}
              >
                <span style={{ fontSize: '12px' }} className="fw-bold">
                  {t('Hesap Aktivasyonu')}
                </span>
              </NavLink>
            </Col>
            <Col sm="12" lg="6" className=" mb-1 mx-1">
              <NavLink
                style={{
                  marginTop: '3px',
                  marginBottom: '3px',
                  borderColor: '#1F59AA',
                  color: active === 6 ? 'white' : '#1F59AA',
                  backgroundColor: active === 6 ? '#1F59AA' : 'inherit',
                }}
                className="border-  border-2  rounded"
                active={active === 6}
                onClick={() => {
                  toggleTab(6)
                }}
              >
                <span className="fw-bold">{t('Rehber Türleri')}</span>
              </NavLink>
            </Col>
          </Col>
          <Col sm="12" lg="2" className=" d-flex   ">
            <Col sm="6" lg="9" className="mb-1 mx-1">
              <NavLink
                style={{
                  marginTop: '3px',
                  marginBottom: '3px',
                  borderColor: '#1F59AA',
                  color: active === 7 ? 'white' : '#1F59AA',
                  backgroundColor: active === 7 ? '#1F59AA' : 'inherit',
                }}
                className="border-  border-2  rounded"
                active={active === 7}
                onClick={() => {
                  toggleTab(7)
                }}
              >
                <span className="fw-bold">{t('Kara Liste')}</span>
              </NavLink>
            </Col>
          </Col>
        </Nav>
        <TabContent
          className="p-2"
          style={{ display: activeType === 2 ? 'none' : 'grid' }}
          activeTab={active}
        >
          <TabPane tabId={1}>
            <SubUser />
          </TabPane>
          <TabPane tabId={2}>
            <CreditTransferHistort />
          </TabPane>
          <TabPane tabId={3}>
            <SmsTemplates />
          </TabPane>
          <TabPane tabId={4}>
            <SenderName />
          </TabPane>
          <TabPane tabId={5}>
            <AccountActivation />
          </TabPane>
          <TabPane tabId={6}>
            <TypesOfGuides />
          </TabPane>
          <TabPane tabId={7}>
            <BlackList />
          </TabPane>
        </TabContent>
      </div>
    </CustomModal>
  )
}

export default DetailsModal
