import { Dispatch, SetStateAction } from 'react'
import Form from 'react-bootstrap/esm/Form'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import CustomModal from 'src/views/components/customModal/CustomModal'
import Button from 'react-bootstrap/esm/Button'
import ListGroup from 'react-bootstrap/ListGroup'

interface Props {
  modal: boolean
  setModal: Dispatch<SetStateAction<boolean>>
}

const AuthModal = (props: Props) => {
  return (
    <CustomModal modal={props.modal} setModal={props.setModal}>
      <div className="px-3 py-2">
        <h1 className="mt-1 text-center text-black mb-3">SMS Ayarları</h1>
        <Form className=" h-100 d-flex">
          <div className="d-flex h-100 w-100 flex-column justify-content-around me-3">
            <Form.Group className="mb-2">
              <Form.Label>SMS Doğrulama Kodu</Form.Label>
              <div className="d-flex">
                <Form.Control placeholder="SMS Kodu" type="number" />
                <Button className="ms-1 w-50" variant="outline-primary">
                  Kod Gönder
                </Button>
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Telefon Numarası Ekle</Form.Label>
              <Button className="w-100" variant="outline-primary">
                Telefon Numarası Ekle
              </Button>
            </Form.Group>
            <Form.Group className="text-center mb-2">
              <Form.Label>
                Gönderilen Veliyi Kontrol Et Yoksa Diğer Veliye Gönder
              </Form.Label>
              <Form.Check type="switch" />
            </Form.Group>
          </div>
          <div className="w-100 d-flex flex-column">
            <Form.Group className="mb-1">
              <Form.Label>Telefon Numaraları</Form.Label>
              <ListGroup>
                <ListGroup.Item>0555 555 5555</ListGroup.Item>
                <ListGroup.Item>0555 555 5555</ListGroup.Item>
                <ListGroup.Item>0555 555 5555</ListGroup.Item>
                <ListGroup.Item>0555 555 5555</ListGroup.Item>
                <ListGroup.Item>0555 555 5555</ListGroup.Item>
                <ListGroup.Item>0555 555 5555</ListGroup.Item>
              </ListGroup>
            </Form.Group>
          </div>
        </Form>
        <Button className="mx-1 mb-1 w-100">Bilgilerimi Güncelle</Button>
      </div>
    </CustomModal>
  )
}

export default AuthModal
