import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import Guides from '../../../../../../components/guides'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import debounce from 'lodash/debounce'
import { useFormik } from 'formik'
const Index = (props: any) => {
  const { t }: any = useTranslation()
  const [count, setCount] = useState(0)

  const formik: any = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => { },
  })

  const fprmiks = props.formik ? props.formik : null
  useEffect(() => {
    if (fprmiks) {
      fprmiks.setFieldValue('message', formik.values.message)
    }
  }, [formik.values.message])


  const messageEle = document.getElementById('textarea')

  if (messageEle) {
    const debounceHandler = debounce(function (e: any) {
      const target = e.target
      const maxLength = target.getAttribute('maxlength')
      const currentLength = target.value.length
      setCount(currentLength)
    }, 300)
    messageEle.addEventListener('input', debounceHandler)
  }

  return (
    <Row className="px-1">
      <Guides />
       <Col md="6" className="mb-1">
        <div>
          <CustomSelect label={t('Zaman')} />
        </div>
      </Col>
      <Col md="6" className="mb-1">
        <div>
          <CustomSelect label={t('Şablonlar')} />
        </div>
      </Col>
      <Col md="6" className="mb- bg-">
        <div className="d-flex justify-content-between align-items-center">
          <Label>Mesaj</Label>
          <Label>
            <b style={{ fontSize: '12px' }}>1 SMS({count} Karakter)</b>
          </Label>
        </div>
        <Input
          id="textarea"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          maxlength="400"
          className='messageField'
          type="textarea"
        />
      </Col>
      <Col md="6" className="bg-">
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Gönderici Adı')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Türü')} />
          </div>
        </Col>
      </Col>
      <Row className="mt-2">
        <Col md="6" style={{ marginBottom: '10px' }}>
          <div className="d-flex align-items-center">
            <Input type="checkbox" />
            <span
              className='messageCheckbox'
            >
              {t('Mesajı Şablon Olarak Kaydet')}
            </span>
          </div>
        </Col>
      </Row>

      <Col md="12" className="mt-1">
        <div
          className="btn text-white d-flex align-items-center justify-content-center btn-block"
          style={{ backgroundColor: '#1F59AA' }}
        >
          Mesajı Gönder
          <Send className="font-medium-3 ms-2" color="white" />
        </div>
      </Col>
    </Row>
  )
}

export default Index
