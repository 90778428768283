import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import Guides from '../../../../../../../components/guides'
import * as yup from 'yup'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import debounce from 'lodash/debounce'
import { useFormik } from 'formik'

const Index = (props: any) => {
    const { t }: any = useTranslation()
    const [field, setField] = useState<any>([])
    const [count, setCount] = useState(0)

    const formik: any = useFormik({
        initialValues: {
            message: '',
        },
        validationSchema: yup.object({
            message: yup.string(),
        }),
        onSubmit: async (values) => { },
    })
    const fprmiks = props.formik ? props.formik : null
    useEffect(() => {
        if (fprmiks) {
            fprmiks.setFieldValue('message', formik.values.message)
        }
    }, [formik.values.message])
    useEffect(() => {
        if (props.columnsName) {
            setField(props.columnsName.split(','))
        }
    }, [props.columnsName])

    const messageEle = document.getElementById('textarea1212')

    if (messageEle) {
        const debounceHandler = debounce(function (e: any) {
            const target = e.target
            const maxLength = target.getAttribute('maxlength')
            const currentLength = target.value.length
            setCount(currentLength)
        }, 300)
        messageEle.addEventListener('input', debounceHandler)
    }

    return (
        <Row className="px-1">
            <Guides />
            <Col md="6" className="mb-1">
                <div>
                    <Label>WhatsApp Numarası</Label>
                    <Input disabled value={"5306556725"} />
                </div>
            </Col>
            <Col md="6" className="mb-1">
                <div>
                    <Label>Dosya Seç</Label>
                    <Input type='file' />
                </div>
            </Col>
            <Col md="6" className="mb- bg-">
                <div className="d-flex justify-content-between align-items-center">
                    <Label>Mesaj</Label>
                    <Label>
                        <b style={{ fontSize: '12px' }}>1 SMS({count} Karakter)</b>
                    </Label>
                </div>
                <Input
                    id="textarea1212"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    maxlength="400"
                    className='messageField'
                    type="textarea"
                />
            </Col>
            <Col md="6" className="bg-">
                <Col md="12" className="mb-1">
                    <div>
                        <CustomSelect label={t('Gönderim Yöntemi')} />
                    </div>
                </Col>
                <Col md="12" className="mb-1">
                    <div>
                        <CustomSelect label={t('Rehber Türü')} />
                    </div>
                </Col>
                <Col md="12" className="mb-">
                    <div>
                        <CustomSelect label={t('Tür')} />
                    </div>
                </Col>
                <Col md="12" className="mb-">
                    <div>
                        <CustomSelect label={t('Zaman')} />
                    </div>
                </Col>
            </Col>

            <Row className="d-flex justify-content-around align-items-center my-1 ">
                <Col
                    md="3"
                    style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: '#5F53DC',
                    }}
                    className="rounded-5 border btn text-white d-flex justify-content-center  text-center d-flex align-items-center"
                >
                    <b style={{ fontSize: '11px' }}> Okul No</b>
                </Col>
                <Col
                    md="3"
                    style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: '#5F53DC',
                    }}
                    className="rounded-5 border d-flex justify-content-center  btn text-white  text-center d-flex align-items-center"
                >
                    <b style={{ fontSize: '11px' }}>Ad Soyad</b>
                </Col>
                <Col
                    md="3"
                    style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: '#5F53DC',
                    }}
                    className="rounded-5 border btn text-white d-flex justify-content-center  text-center d-flex align-items-center"
                >
                    <b className="" style={{ fontSize: '11px' }}>
                        Veli Adı
                    </b>
                </Col>
                <Col
                    md="3"
                    style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor: '#5F53DC',
                    }}
                    className="rounded-5 border btn text-white d-flex justify-content-center  text-center d-flex align-items-center"
                >
                    <b className="" style={{ fontSize: '11px' }}>
                        Tarih
                    </b>
                </Col>
            </Row>

            <Row className="d-flex justify-content-around align-items-center mb-1 ">
                {field.map((item: any, i: number) => {
                    return (
                        <Col
                            onClick={() => {
                                formik.setFieldValue(
                                    'message',
                                    formik.values.message + `[${item}]`
                                )
                            }}
                            key={i}
                            xs="12"
                            md="4"
                            lg="3"
                            style={{
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                backgroundColor: '#FC5661',
                            }}
                            className="rounded-5 mb-1 border btn text-white d-flex justify-content-center  text-center d-flex align-items-center"
                        >
                            <b style={{ fontSize: '11px' }}> {item}</b>
                        </Col>
                    )
                })}
            </Row>

            <Col md="12" style={{ marginTop: '5px' }} className="m">
                <div
                    className="btn text-white d-flex align-items-center justify-content-center btn-block"
                    style={{ backgroundColor: '#1F59AA' }}
                >
                    Mesajı Gönder
                    <Send className="font-medium-3 ms-2" color="white" />
                </div>
            </Col>
        </Row>
    )
}

export default Index
