import { ReactNode } from 'react'

const Page = ({
  children,
  display,
  overflow = 'visible',
  justifyContent,
  maxHeight = 350,
  padding,
  minHeight,
  width,
  alignItems,
}: {
  children: ReactNode
  padding?: number
  display?: string
  overflow?: string
  height?: string | number
  width?: number | string
  minHeight?: number | string
  maxHeight?: number | string
  justifyContent?: string
  alignItems?: string
}) => {
  return (
    <div
      style={{
        height: '100%',
        minHeight: minHeight,
        overflow: overflow,
        justifyContent: justifyContent,
        alignItems: alignItems,
        scrollbarWidth: 'thin',
      }}
    >
      {children}
    </div>
  )
}

export default Page
