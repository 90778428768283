import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { SiMicrosoftexcel } from 'react-icons/si'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import DailyAbsenteeism from './dailyAbsenteeism/index'
import MassAbsenteeism from './massAbsenteeism/index'
import EschoolStyle from './eSchoolStyle/index'
import SchoolNumber from './schoolNuumber/index'
import Epolling from './ePolling/index1'

const index = () => {
  const { t }: any = useTranslation()
  const [active, setActive] = useState(1)
  const [activeType, setActiveType] = useState(1)
  const toggleTab = (tab: any) => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const toggleTabType = (tab: any) => {
    if (activeType !== tab) {
      setActiveType(tab)
    }
  }
  const formik: any = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {},
  })
  useEffect(() => {
    formik.resetForm()
  }, [active, activeType])
  return (
    <div className="fontFamily">
      <Nav
        style={{ width: '100%', backgroundColor: '#FFFFFF' }}
        pills
        className="flex-wrap  "
      >
        <NavItem className="" style={{ width: '16%', marginRight: 'px' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#1F59AA',
              backgroundColor: active === 1 ? '#1F59AA' : 'inherit',
              color: active === 1 ? 'white' : '#1F59AA',
            }}
            className="border-2  rounded"
            active={active === 1}
            onClick={() => {
              toggleTab(1)
            }}
          >
            <span className="fw-bold">{t('E-Yoklama')}</span>
          </NavLink>
        </NavItem>

        <NavItem className="mx-1" style={{ width: '15%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#1F59AA',
              backgroundColor: active === 3 ? '#1F59AA' : 'inherit',
              color: active === 3 ? 'white' : '#1F59AA',
            }}
            className="border-  border-2  rounded"
            active={active === 3}
            onClick={() => {
              toggleTab(3)
            }}
          >
            <span className="fw-bold">{t('Günlük Devamsızlık')}</span>
          </NavLink>
        </NavItem>
        <NavItem className="mx-1" style={{ width: '16%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#1F59AA',
              color: active === 2 ? 'white' : '#1F59AA',
              backgroundColor: active === 2 ? '#1F59AA' : 'inherit',
            }}
            className="border-  border-2  rounded"
            active={active === 2}
            onClick={() => {
              toggleTab(2)
            }}
          >
            <span className="fw-bold">{t('E-Okul Tarzı İle')}</span>
          </NavLink>
        </NavItem>
        <NavItem className="mx-1" style={{ width: '16%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#1F59AA',
              color: active === 4 ? 'white' : '#1F59AA',
              backgroundColor: active === 4 ? '#1F59AA' : 'inherit',
            }}
            className="border-  border-2  rounded"
            active={active === 4}
            onClick={() => {
              toggleTab(4)
            }}
          >
            <span className="fw-bold">{t('Okul No ile')}</span>
          </NavLink>
        </NavItem>
        <NavItem className="mx-1" style={{ width: '15.4%' }}>
          <NavLink
            style={{
              marginTop: '3px',
              marginBottom: '3px',
              borderColor: '#1F59AA',
              color: active === 5 ? 'white' : '#1F59AA',
              backgroundColor: active === 5 ? '#1F59AA' : 'inherit',
            }}
            className="border-  border-2  rounded"
            active={active === 5}
            onClick={() => {
              toggleTab(5)
            }}
          >
            <span className="fw-bold">{t('Toplu Devamsızlık')}</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent
        style={{ display: activeType === 2 ? 'none' : 'grid' }}
        activeTab={active}
      >
        <TabPane tabId={1}>
          <>
            <Epolling />
          </>
        </TabPane>

        <TabPane tabId={3}>
          <DailyAbsenteeism />
        </TabPane>
        <TabPane tabId={2}>
          <div>
            <EschoolStyle formik={formik} />
          </div>
        </TabPane>
        <TabPane tabId={4}>
          <div>
            <SchoolNumber formik={formik} />
          </div>
        </TabPane>
        <TabPane tabId={5}>
          <MassAbsenteeism />
        </TabPane>
      </TabContent>
    </div>
  )
}

export default index
