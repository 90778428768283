import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IForgorPasswordConfirm } from './model'
import { forgotPasswordConfirm, sendForgotPassword } from './service'

export const sendForgotPasswordHook = () => {
  return useMutation(
    ['send-forgot-password'],
    async (phone: string) =>
      await sendForgotPassword(phone)
        .then((res: any) => res.data)
        .catch((err) => Promise.reject(err.response.data)),
    {
      onSuccess: (res) => res,
      onError: (err) => err,
    }
  )
}

export const forgotPasswordConfirmHook = () => {
  return useMutation(
    ['forgot-password-confirm'],
    async (data: IForgorPasswordConfirm) =>
      await forgotPasswordConfirm(data)
        .then((res: any) => res.data)
        .catch((err) => Promise.reject(err.response.data)),
    {
      onSuccess: (res) => res,
      onError: (err) => err,
    }
  )
}


