import { useState } from 'react'
import { FcBusinessman, FcBusinesswoman, FcReading } from 'react-icons/fc'
import { GiCheckMark } from 'react-icons/gi'
import { Col } from 'reactstrap'

const index = () => {
  const [isClicked1, setIsClicked1] = useState(true)
  const [isClicked2, setIsClicked2] = useState(false)
  const [isClicked3, setIsClicked3] = useState(false)
  const handleClick1 = () => {
    if (isClicked2 === false && isClicked3 === false) {
      setIsClicked1(true)
    } else {
      setIsClicked1(!isClicked1)
    }
  }

  const handleClick2 = () => {
    if (isClicked1 === false && isClicked3 === false) {
      setIsClicked2(true)
    } else {
      setIsClicked2(!isClicked2)
    }
  }

  const handleClick3 = () => {
    if (isClicked1 === false && isClicked2 === false) {
      setIsClicked3(true)
    } else {
      setIsClicked3(!isClicked3)
    }
  }
  return (
    <Col md="12" className="mb-1">
      <div
        style={{ width: '100%' }}
        className="d-flex align-items-center justify-content-between "
      >
        <div
          onClick={handleClick1}
          style={{
            cursor: 'pointer',
            paddingTop: '10px',
            paddingBottom: '10px',
            width: '30%',
            border: '2px solid #7367F0',
            backgroundColor: isClicked1 ? '#7367F0' : 'transparent',
            color: isClicked1 ? 'white' : '#7367F0',
          }}
          className={` px-1  d-flex justify-content-center align-items-center rounded-2 ${
            isClicked1 ? 'position-relative' : ''
          }`}
        >
          {isClicked1 && (
            <GiCheckMark
              color="#96D56C"
              size={'35px'}
              style={{
                position: 'absolute',
                top: '-11px',
                left: '-7px',
                fontSize: '25px',
              }}
            />
          )}
          <FcBusinessman style={{ fontSize: '20px' }} />
          <span className="">1.Veli</span>
        </div>
        <div
          onClick={handleClick2}
          style={{
            cursor: 'pointer',
            paddingTop: '10px',
            paddingBottom: '10px',
            width: '30%',
            border: '2px solid #7367F0',
            backgroundColor: isClicked2 ? '#7367F0' : 'transparent',
            color: isClicked2 ? 'white' : '#7367F0',
          }}
          className={`justify-content-center px-1 d-flex align-items-center rounded-2 ${
            isClicked2 ? 'position-relative' : ''
          }`}
        >
          {isClicked2 && (
            <GiCheckMark
              color="#96D56C"
              size={'35px'}
              style={{
                position: 'absolute',
                top: '-11px',
                left: '-7px',
                fontSize: '25px',
              }}
            />
          )}
          <FcBusinesswoman style={{ fontSize: '20px' }} />
          <span className="">2.Veli</span>
        </div>
        <div
          onClick={handleClick3}
          style={{
            cursor: 'pointer',
            paddingTop: '10px',
            paddingBottom: '10px',
            width: '30%',
            border: '2px solid #7367F0',
            backgroundColor: isClicked3 ? '#7367F0' : 'transparent',
            color: isClicked3 ? 'white' : '#7367F0',
          }}
          className={`justify-content-center d-flex align-items-center  px-1 rounded-2 ${
            isClicked3 ? 'position-relative' : ''
          }`}
        >
          {isClicked3 && (
            <GiCheckMark
              color="#96D56C"
              size={'35px'}
              style={{
                position: 'absolute',
                top: '-11px',
                left: '-7px',
                fontSize: '25px',
              }}
            />
          )}
          <FcReading style={{ fontSize: '20px' }} />
          <span className="">Öğrenci</span>
        </div>
      </div>
    </Col>
  )
}
export default index
