import Page from '../Page'
import { ReactNode, useState } from 'react'
import { MdEdit } from 'react-icons/md'
import images from '../../../../../../@core/assets/placeholder.jpg'
import Image from 'react-bootstrap/Image'

const AccountInfo = () => {
  const [image, setImage] = useState<any>(images)

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]))
    }
  }

  const name = 'Borusan Asım Kocabıyık Mesleki ve Teknik Anadolu Lisesi'

  return (
    <Page display="flex" minHeight={300}>
      <div className="d-flex flex-column w-100" style={{ maxWidth: 600 }}>
        <Content>
          <div className="d-flex mb-1">
            <div
              style={{
                gridRow: 1,
                gridColumn: 1,
              }}
            >
              <Image src={image} height={100} width={100} rounded />
            </div>
            <ChoosePhotoPen onImageChange={onImageChange} />
          </div>
          <h2 className="w-100 text-center">{name}</h2>
        </Content>
        <hr className="mt-0 width-auto text-secondary" />
        <div className="d-flex align-bottom">
          <InfoBox title="Temsilci" text="Onur" textColor="primary" />
          <InfoBox title="Numara" text="02129092554" textColor="primary" />
          <InfoBox title="Durum" text="Aktif" textColor="success" />
        </div>
      </div>
    </Page>
  )
}

export default AccountInfo

const Content = ({ children }: { children?: ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: 15,
      }}
    >
      {children}
    </div>
  )
}

const InfoBox = ({ title, text, textColor = 'primary' }: InfoBoxProps) => {
  return (
    <div
      className="text-center"
      style={{
        display: 'flex',
        width: '33%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <h4 className="text-secondary">{title}</h4>
      <hr className="mt-0 width-100 text-secondary" />
      <h4 className={`mb-2 text-${textColor}`}>{text}</h4>
    </div>
  )
}

interface InfoBoxProps {
  text: string
  title: string
  textColor?: 'success' | 'warning' | 'primary'
}

const ChoosePhotoPen = ({ onImageChange }: { onImageChange: any }) => {
  return (
    <div style={{ gridRow: 1, gridColumn: 1 }}>
      <input
        onChange={onImageChange}
        id="files"
        type="file"
        className="hidden"
      />
      <label htmlFor="files" style={{ transform: 'translate(-15px, 80px)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            borderRadius: '50%',
            backgroundColor: '#ede9fe',
            padding: 5,
          }}
        >
          <MdEdit size={20} className="text-primary" />
        </div>
      </label>
    </div>
  )
}
