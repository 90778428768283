import { useState } from 'react'
import { Plus } from 'react-feather'
import { IoMdTrash } from 'react-icons/io'
import { SiMicrosoftexcel } from 'react-icons/si'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomTable from 'src/views/components/customTable/customTable'

const index = () => {
  const [modal, setModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const columns = [
    {
      name: (
        <>
        <Input type='checkbox'></Input>
        </>
      ),
      sortable: true,
      selector: (row: { selected: any }) => row.selected,
    },
    {
      name: 'Rehber Türü',
      sortable: true,
      selector: (row: { guidename: any }) => row.guidename,
    },
    {
      name: 'İşlemler',
      sortable: true,
      selector: (row: { operations: any }) => row.operations,
    },
  ]
  const guidename = [
    {
      guidename: 'Ana Rehber',
      selected:false
    },
    {
      guidename: 'sa',
      selected:false
    },
    {
      guidename: 'Lise',
      selected:false
    },
  ]
  const data = guidename.map((item: any) => {
    return {
      guidename: item.guidename,
      operations: (
        <div className='d-flex justify-content-center align-items-center'>
          <div onClick={()=>{setUpdateModal(true)}} className="btn btn-warning mx-1">Güncelle</div>
          <div onClick={()=>{setDeleteModal(true)}} className="btn btn-danger">Sil</div>
        </div>
      ),
      selected:(
        <>
        <Input type='checkbox'></Input>
        </>
      )
    }
  })
  return (
    <Row>
      <Col md="12">
        <CustomTable columns={columns} data={data}>
          <Button
            onClick={() => {
              setModal(true)
            }}
            className="btn btn-success d-flex justify-content-center align-items-center"
          >
            <Plus />
            <span>Yeni Rehber Türü Kaydı</span>
          </Button>
        </CustomTable>
      </Col>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        >
          <h3>Yeni Rehber Türü Kaydı</h3>
        </ModalHeader>
        <ModalBody>
          <Row md="6" className="d-flex justify-content-center">
            <Col className="mb-1 d-flex justify-content-start" md="12">
              <ul>
                <li>
                  Okul Numarası çakışan durumlarda kullanılabilecek bu modül,
                  tüm sms ekranlarına entegre çalışır.
                </li>
                <li className='my-1'>
                  Bu Bölümde açacağınız yeni Rehber Türlerine (Okul Türlerine)
                  kayıtlı Grup/Sınıflarınızı tanımlayabilirsiniz.
                </li>
                <li className='my-1'>
                  Alt Kullanıcılarınıza da bu Rehber Türlerinden birini
                  atayabilirsiniz. Bu işlemi yaptığınızda alt kullanıcı sadece
                  bu kayda bağlı Sınıf/Grupları görüntüleyebilir. Dilerseniz tek
                  sınıf seçerek bu kısıtlamayı daha da daraltabilirsiniz.
                </li>
                <li className='my-1'>
                  Bünyenizde 2 ayrı okul var; ANADOLU LİSESİ ve SOSYAL BİLİMLER
                  LİSESİ. Bu okullarda aynı okul numarasına sahip öğrenciler
                  var. Devamsızlık gönderirken öğrenciler çakışıyor, yanlış
                  öğrenciye ya da bazen her çakışan öğrenciye aynı mesajı
                  gönderiyosunuz. Bu özelliğimizle karışıklığa son! Okullarınızı
                  ANADOLU LİSESİ ve SOSYAL LİSE gibi iki farklı Rehber Türü
                  kaydıyla ayırabilir, daha sonra rehberinizde kayıtlı bu
                  sınıfları ilgili Rehber Türüne (Okul Türüne) atayabilirsiniz.
                </li>
                <li>
                Tüm Devamsızlık, Sınav Sonucu, Okul Numarasına SMS, Özel Tasarım ve Genel SMS Modüllerinde filtreyi sınırlandırarak çakışmaları böylelikle engelleyebilirsiniz.
                </li>
              </ul>
            </Col>
            <Col className="mb-1" md="8">
              <Label>Açıklama</Label>
              <Input placeholder="Açıklama" />
            </Col>
            <Col className="d-flex justify-content-center" md="8">
              <Button
                onClick={() => {
                  setModal(false)
                }}
                className="btn-block btn-success"
              >
                Kaydet
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={updateModal}
        toggle={() => setUpdateModal(!updateModal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center "
          closeButton
          toggle={() => setUpdateModal(!updateModal)}
        >
        </ModalHeader>
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col md="8">
            <Label >Rehber Türü</Label>
              <Input type='text' placeholder='Rehber Türü' />
            </Col>
          
            <Col md="8" className="d-flex justify-content-center my-1">
              <Button onClick={()=>setUpdateModal(false)} className="btn btn-block d-flex btn-danger">
                Güncelle
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-md py-1"
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
      >
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col className="my-1 d-flex justify-content-center " md="12">
            <IoMdTrash fontSize={"120px"} color='red' />
            </Col>
            <Col className="my-1 d-flex justify-content-center" md="12">
            <h1>
            Emin misiniz?
            </h1>
            </Col>
            <Col md="12" className="d-flex justify-content-around">
              
              <Button onClick={()=>setDeleteModal(false)} className="btn btn-block d-flex btn-danger">
               Evet,Sil
              </Button>
              <Button onClick={()=>setDeleteModal(false)} className="btn btn-block d-flex btn-success">
                Vazgeç
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Row>
  )
}

export default index
