export const emailMess = 'Please enter valid e-mail address.'
export const minLength = 'Password cannot be less than 6 characters.'
export const maxPhoneLength = 'Phone cannot exceed 10 characters'
export const minPhoneLength = 'Phone cannot be less than 10 characters'
export const nationalMess = 'This field must have 11 characters'
export const nationalNumber = 'Letters cannot be entered in this field!'
export const required = 'This field is required.'
export const RqErrorMessage = (errors: any, touched: any) => {
  return (
    <div style={{ color: 'red', zIndex: 1 }}>
      {errors && touched ? errors : ''}
    </div>
  )
}
