import { ReactNode, useState } from 'react'
import Page from '../Page'
import { MdSupportAgent, MdMail, MdPhone } from 'react-icons/md'

const Contact = () => {
  const [showMore, setShowMore] = useState(false)
  const support =
    '7/24 Destek talepleriniz, bize göndermeniz gereken tüm belgeler ve iletileriniz için bu mail adresimizi kullabilirsiniz. Aktivasyon ile ilgili evraklar, Ödeme bildirimi, Excel liste yükleme sorunları, Fiyat teklifi, Referans, Öneri ve Şikayetler...'
  const contact =
    'Mesai saatlerimizde canlı destek talepleriniz için bize ulaşabilirsiniz. Diğer saatlerde lütfen mail veya İLETİŞİM sayfamızdaki iletişim formu üzerinde bizimle irtibata geçiniz.'
  const liveSupport =
    'Özellikle Excel telefon listesi yükleme ve Excel özel tasarım SMS gönderim / kullanım sorunlarınız için bilgisayarınıza bağlantı kurarak destek sağlayabiliriz.'

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        padding: 5,
      }}
    >
      <Component
        icon={<MdMail className="text-primary" size={75} />}
        title="destek@ilksms.com"
        description={liveSupport}
        onClick={setShowMore}
        showMore={showMore}
      />
      <Component
        icon={<MdPhone className="text-primary" size={75} />}
        title="0212 909 25 54"
        description={liveSupport}
        onClick={setShowMore}
        showMore={showMore}
      />
      <Component
        icon={<MdSupportAgent className="text-primary" size={75} />}
        title="Uzak Bağlantı İle Destek"
        description={liveSupport}
        onClick={setShowMore}
        showMore={showMore}
      />
    </div>
  )
}

export default Contact

const Component = ({
  icon,
  title,
  description,
  onClick,
  color = '#6F6B7C',
  showMore,
}: {
  icon: ReactNode
  title: string
  description: string
  onClick?: any
  showMore?: boolean
  color?: string
}) => {
  return (
    <div
      onClick={() => onClick(!showMore)}
      style={{
        width: '33%',
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        margin: 5,
        flexDirection: 'column',
        borderRadius: 15,
        backgroundColor: '#ede9fe',
      }}
    >
      {icon}
      <p
        className="text-primary"
        style={{
          margin: 0,
          padding: 0,
          marginLeft: 10,
          color: 'black',
          fontSize: 18,
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: 10,
        }}
      >
        {title}
      </p>
      <p
        style={{
          margin: 0,
          padding: 0,
          marginLeft: 10,
          color: color,
          fontSize: 13,
          textAlign: 'center',
        }}
      >
        {description}
      </p>
    </div>
  )
}
