import React from 'react'
import Page from '../Page'
import TableComponent from './table.js'
import Button from 'react-bootstrap/esm/Button'
import Form from 'react-bootstrap/Form'

const Birthdays = () => {
  return (
    <TableComponent
      rows={data}
      columns={[
        'Kayıt Türü',
        'Okul No',
        'Sınıf',
        'Doğum Tarihi',
        'Ad Soyad',
        'Durum',
        'Seç',
        'Seçilenlere Gönder',
      ]}
    />
  )
}

export default Birthdays

const data = [
  createData(
    'Öğretmen',
    '183',
    '9-a',
    '16/02/1980',
    'Ali Veli',
    'Gönderilemedi',
    <Form.Check />,
    <Button>Gönder</Button>
  ),
  createData(
    'Öğrenci',
    '178',
    '10-c',
    '16/02/1980',
    'Murat Can',
    'Gönderildi',
    <Form.Check />,
    <Button>Gönder</Button>
  ),
  createData(
    'Öğrenci',
    '178',
    '10-c',
    '16/02/1980',
    'Murat Can',
    'Gönderildi',
    <Form.Check />,
    <Button>Gönder</Button>
  ),
  createData(
    'Öğrenci',
    '178',
    '10-c',
    '16/02/1980',
    'Murat Can',
    'Gönderildi',
    <Form.Check />,
    <Button>Gönder</Button>
  ),
  createData(
    'Öğrenci',
    '178',
    '10-c',
    '16/02/1980',
    'Murat Can',
    'Gönderildi',
    <Form.Check />,
    <Button>Gönder</Button>
  ),
]

function createData(
  date: string,
  customer: string,
  phone: string,
  employee: string,
  service: string,
  note: string,
  select: any,
  button: any
) {
  return {
    date,
    customer,
    phone,
    employee,
    service,
    note,
    select,
    button,
  }
}
