import { useState } from 'react'
import { FcExpand } from 'react-icons/fc'
import ScrollBar from 'react-perfect-scrollbar'
import { Scrollbar } from 'react-scrollbars-custom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import CustomTable from 'src/views/components/customTable/customTable'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

const index = () => {
  const { t }: any = useTranslation()
  const [childrensModal, setChildrensModal] = useState(false)
  const [modal,setModal] = useState(false)
  const [childrens, setChildrens] = useState<any>([
    {
      schoolNumber: '123',
      nameSurname: 'Can Aslan Oktay can',
      overall: false,
      fullDay: false,
      halfDay: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '124',
      nameSurname: 'Meltem Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '125',
      nameSurname: 'Salih Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '126',
      nameSurname: 'Yusuf Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '126',
      nameSurname: 'Yusuf Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '126',
      nameSurname: 'Yusuf Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '126',
      nameSurname: 'Yusuf Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '126',
      nameSurname: 'Yusuf Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '126',
      nameSurname: 'Yusuf Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '126',
      nameSurname: 'Yusuf Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
    {
      schoolNumber: '126',
      nameSurname: 'Yusuf Aslan',
      overall: false,
      fullDay: false,
      halfDay: false,
      phones: false,
      arrivedLate: false,
      beforeNoon: false,
      afterNoon: false,
      schoolCourse: false,
      reports: false,
      onLeave: false,
    },
  ])

  const scrollAreaTable = {
    width: '100%',
    height: '500px',
    overflow: 'scroll',
    padding: '10px',
  }
  const data = [
    {
      name: '8/A Sınıfı',
    },
    {
      name: '8/B Sınıfı',
    },
    {
      name: '8/C Sınıfı',
    },
    {
      name: '8/D Sınıfı',
    },
    {
      name: '8/E Sınıfı',
    },
    {
      name: '8/F Sınıfı',
    },
    {
      name: '8/G Sınıfı',
    },
    {
      name: '8/H Sınıfı',
    },
  ]
  const columns = [
    {
      name: (
        <p className="text-center">
          Okul <br /> No
        </p>
      ),
      sortable: true,
      selector: (row: { schoolNumber: string }) => row.schoolNumber,
      width: '100px',
    },
    {
      name: (
        <p className="text-center">
          Ad <br /> Soyad
        </p>
      ),
      sortable: true,
      selector: (row: { nameSurname: string }) => row.nameSurname,
      width: '200px',
    },
    {
      name: t('Genel'),
      sortable: true,
      selector: (row: { overall: string }) => row.overall,
    },
    {
      name: (
        <p className="text-center">
          Tam <br /> Gün
        </p>
      ),
      sortable: true,
      selector: (row: { fullDay: string }) => row.fullDay,
    },
    {
      name: (
        <p className="text-center">
          Yarım <br /> Gün
        </p>
      ),
      sortable: true,
      selector: (row: { halfDay: string }) => row.halfDay,
    },
    {
      name: (
        <p className="text-center">
          Geç <br /> Kaldı
        </p>
      ),
      sortable: true,
      selector: (row: { arrivedLate: string }) => row.arrivedLate,
    },
    {
      name: (
        <p className="text-center">
          Öğleden <br /> Önce
        </p>
      ),
      sortable: true,
      selector: (row: { beforeNoon: string }) => row.beforeNoon,
    },
    {
      name: (
        <p className="text-center">
          Öğleden <br /> Sonra
        </p>
      ),
      sortable: true,
      selector: (row: { afterNoon: string }) => row.afterNoon,
    },
    {
      name: (
        <p className="text-center">
          Okul <br /> Kursu
        </p>
      ),
      sortable: true,
      selector: (row: { schoolCourse: string }) => row.schoolCourse,
    },
    {
      name: t('Raporlu'),
      sortable: true,
      selector: (row: { reports: string }) => row.reports,
    },
    {
      name: t('İzinli'),
      sortable: true,
      selector: (row: { onLeave: string }) => row.onLeave,
    },
  ]

  const updateState = (index: number, field: string) => {
    const newArray = [...childrens]
    const updatedItem = { ...newArray[index], [field]: !newArray[index][field] }
    if (updatedItem[field]) {
      Object.keys(updatedItem).forEach((key) => {
        if (typeof updatedItem[key] === 'boolean') {
          if (key !== field) {
            updatedItem[key] = false
          }
        }
      })
    }
    newArray[index] = updatedItem
    setChildrens(newArray)
  }
  const filter = [
    { i: 1, name: 'Okul Numarasına Göre' },
    { i: 2, name: 'Öğrenci Adına Göre' },
  ]
  const filtered = filter.map((item: any) => {
    return {
      id: item.i,
      name: item.name,
    }
  })
  function remove(i: number) {
    const newArray = [...childrens]
    for (const key in newArray[i]) {
      if (typeof newArray[i][key] === 'boolean') {
        newArray[i][key] = false
      }
    }
    setChildrens(newArray)
  }

  const students = childrens.map((item: any, index: number) => {
    return {
      schoolNumber: item.schoolNumber,
      nameSurname: item.nameSurname,
      overall: (
        <>
          <Input
            onChange={() => updateState(index, 'overall')}
            type="checkbox"
            checked={item.overall}
          ></Input>
        </>
      ),
      fullDay: (
        <>
          <Input
            onChange={() => updateState(index, 'fullDay')}
            type="checkbox"
            checked={item.fullDay}
          ></Input>
        </>
      ),
      halfDay: (
        <>
          <Input
            onChange={() => updateState(index, 'halfDay')}
            type="checkbox"
            checked={item.halfDay}
          ></Input>
        </>
      ),
      arrivedLate: (
        <>
          <Input
            onChange={() => updateState(index, 'arrivedLate')}
            type="checkbox"
            checked={item.arrivedLate}
          ></Input>
        </>
      ),
      beforeNoon: (
        <>
          <Input
            onChange={() => updateState(index, 'beforeNoon')}
            type="checkbox"
            checked={item.beforeNoon}
          ></Input>
        </>
      ),
      afterNoon: (
        <>
          <Input
            onChange={() => updateState(index, 'afterNoon')}
            type="checkbox"
            checked={item.afterNoon}
          ></Input>
        </>
      ),
      schoolCourse: (
        <>
          <Input
            onChange={() => updateState(index, 'schoolCourse')}
            type="checkbox"
            checked={item.schoolCourse}
          ></Input>
        </>
      ),
      reports: (
        <>
          <Input
            onChange={() => updateState(index, 'reports')}
            type="checkbox"
            checked={item.reports}
          ></Input>
        </>
      ),
      onLeave: (
        <>
          <Input
            onChange={() => updateState(index, 'onLeave')}
            type="checkbox"
            checked={item.onLeave}
          ></Input>
        </>
      ),
    }
  })

  return (
    <>
      <Row>
        <Col md="12" className="d-flex  justify-content-between align-items-end">
          <Col md="6" className="">
            <Label className='' >Şuna Göre Sırala</Label>
            <SelectOptions options={filtered} />
          </Col>

          <Col md="6" className=" text-end  mb-1">
            <div onClick={()=>{
              setModal(true)
            }} style={{width:"75%",backgroundColor:"#1F59AA"}} className="btn btn-block text-white "> Bilgi</div>
          </Col>
        </Col>

        <Col md="12" className="my-1">
          <Scrollbar className="" style={{ width: '100%', height: 355 }}>
            {data.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setChildrensModal(true)
                }}
                style={{
                  width: '100%',
                  paddingTop: '9px',
                  paddingBottom: '9px',
                }}
                className="my-1 d-flex justify-content-between border border-2 rounded  "
              >
                <div
                  style={{ width: '90%', cursor: 'pointer' }}
                  className="mx-1"
                >
                  <span>{item.name}</span>
                </div>

                <div
                  onClick={() => {
                    setChildrensModal(true)
                  }}
                  style={{ cursor: 'pointer' }}
                  className="mx-1 "
                >
                  <FcExpand
                    className="text-dark"
                    style={{ fontSize: '20px' }}
                  />
                </div>
              </div>
            ))}
          </Scrollbar>
        </Col>
      </Row>
      <Modal
        className="modal-dialog-custom "
        isOpen={childrensModal}
        toggle={() => setChildrensModal(!childrensModal)}
      >
        <ModalHeader
          style={{ backgroundColor: '#1F59AA' }}
          className=" d-flex justify-content-center align-items-center text-center"
          closeButton
          toggle={() => {
            setChildrensModal(!childrensModal)
          }}
        >
          <div className="text-white">
            8/A Sınıfı ({students.length} Listeleniyor)
          </div>
        </ModalHeader>
        <ModalBody>
          <CustomTable
            notPage
            style={scrollAreaTable}
            data={students}
            columns={columns}
          />
          <Col className="d-flex justify-content-center align-items-center">
            <Button
              onClick={() => {
                setChildrensModal(false)
              }}
              className=" btn btn-success  mx-1 "
            >
              Onayla
            </Button>
          </Col>
        </ModalBody>
      </Modal>
      <Modal
  className="modal-dialog-centered modal-lg "
  isOpen={modal}
  toggle={() => setModal(!modal)}
>
  <ModalHeader
    className="d-flex justify-content-center align-items-center text-center bg-transparent"
    closeButton
    onClick={() => setModal(!modal)}
  >
    <div className="text-black">
      <AiOutlineQuestionCircle size={100} color="black" />
    </div>
  </ModalHeader>
  <ModalBody className="text-center px-1  ">
    <div className=' px-1 p ' >
      <h4>Gönderilecek Mesaj Örnekleri</h4>
    </div>
    <div className='px-1 py-1'>
      <p>
      <b  >Genel:</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde devamsızlık yapmıştır.
      </p>
      <p>
      <b>Tam Gün:</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde tam gün devamsızlık yapmıştır.
      </p>
      <p>
      <b>Öğleden Önce:</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde öğleden önce devamsızlık yapmıştır.
      </p>
      <p>
      <b>Öğleden Sonra: </b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde öğleden sonra devamsızlık yapmıştır.
      </p>
      <p>
      <b>Geç Kaldı:</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde derse geç kalmıştır.
      </p>
      <p>
      <b>Yarım Gün:</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde yarım gün devamsızlık yapmıştır.
      </p>
      <p>
      <b>Okul Kursu:</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde okul kursuna katılmamıştır.
      </p>
      <p>
      <b>İzinli:</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde izinli devamsızlık yapmıştır.
      </p>
      <p>
      <b>Raporlu:</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde raporlu devamsızlık yapmıştır.
      </p>
      <p>
      <b>OKUNDU BİLGİSİ</b> Sayın ESRA SOYLU, 96 numaralı öğrenciniz SELİN SOYLU 16.01.2024 tarihinde devamsızlık yapmıştır.
      </p>
    </div>
  </ModalBody>
</Modal>
    </>
  )
}

export default index
