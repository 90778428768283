import { useState } from 'react'
import { Plus } from 'react-feather'
import { IoMdTrash } from 'react-icons/io'
import { SiMicrosoftexcel } from 'react-icons/si'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomTable from 'src/views/components/customTable/customTable'

const index = () => {
  const [modal, setModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const columns = [
    {
      name: 'Eklendiği Tarih',
      sortable: true,
      selector: (row: { date: any }) => row.date,
    },
    {
      name: 'Telefon',
      sortable: true,
      selector: (row: { phone: any }) => row.phone,
    },
    {
      name: 'Açıklama',
      sortable: true,
      selector: (row: { explanation: any }) => row.explanation,
    },
    {
      name: 'Sil',
      sortable: true,
      selector: (row: { delete: any }) => row.delete,
    },
  ]
  const phone = [
    {
      date: '12/21/2024',
      phone: '5306556725',
      explanation: 'Test',
    },
    {
      date: '12/21/2024',
      phone: '5306556725',
      explanation: 'Test1',
    },
    {
      date: '12/21/2024',
      phone: '5306556725',
      explanation: 'Test2',
    },
    {
      date: '12/21/2024',
      phone: '5306556725',
      explanation: 'Test3',
    },
  ]
  const data = phone.map((item: any) => {
    return {
      date: item.date,
      phone: item.phone,
      explanation: item.explanation,
      delete: (
        <div className="d-flex justify-content-center align-items-center">
          <div
            onClick={() => {
              setDeleteModal(true)
            }}
            className="btn btn-danger"
          >
            Sil
          </div>
        </div>
      ),
    }
  })
  return (
    <Row>
      <Col className="d-flex justify-content-between mb-1" md="12">
        <Button className="btn btn-success">Excele Aktar</Button>
        <Input style={{ width: '200px' }} placeholder="Arama"></Input>
      </Col>
      <Col md="12">
        <CustomTable columns={columns} data={data}>
          <Button
            onClick={() => {
              setModal(true)
            }}
            className="btn btn-success d-flex justify-content-center align-items-center"
          >
            <Plus />
            <span>Kara Listeye Ekle</span>
          </Button>
        </CustomTable>
      </Col>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        >
          <h3>Kara Listeye Ekle</h3>
        </ModalHeader>
        <ModalBody>
          <Row md="6" className="d-flex justify-content-center">
            <Col className="mb-1" md="8">
              <Label>Numara</Label>
              <Input
                type="textarea"
                placeholder="Engellenecek Telefon Numaralarını \n Numaraları alt alta veya aralarına virgül koyarak yazabilirsiniz.
 "
              />
            </Col>
            <Col className="mb-1" md="8">
              <Label>Açıklama</Label>
              <Input placeholder="Açıklama" />
            </Col>
            <Col className="d-flex justify-content-center" md="8">
              <Button
                onClick={() => {
                  setModal(false)
                }}
                className="btn-block btn-success"
              >
                Kara Listeye Ekle
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={updateModal}
        toggle={() => setUpdateModal(!updateModal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center "
          closeButton
          toggle={() => setUpdateModal(!updateModal)}
        ></ModalHeader>
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col md="8">
              <Label>Rehber Türü</Label>
              <Input type="text" placeholder="Rehber Türü" />
            </Col>

            <Col md="8" className="d-flex justify-content-center my-1">
              <Button
                onClick={() => setUpdateModal(false)}
                className="btn btn-block d-flex btn-danger"
              >
                Güncelle
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-md py-1"
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
      >
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col className="my-1 d-flex justify-content-center " md="12">
              <IoMdTrash fontSize={'120px'} color="red" />
            </Col>
            <Col className="my-1 d-flex justify-content-center" md="12">
              <h1>Emin misiniz?</h1>
            </Col>
            <Col md="12" className="d-flex justify-content-around">
              <Button
                onClick={() => setDeleteModal(false)}
                className="btn btn-block d-flex btn-danger"
              >
                Evet,Sil
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                className="btn btn-block d-flex btn-success"
              >
                Vazgeç
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Row>
  )
}

export default index
