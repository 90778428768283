import { ReactNode, useState, useEffect } from 'react'
import { Table } from 'reactstrap'

const TableComponent = ({ columns, rows }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })
  return (
    <Table
      style={{ width: (windowWidth * 65) / 100 - 40 }}
      responsive
      striped
      hover
    >
      <thead>
        <tr
          style={{
            backgroundColor: 'red',
            position: 'sticky',
            zIndex: 100,
            top: 0,
          }}
        >
          {columns.map((column) => (
            <th
              className="text-black text-center"
              style={{ backgroundColor: '#ede9fe' }}
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.values(rows).map((value) => (
          <tr>
            {Object.values(value).map((data, index) => (
              <td>
                <p className="text-center" key={index}>
                  {data}
                </p>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default TableComponent
