import React, { useState } from 'react'
import { Plus } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import SelectOptions from 'src/views/components/Select/SelectOptions'
import CustomTable from 'src/views/components/customTable/customTable'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { FaTrash } from 'react-icons/fa'
import { FiAlignJustify, FiEdit } from 'react-icons/fi'
import { IoMdTrash } from 'react-icons/io'
import { RiArrowGoBackFill } from 'react-icons/ri'

const index = () => {
  const navigate = useNavigate()
  const [updateModal, setUpdateModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const columns = [
    {
      name: 'Okul Numarası',
      sortable: true,
      selector: (row: { schoolNumber: any }) => row.schoolNumber,
    },
    {
      name: 'Ad Soyad',
      sortable: true,
      selector: (row: { name: any }) => row.name,
    },
    {
      name: '1.Veli Ad Soyad',
      sortable: true,
      selector: (row: { firstStParentNameAndSurname: any }) =>
        row.firstStParentNameAndSurname,
    },
    {
      name: '1.Veli Telefon ',
      sortable: true,
      selector: (row: { firstStParentPhone: any }) => row.firstStParentPhone,
    },
    {
      name: '2.Veli Ad Soyad ',
      sortable: true,
      selector: (row: { secondStParentNameAndSurname: any }) =>
        row.secondStParentNameAndSurname,
    },
    {
      name: '2.Veli Telefon  ',
      sortable: true,
      selector: (row: { secondStParentPhone: any }) => row.secondStParentPhone,
    },
    {
      name: 'Öğrenci Telefon  ',
      sortable: true,
      selector: (row: { studentPhone: any }) => row.studentPhone,
    },
    {
      name: 'İşlemler',
      sortable: true,
      selector: (row: { operations: any }) => row.operations,
    },
  ]
  const datax = [
    {
      schoolNumber: '129',
      name: 'Can Akcura',
      firstStParentNameAndSurname: 'Muhammetcan Akcura',
      firstStParentPhone: '5306556725',
      secondStParentNameAndSurname: 'Eda Akcura',
      secondStParentPhone: '5306556724',
      studentPhone: '5306556723',
    },
    {
      schoolNumber: '128',
      name: 'Esma Ilıman',
      firstStParentNameAndSurname: 'Selim Ilıman',
      firstStParentPhone: '5306556721',
      secondStParentNameAndSurname: 'Serra Ilıman',
      secondStParentPhone: '5306556728',
      studentPhone: '530656722',
    },
    {
      schoolNumber: '128',
      name: 'Esma Ilıman',
      firstStParentNameAndSurname: 'Selim Ilıman',
      firstStParentPhone: '5306556721',
      secondStParentNameAndSurname: 'Serra Ilıman',
      secondStParentPhone: '5306556728',
      studentPhone: '530656722',
    },
    {
      schoolNumber: '128',
      name: 'Esma Ilıman',
      firstStParentNameAndSurname: 'Selim Ilıman',
      firstStParentPhone: '5306556721',
      secondStParentNameAndSurname: 'Serra Ilıman',
      secondStParentPhone: '5306556728',
      studentPhone: '530656722',
    },
    {
      schoolNumber: '128',
      name: 'Esma Ilıman',
      firstStParentNameAndSurname: 'Selim Ilıman',
      firstStParentPhone: '5306556721',
      secondStParentNameAndSurname: 'Serra Ilıman',
      secondStParentPhone: '5306556728',
      studentPhone: '530656722',
    },
  ]
  const data = datax.map((item: any) => {
    return {
      schoolNumber: item.schoolNumber,
      name: item.name,
      firstStParentNameAndSurname: item.firstStParentNameAndSurname,
      firstStParentPhone: item.firstStParentPhone,
      secondStParentNameAndSurname: item.secondStParentNameAndSurname,
      secondStParentPhone: item.secondStParentPhone,
      studentPhone: item.studentPhone,
      operations: (
        <div className="d-flex jusify-content-center">
          <div className="mx-1">
            <FiEdit
              title="Düzenle"
              onClick={() => {
                // TODO: add something here
              }}
              size={'25px'}
            />
          </div>
          <div
            onClick={() => {
              setDeleteModal(true)
            }}
            className=""
          >
            <FaTrash title="Sil" color="red" size={'20px'} />
          </div>
        </div>
      ),
    }
  })
  return (
    <div>
      <Col className="d-flex justify-content-between mb-2 mt-5">
        <div>
          <Button
            onClick={(e: any) => {
              navigate('/contact')
            }}
            className="float-right"
            color="primary"
          >
            <RiArrowGoBackFill size={15} />
            <span className="ms-1 align-middle">Geri Dön</span>
          </Button>
        </div>
        <div>
          <Input placeholder="Arama" />
        </div>
      </Col>

      <CustomTable columns={columns} data={data} />
      <Modal
        className=" modal-dialog-centered modal-lg py-1"
        isOpen={updateModal}
        toggle={() => setUpdateModal(!updateModal)}
      >
        <ModalBody>
          <Row className="d-flex justify-content-center my-3 mx-1">
            <Col className="mb-1" md="6">
              <Label>Okul Numarası</Label>
              <Input placeholder="Okul Numarası"></Input>
            </Col>
            <Col className="mb-1" md="6">
              <Label>Ad Soyad</Label>
              <Input placeholder="Ad Soyad"></Input>
            </Col>
            <Col className="mb-1" md="6">
              <Label>1.veli Ad Soyad</Label>
              <Input placeholder="1.veli Ad Soyad"></Input>
            </Col>
            <Col className="mb-1" md="6">
              <Label>1.veli Telefon</Label>
              <Input placeholder="1.veli Telefon"></Input>
            </Col>
            <Col className="mb-1" md="6">
              <Label>2.veli Ad Soyad</Label>
              <Input placeholder="2.veli Ad Soyad"></Input>
            </Col>
            <Col className="mb-1" md="6">
              <Label>2.veli Telefon</Label>
              <Input placeholder="2.veli Telefon"></Input>
            </Col>
            <Col className="mb-1" md="6">
              <Label>Öğrenci Telefon</Label>
              <Input placeholder="Öğrenci Telefon"></Input>
            </Col>
            <Col md="12" className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  setUpdateModal(false)
                }}
                className="btn btn-warning mx-1"
              >
                Güncelle
              </Button>
              <Button
                onClick={() => {
                  setUpdateModal(false)
                }}
                className="btn btn-danger mx-1"
              >
                Vazgeç
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-md py-1"
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
      >
        <ModalBody>
          <Row className="d-flex justify-content-center">
            <Col className="my-1 d-flex justify-content-center " md="12">
              <IoMdTrash fontSize={'120px'} color="red" />
            </Col>
            <Col className="mb-1 d-flex justify-content-center" md="12">
              <h1>Emin misiniz?</h1>
            </Col>
            <Col className="mb-3 d-flex justify-content-center " md="12">
              <h6 className="text-center">
                <b>Bu kayıt silindikten sonra bir daha ulaşılamayacaktır</b>
              </h6>
            </Col>
            <Col md="12" className="d-flex justify-content-around">
              <Button
                onClick={() => setDeleteModal(false)}
                className="btn btn-block d-flex btn-danger"
              >
                Evet,Sil
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                className="btn btn-block d-flex btn-success"
              >
                Vazgeç
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
