import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {
  MdNotifications,
  MdSearch,
  MdWhatsapp,
  MdSms,
  MdAccountBox,
} from 'react-icons/md'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Image from 'react-bootstrap/Image'
import image from './avatar-1.png'
import { FaBars } from 'react-icons/fa'

const ThemeNavbar = (props) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {window.innerWidth < 1200 ? (
        <FaBars size={25} onClick={() => props.setVisibility(true)} />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flex: 0.3,
              height: '100%',
            }}
          >
            <InfoBox
              title="Üye Kodu"
              value={'75'}
              disabled
              icon={<MdAccountBox className="text-secondary" />}
            />
            <InfoBox
              title="SMS"
              value={72}
              icon={<MdWhatsapp className="text-secondary" />}
            />
            <InfoBox
              title="Whatsapp"
              value={12}
              icon={<MdSms className="text-secondary" />}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 0.3,
              marginRight: 15,
              marginLeft: 15,
            }}
          >
            <InputGroup className="me-1 h-50">
              <Form.Control
                placeholder="Okul Numarası Veya Öğrenci Adı"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
                <MdSearch size={25} />
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flex: 0.4,
            }}
          >
            <ButtonGroup className="me-1 w-100">
              <Button className="me-1" variant="outline-primary">
                E-Okul Entegrasyonu
              </Button>
              <Button variant="outline-success">e-Yoklama</Button>
            </ButtonGroup>
          </div>
        </>
      )}
      <div className="d-flex">
        <div
          className="text-primary d-flex align-items-center justify-content-center"
          style={{
            backgroundColor: 'white',
            border: '.5px solid #7367f0',
            width: 50,
            height: 50,
            borderRadius: '50%',
          }}
        >
          <MdNotifications color="#7367f0" size={25} />
        </div>
        <Image
          className="ms-2"
          src={image}
          height={50}
          width={50}
          roundedCircle
        />
      </div>
    </div>
  )
}

export default ThemeNavbar

const InfoBox = ({ title, value, disabled, icon }) => {
  return (
    <div
      className="mx-auto text-center"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <h6
        className="text-secondary text-center "
        style={{ fontSize: 18, margin: 0, marginRight: 8 }}
      >
        {title}
      </h6>
      <div className="d-flex align-items-center justify-content-center me-1">
        <h6
          className={` m-0 text-center text-${
            disabled ? 'primary' : getTextColor(value)
          }`}
          style={{ fontSize: 18 }}
        >
          {value}
        </h6>
      </div>
    </div>
  )
}

const getTextColor = (value) => {
  if (value > 50 && value < 100) {
    return 'warning'
  } else if (value > 100) {
    return 'success'
  } else {
    return 'danger'
  }
}
