import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AiOutlineCloudUpload, AiOutlineQuestionCircle } from 'react-icons/ai'
import CustomTable from 'src/views/components/customTable/customTable'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'

import { FaSearchPlus } from 'react-icons/fa'
import CustomExcel from 'src/views/pages/main/components/CustomExcel'

const index = (props: any) => {
  const [modal, setModal] = useState(false)
  const [active, setActive] = useState(true)
  const [excelModal, setExcelModal] = useState(false)
  const [show, setShow] = useState(false)
  const { t }: any = useTranslation()
  const [excelData, setExcelData] = useState<any[][] | null>(null)

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()

    reader.onload = (e: any) => {
      const binaryData = e.target.result
      const workbook = XLSX.read(binaryData, { type: 'binary' })

      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]

      const data: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      setExcelData(data)
    }

    reader.readAsBinaryString(file)
  }

  const col = excelData ? excelData[0]?.join(',') : ''

  const columns2 = excelData
    ? excelData[0].map((item: any) => ({
        name: item,
        Sortable: true,
        selector: (row: any) => row[item],
      }))
    : []

  const selectionColumn: any = {
    name: 'Seç',
    selector: (row: any) => row.selected,
    sortable: false,
    cell: (row: any) => (
      <Input
        type="checkbox"
        checked={row.selected}
        onChange={() => handleCheckboxChange(row)}
      />
    ),
  }

  columns2.unshift(selectionColumn)

  const data1 = excelData
    ? excelData.slice(1).map((item: any, index: number) => {
        const row: any = {
          selected: true,
          index: index,
        }
        excelData[0].forEach((columnName: any, i: number) => {
          row[columnName] = item[i]
        })
        return row
      })
    : []
  const handleCheckboxChange = (row: any) => {
    const update = [excelData ? [...excelData] : '']
  }

  if (typeof props.onDataChange === 'function') {
    props.onDataChange(col)
  }

  useEffect(() => {
    if (excelData ? excelData.length > 0 : '') {
      setActive(false)
    }
  }, [excelData])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if (excelData ? excelData.length > 0 : '') {
      setShow(true)
    }
  }, [excelData])
  return (
    <>
      {active ? (
        <CustomExcel />
      ) : (
        <>
          <Row>
            <Col md="12" className=" d-flex justify-content-center">
              <div className="">
                <div
                  onClick={() => setActive(true)}
                  style={{ cursor: 'pointer' }}
                  className=" d-flex justify-content-center align-items-center"
                >
                  <AiOutlineCloudUpload size={40} color="#AAAAAA" />
                </div>
                <div className="">Excel Dosyasını Tekrar Yükle</div>
              </div>
            </Col>
            <Col md="12">
              <Button color="primary" onClick={() => setExcelModal(true)}>
                <FaSearchPlus size={'25px'} />
              </Button>
              <CustomTable
                notPage
                fixedHeaderScrollHeight={'380px'}
                data={data1}
                columns={columns2}
              />
            </Col>
          </Row>
        </>
      )}
      <Modal
        className="modal-dialog-centered modal-xl py-1"
        isOpen={excelModal}
        toggle={() => setExcelModal(!excelModal)}
      >
        <ModalHeader
          style={{ backgroundColor: '#1F59AA' }}
          className="d-flex justify-content-center align-items-center text-center "
          closeButton
          toggle={() => setExcelModal(!excelModal)}
        >
          <h4 className="text-white">Aktarılan Kayıtlar</h4>
        </ModalHeader>
        <ModalBody>
          <div>
            <CustomTable
              notPage
              fixedHeaderScrollHeight={'700px'}
              data={data1}
              columns={columns2}
            />
          </div>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered modal-lg py-1"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader
          className="d-flex justify-content-center align-items-center text-center bg-transparent"
          closeButton
          toggle={() => setModal(!modal)}
        ></ModalHeader>
        <ModalBody className="text-center px-1  ">
          <div className="text-black">
            <AiOutlineQuestionCircle size={100} color="black" />
          </div>
          <div className=" mt-1 px-1 p ">
            <h4>Nasıl Kullanılır?</h4>
          </div>
          <div className="px-1 py-1">
            <p>
              Excel tablonuzun ilk satırında yani başlık satırında boşluk
              olmamalıdır. Excel dosyanızın ilk sütunu okul numarası veya
              telefon numarası olmalıdır.
            </p>
            <p className="text-danger">
              {
                'En fazla 25 sütunluk Excel dosyası kabul etmektedir. Excel dosyasınızın ilk 15 sütunu bu alanda gözükmektedir. Diğer alanları mesaj yazma alanında görebilirsiniz.'
              }
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default index
