import React, { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import debounce from 'lodash/debounce';
import { useFormik } from 'formik'
import Guides from '../../../../../../components/guides'
const Index = (props: any) => {
  const { t }: any = useTranslation()
  const [count, setCount] = useState(112)

  const formik: any = useFormik({
    initialValues: {
      message: 'Sayın [veli ad soyad], [okul no] numaralı öğrencinizin [ad soyad] yapılan deneme sınavı sonuçları; [sinavsonucu]',
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {
    },
  })
  const fprmiks = props.formik ? props.formik : null
  useEffect(() => {
    if (fprmiks) {
      fprmiks.setFieldValue(
        'message',
        formik.values.message
      )
    }

  }, [formik.values.message])


  const messageEle = document.getElementById('practice');

  if (messageEle) {
    const debounceHandler = debounce(function (e: any) {
      const target = e.target;
      const maxLength = target.getAttribute('maxlength');
      const currentLength = target.value.length;
      setCount(currentLength);
    }, 300);
    messageEle.addEventListener('input', debounceHandler);
  }



  return (
    <Row className="px-1">
      <Guides />
      <Col md="6" className="mb-">
        <Label>Mesaj</Label>
        <Input
          id="practice"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          maxlength="400"
          style={{
            height: '190px',
            overflowY: 'scroll',
            scrollbarWidth: 'auto',
            scrollbarColor: 'dark thin',
          }}
          type="textarea"
        />
        <div className="text-center mt-1 d-flex align-items-center  ">
          <b style={{ fontSize: '13px' }}>1 SMS({count} Karakter)</b>
        </div>
      </Col>
      <Col md="6">
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Rehber Türü')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Gönderici Adı')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Türü')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Sınav Türü')} />
          </div>
        </Col>
      </Col>



      <Col md="12" className="mt-1">
        <div
          className="btn text-white d-flex align-items-center justify-content-center btn-block"
          style={{ backgroundColor: '#1F59AA' }}
        >
          Mesajı Gönder
          <Send className="font-medium-3 ms-2" color="white" />
        </div>
      </Col>
    </Row>
  )
}

export default Index
