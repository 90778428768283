import { useEffect, useState } from 'react'
import { Send } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import Guides from '../../../../../../../components/guides'
import CustomSelect from 'src/views/components/customSelect/customSelect'
import debounce from 'lodash/debounce';
import { useFormik } from 'formik'
const Index = (props:any) => {
  const { t }: any = useTranslation()
  const [count, setCount] = useState(0)

  const formik: any = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: yup.object({
      message: yup.string(),
    }),
    onSubmit: async (values) => {
    },
  })

  const fprmiks = props.formik ? props.formik :null
  useEffect(() => {
    if(fprmiks){
      fprmiks.setFieldValue(
        'message',
          formik.values.message
      )
    }
    
  }, [formik.values.message])
  
  const messageEle = document.getElementById('textarea');

if (messageEle) {
    const debounceHandler = debounce(function (e: any) {
        const target = e.target;
        const maxLength = target.getAttribute('maxlength');
        const currentLength = target.value.length;
        setCount(currentLength);
    }, 300);
    messageEle.addEventListener('input', debounceHandler);
}

  return (
    <Row className="px-1">
      <Guides/>
        <Col md="6" className="mb-1">
          <div>
            <Label>WhatsApp Hesabı</Label>
            <Input disabled value={"5306556725"} />
          </div>
        </Col>
        <Col md="6" className="mb-1">
          <div>
            <CustomSelect label={t('Türü')} />
          </div>
        </Col>
      <Col md="6" className="mb- bg-">
        <div className='d-flex justify-content-between align-items-center'>
        <Label>Mesaj</Label>
        <Label>
            <b style={{ fontSize: '12px' }}>1 SMS({count} Karakter)</b>
          </Label>
        </div>
        <Input
          id="textarea"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          maxlength="400"
          style={{
            height: '190px',
            overflowY: 'scroll',
            scrollbarWidth: 'auto',
            scrollbarColor: 'dark thin',
          }}
          type="textarea"
        />
      </Col>
      <Col md="6" className='bg-' >
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Şablonlar')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Hız')} />
          </div>
        </Col>
        <Col md="12" className="mb-1">
          <div>
            <CustomSelect label={t('Zaman')} />
          </div>
        </Col>
        <Col md="12">
          <Label>Dosya Seç</Label>
            <Input type="file"  />
          </Col>
      </Col>
      

      <Row className="mb-">
        <Col md="6" style={{ marginBottom: '10px' }}>
          <div className="d-flex align-items-center">
            <Input type="checkbox" />
            <span
              style={{
                fontSize: '13px',
                fontWeight: '700',
                marginTop: '2px',
                marginLeft: '3px',
              }}
            >
              {t('Mesajı Şablon Olarak Kaydet')}
            </span>
          </div>
        </Col>
        
      </Row>

      <Col md="12" className="">
        <div
          className="btn text-white d-flex align-items-center justify-content-center btn-block"
          style={{ backgroundColor: '#1F59AA' }}
        >
          Mesajı Gönder
          <Send className="font-medium-3 ms-2" color="white" />
        </div>
      </Col>
    </Row>
  )
}

export default Index
